import { all, takeEvery, put, fork, call } from "redux-saga/effects";
//import { auth, googleAuthProvider, facebookAuthProvider } from 'auth/FirebaseAuth';
import { auth } from "auth/FirebaseAuth";
import { AUTH_PREFIX_PATH, APP_BASE_URL } from "configs/AppConfig";

import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
  FORGOT_PASSWORD,
  CONFIRM_FORGOT_PASSWORD_RESET,
  CHANGE_PASSWORD,
} from "../constants/Auth";

import {
  showAuthMessage,
  showSuccessMessage,
  authenticated,
  requireAccept,
  requireAccoutSelect,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
  hideLoading,
  ForgotPasswordRequested,
  confirmForgotPasswordResetSuccess,
  doResetForm,
} from "../actions/Auth";

import { updateTimezone } from "../actions/Items";

import FirebaseService from "services/FirebaseService";
import InterplaiDirectService from "services/InterplaiDirectService";

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(
        FirebaseService.signInEmailRequest,
        email,
        password
      );
      // const userByEmail = yield call(InterplaiDirectService.getUserByEmail, email);
      // localStorage.setItem("apiVersion", userByEmail.apiVersion);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        if (localStorage.getItem("userId")) {
          const status = localStorage.getItem("status");
          const role = localStorage.getItem("role");
          const preference = yield call(InterplaiDirectService.getPreferences);
          const tz = preference.preferredTimeZone;
          localStorage.setItem("preferredTimezone", tz);
          updateTimezone(tz);
          // Disallow driver user if preference is set to false only
          if (role === "driver") {
            if (preference.driverLogin === false) {
              const signOutUser = yield call(FirebaseService.signOutRequest);
              if (signOutUser === undefined) {
                InterplaiDirectService.clearLocalStorage();
                yield put(showAuthMessage("Not allowed to login!"));
              }
            }
          }
          if (status === "active") {
            if (role === "superAdmin") {
              yield put(requireAccoutSelect(user.user.uid));
            } else {
              yield put(authenticated(user.user.uid));
            }
          } else if (status == "pending") {
            yield put(requireAccept(user.user.uid));
          }
        }
      }
    } catch (error) {
      yield put(showAuthMessage(error.message));
      InterplaiDirectService.clearLocalStorage();
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield call(FirebaseService.signOutRequest);
      if (signOutUser === undefined) {
        InterplaiDirectService.clearLocalStorage();

        yield put(signOutSuccess(signOutUser));
      } else {
        yield put(showAuthMessage(signOutUser.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password, full_name, company_name } = payload;
    try {
      const user = yield call(
        FirebaseService.signUpEmailRequest,
        email,
        password,
        full_name,
        company_name
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
        localStorage.setItem("preferredTimezone", "America/Detroit");
        updateTimezone("America/Detroit");
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(FirebaseService.signInGoogleRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithGoogleAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(FirebaseService.signInFacebookRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithFacebookAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* requestForgotPasswordToken() {
  yield takeEvery(FORGOT_PASSWORD, function* ({ payload }) {
    const { email } = payload;

    try {
      const actionCodeSettings = {
        url: `${APP_BASE_URL}${AUTH_PREFIX_PATH}/login`,
        // dynamicLinkDomain: `${APP_BASE_URL}${AUTH_PREFIX_PATH}/reset-password`
      };

      const sendPasswordResetSucces = yield call(
        FirebaseService.sendPasswordResetEmail,
        email,
        actionCodeSettings
      );

      if (sendPasswordResetSucces === undefined) {
        yield put(
          showSuccessMessage("We have sent an email with code to your email")
        );
        yield put(hideLoading());
        yield put(ForgotPasswordRequested());
      } else {
        yield put(showAuthMessage(sendPasswordResetSucces.message));
        yield put(hideLoading());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* requestConfirmPasswordReset() {
  yield takeEvery(CONFIRM_FORGOT_PASSWORD_RESET, function* ({ payload }) {
    const { token, password } = payload;

    try {
      const confirmPasswordRequestSuccess = yield call(
        FirebaseService.confirmpasswordreset,
        token,
        password
      );
      if (
        confirmPasswordRequestSuccess.code &&
        confirmPasswordRequestSuccess.code === 200
      ) {
        yield put(showSuccessMessage("Password Reset Successfully"));
        yield put(hideLoading());
        yield put(
          confirmForgotPasswordResetSuccess("Password Reset Successfully")
        );
      } else {
        yield put(
          showAuthMessage(confirmPasswordRequestSuccess.response.data.msg)
        );
        yield put(hideLoading());
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* requestChangePassword() {
  yield takeEvery(CHANGE_PASSWORD, function* ({ payload }) {
    const { password } = payload;
    try {
      const response = yield call(FirebaseService.updatePassword, password);

      if (response && response.message) {
        yield put(showAuthMessage(response.message));
      } else {
        yield put(
          showSuccessMessage(
            "Password updated successfully. Please login again."
          )
        );

        yield put(doResetForm());

        //   const signOutUser = yield call(FirebaseService.signOutRequest);

        //   if (signOutUser === undefined) {
        //     InterplaiDirectService.clearLocalStorage();

        //     yield put(signOutSuccess(signOutUser));
        //   } else {
        //     yield put(showAuthMessage(signOutUser.message));
        //   }
      }

      yield put(hideLoading());
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
    fork(signUpWithFBEmail),
    fork(signInWithFBGoogle),
    fork(signInWithFacebook),
    fork(requestForgotPasswordToken),
    fork(requestConfirmPasswordReset),
    fork(requestChangePassword),
  ]);
}
