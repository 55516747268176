import axios from "axios";
import { notification } from "antd";
import history from "../history";
import { connect } from "react-redux";

import { AUTH_TOKEN } from "redux/constants/Auth";
import { hideLoading } from "redux/actions/Auth";

import InterplaiDirectService from "../services/InterplaiDirectService";

const http = axios.create();

const ENTRY_ROUTE = "/auth/login";

let apiVersion = localStorage.getItem("apiVersion");

// http.defaults.baseURL = url;

http.defaults.timeout = 60000;
http.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Add a request interceptor
http.interceptors.request.use(
  async (config) => {
    config.baseURL = InterplaiDirectService.getApiUrl(apiVersion);

    const headers = await InterplaiDirectService.getApiHeaders();

    config.headers.common["x-api-key"] = headers.xAPIKey;
    config.headers.common["encoded-auth-fb-token"] = headers.encodedAuthFbToken;
    config.headers.common["user-id"] = headers.userId;
    if (headers.delegateAccountId) {
      config.headers.common["delegate-account-id"] = headers.delegateAccountId;
    }

    return config;
  },

  (error) => {
    // Do something with request error here
    // notification.error({
    //   message: "Error",
    // });
    Promise.reject(error);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    let notificationParam = {
      message: "",
    };

    // Remove token and redirect
    if (error.response.status === 403 || error.response.status === 401) {
      notificationParam.message = "[Authentication Failed]";
      notificationParam.description = error.response.data;

      InterplaiDirectService.clearLocalStorage();

      localStorage.setItem(
        "error_auth_description",
        notificationParam.message + " - " + notificationParam.description
      );

      //notification.error(notificationParam);

      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    if (error.response.status === 404) {
      notificationParam.message = "Not Found";
      hideLoading();
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
      hideLoading();
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
      hideLoading();
    }

    if (error.response.status === 422) {
      notificationParam.message = error.response.data
        ? error.response.data
        : "Invalid Data";
      hideLoading();
    }

    //notification.error(notificationParam);

    return Promise.reject(error);
  }
);

const mapStateToProps = ({ auth }) => {
  return {};
};

const mapDispatchToProps = {
  hideLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(http);
