import React from "react";
import { Menu, Button, Space } from "antd";
import { useHistory } from "react-router-dom";

export const NavDelegate = () => {
  let history = useHistory();

  const visible =
    localStorage.getItem("delegateAccountId") && localStorage.getItem("delegateAccountName") ? true : false;

  const exitDeligateAccess = () => {
    localStorage.removeItem("delegateAccountId");
    localStorage.removeItem("delegateAccountName");
    window.location.reload();
  };

  return (
    <Menu mode="horizontal">
      {visible ? (
        <Menu.Item>
          <Space>
            <p>Delegate Account:</p>
            <p>{localStorage.getItem("delegateAccountName")}</p>
            <Button type="danger" style={{ top: "-10px" }} size="small" onClick={exitDeligateAccess}>
              X Exit
            </Button>
          </Space>
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

export default NavDelegate;
