import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Form, Input, Alert } from "antd";
import { LockOutlined } from "@ant-design/icons";
import {
  signIn,
  changePassword,
  showLoading,
  hideLoading,
  showAuthMessage,
  hideAuthMessage,
} from "redux/actions/Auth";

import { motion } from "framer-motion";

const rules = {
  password: [
    {
      required: true,
      message: "Please input your password",
    },
  ],
  confirm: [
    {
      required: true,
      message: "Please confirm your password!",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject("Passwords do not match!");
      },
    }),
  ],
};

export const ChangePasswordForm = (props) => {
  const formRef = React.createRef();

  const [passwordChangeLoading, setChangePasswordChangeLoading] =
    useState(false);

  let history = useHistory();

  const {
    changePassword,
    setChangePasswordModal,
    hideAuthMessage,
    showLoading,
    hideLoading,
    loading,
    resetForm,
    signOut,
    showMessage,
    message,
    messageType,
    timezone,
  } = props;

  const onUpdatePassword = (values) => {
    setChangePasswordChangeLoading(true);
    changePassword(values);
    setChangePasswordChangeLoading(false);
    setTimeout(() => {
      setChangePasswordModal(false);
    }, 2500);
    localStorage.setItem("passwordChanged", true);
    setTimeout(() => {
      signOut();
    }, 3000);
  };

  const onReset = () => {
    formRef.current.resetFields();
  };

  useEffect(() => {
    if (resetForm === true) {
      onReset();
    }
  }, [resetForm]);

  return (
    <div className="w-100">
      {/* <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: showMessage ? 1 : 0,
          marginBottom: showMessage ? 20 : 0,
        }}
      >
        <Alert type={messageType} showIcon message={message}></Alert>
      </motion.div> */}
      <Form
        layout="vertical"
        name="login-form"
        ref={formRef}
        onFinish={onUpdatePassword}
      >
        <Form.Item
          name="password"
          label="New Password"
          rules={rules.password}
          hasFeedback
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Confirm New Password"
          rules={rules.confirm}
          hasFeedback
        >
          <Input.Password prefix={<LockOutlined className="text-primary" />} />
        </Form.Item>
        <Form.Item className="text-right mb-0">
          <Button
            size="small"
            type="dark"
            htmlType="button"
            onClick={onReset}
            className="my-2"
            style={{ float: "left" }}
          >
            Clear
          </Button>
          <Button
            size="small"
            type="primary"
            htmlType="submit"
            loading={passwordChangeLoading}
            className="my-2 mr-2"
          >
            Update
          </Button>
          <Button
            size="small"
            type="dark"
            htmlType="button"
            onClick={() => setChangePasswordModal(false)}
            className="my-2"
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

ChangePasswordForm.defaultProps = {
  redirect: false,
  resetForm: false,
};

const mapStateToProps = (state) => {
  const { auth, items } = state;

  return {
    loading: auth.loading,
    message: auth.message,
    messageType: auth.messageType,
    showAuthMessage: auth.showAuthMessage,
    hideAuthMessage: auth.hideAuthMessage,
    showLoading: auth.showLoading,
    hideLoading: auth.hideLoading,
    showMessage: auth.showMessage,
    resetForm: auth.resetForm,
    timezone: items.timezone,
  };
};

const mapDispatchToProps = {
  signIn,
  changePassword,
  showAuthMessage,
  showLoading,
  hideLoading,
  hideAuthMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
