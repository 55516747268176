import {
  SIGNIN,
  AUTHENTICATED,
  REQUIREACCEPT,
  REQUIREACCOUNTSELECT,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  HIDE_LOADING,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_REQUESTED,
  CONFIRM_FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_RESET_SUCCESS,
  CHANGE_PASSWORD,
  RESET_FORM,
} from "../constants/Auth";

export const signIn = (user) => {
  return {
    type: SIGNIN,
    payload: user,
  };
};

export const authenticated = (token) => {
  return {
    type: AUTHENTICATED,
    token,
  };
};

export const requireAccept = (token) => {
  return {
    type: REQUIREACCEPT,
    token,
  };
};

export const requireAccoutSelect = (token) => {
  return {
    type: REQUIREACCOUNTSELECT,
    token,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (user) => {
  return {
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const signInWithGoogle = () => {
  return {
    type: SIGNIN_WITH_GOOGLE,
  };
};

export const signInWithGoogleAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_GOOGLE_AUTHENTICATED,
    token,
  };
};

export const signInWithFacebook = () => {
  return {
    type: SIGNIN_WITH_FACEBOOK,
  };
};

export const signInWithFacebookAuthenticated = (token) => {
  return {
    type: SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const showSuccessMessage = (message) => {
  return {
    type: SHOW_SUCCESS_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const hideLoading = () => {
  return {
    type: HIDE_LOADING,
  };
};

export const requestForgotPassword = (userEmail) => {
  return {
    type: FORGOT_PASSWORD,
    payload: userEmail,
  };
};

export const ForgotPasswordRequested = (message) => {
  return {
    type: FORGOT_PASSWORD_REQUESTED,
    message: message,
  };
};

export const confirmForgotPasswordReset = (payload) => {
  return {
    type: CONFIRM_FORGOT_PASSWORD_RESET,
    payload: payload,
  };
};

export const confirmForgotPasswordResetSuccess = (message) => {
  return {
    type: FORGOT_PASSWORD_RESET_SUCCESS,
    message: message,
  };
};

export const changePassword = (values) => {
  return {
    type: CHANGE_PASSWORD,
    payload: values,
  };
};

export const doResetForm = () => {
  return {
    type: RESET_FORM,
  };
};
