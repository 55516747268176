const dev = {
  API_ENDPOINT_URL: "https://jsonplaceholder.typicode.com",
  APP_BASE_URL: "https://i-direct-docs-njsi5yolsq-uk.a.run.app",
};

const prod = {
  API_ENDPOINT_URL: "https://api.prod.com",
  APP_BASE_URL: "https://console.interplai.io",
};

const staging = {
  API_ENDPOINT_URL: "https://api.stagin.com",
  APP_BASE_URL: "https://console.staging.direct.interplai.io",
};

const test = {
  API_ENDPOINT_URL: "https://api.test.com",
  APP_BASE_URL: "https://console.direct.interplai.io",
};

const developmentlocal = {
  API_ENDPOINT_URL: "http://172.19.0.2:2000",
  APP_BASE_URL: "http://127.19.0.11:3000",
};

const getEnv = () => {
  switch (process.env.REACT_APP_CROSS_NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "staging":
      return staging;
    case "test":
      return test;
    case "development-local":
      return developmentlocal;
    default:
      return developmentlocal;
  }
};

export const env = getEnv();
