import mongoose from "mongoose";

export const generateInterplaiId = async () => {
  try {
    let interplaiObjectId = mongoose.Types.ObjectId();
    let interplaiId = interplaiObjectId.toString();
    return interplaiId;
  } catch (err) {
    return "no-id";
    console.log("generateInterplai Id : ", err);
  }
};
