import React, { useState, useEffect } from "react";
import { Form, Button, Select, Input, InputNumber, Popover, Switch } from "antd";
import { InfoCircleOutlined, PlusOutlined, MinusCircleOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";

const { Option } = Select;

const MobileApp = ({ form }) => {
  const [driverPay, setDriverPay] = useState(false);
  const [settings, setSettings] = useState(false);

  return (
    <div className="mb-4 mt-2 p-3 border rounded">
      <div className="my-3">
        <a className="text-dark my-4" onClick={() => setDriverPay(!driverPay)}>
          DRIVER PAY {driverPay ? <UpOutlined /> : <DownOutlined />}
        </a>
        {driverPay && (
          <div className="mt-4">
            <Form.Item name="basePay" label="Base Pay" hasFeedback shouldUpdate={true} rules={[{ required: false }]}>
              <div className="d-flex align-items-center">
                <InputNumber className="mr-3 w-100" placeholder="Enter base pay" />
                <Popover
                  content="A fixed amount of money paid to a driver, not including any bonuses, commission, or other discretionary incentives."
                  trigger="hover"
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item name="peakPay" label="Peak Pay" hasFeedback shouldUpdate={true} rules={[{ required: false }]}>
              <div className="d-flex align-items-center">
                <InputNumber className="mr-3 w-100" placeholder="Enter peak pay" />
                <Popover
                  content="Extra amount of money paid to the driver for delivering the order during peak hours."
                  trigger="hover"
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item name="customerTip" label="Customer Tip" hasFeedback shouldUpdate={true} rules={[{ required: false }]}>
              <div className="d-flex align-items-center">
                <InputNumber className="mr-3 w-100" placeholder="Enter customer tip" />
                <Popover content="Amount of money to be paid to the driver as a tip." trigger="hover" placement="right">
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
          </div>
        )}
      </div>

      <div className="my-3">
        <a className="text-dark my-4" onClick={() => setSettings(!settings)}>
          SETTINGS {settings ? <UpOutlined /> : <DownOutlined />}
        </a>
        {settings && (
          <div className="mt-4">
            <Form.Item name="verifyOrderItems" label="Verify Order Items" hasFeedback shouldUpdate={true} rules={[{ required: false }]}>
              <div className="d-flex align-items-center">
                <Select
                  placeholder="Select if items need to be verified"
                  className="mr-3"
                  onChange={(value) => {
                    form.setFieldsValue({
                      verifyOrderItems: value,
                    });
                  }}
                >
                  <Option value={false}>False</Option>
                  <Option value={true}>True</Option>
                </Select>
                <Popover
                  content="Indicates if the items in the manifest has to be verified or not. (boolean)"
                  trigger="hover"
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item name="scanOrderItems" label="Scan Order Items" hasFeedback shouldUpdate={true} rules={[{ required: false }]}>
              <div className="d-flex align-items-center">
                <Select
                  placeholder="Select if items need to be scanned"
                  className="mr-3"
                  onChange={(value) => {
                    form.setFieldsValue({
                      scanOrderItems: value,
                    });
                  }}
                >
                  <Option value={false}>False</Option>
                  <Option value={true}>True</Option>
                </Select>
                <Popover
                  content="Indicates if the items in the manifest has to be scanned or not. (boolean)"
                  trigger="hover"
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item
              name="provideChangeToCustomer"
              label="Provide Change To Customer"
              hasFeedback
              shouldUpdate={true}
              rules={[{ required: false }]}
            >
              <div className="d-flex align-items-center">
                <Select
                  placeholder="Select if change needs to be provided"
                  className="mr-3"
                  onChange={(value) => {
                    form.setFieldsValue({
                      provideChangeToCustomer: value,
                    });
                  }}
                >
                  <Option value={false}>False</Option>
                  <Option value={true}>True</Option>
                </Select>
                <Popover
                  content="Provide change in cash for the extra amount received from the customer for delivering the manifest. (boolean)"
                  trigger="hover"
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item
              name="displayAcceptButton"
              label="Display Accept Button"
              hasFeedback
              shouldUpdate={true}
              rules={[{ required: false }]}
            >
              <div className="d-flex align-items-center">
                <Select
                  placeholder="Select whether accept button should be displayed"
                  className="mr-3"
                  onChange={(value) => {
                    form.setFieldsValue({
                      displayAcceptButton: value,
                    });
                  }}
                >
                  <Option value={false}>False</Option>
                  <Option value={true}>True</Option>
                </Select>
                <Popover content="Option to display or hide the 'accept' button to drivers. (boolean)" trigger="hover" placement="right">
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item
              name="displayDeclineButton"
              label="Display Decline Button"
              hasFeedback
              shouldUpdate={true}
              rules={[{ required: false }]}
            >
              <div className="d-flex align-items-center">
                <Select
                  placeholder="Select whether decline button should be displayed"
                  className="mr-3"
                  onChange={(value) => {
                    form.setFieldsValue({
                      displayDeclineButton: value,
                    });
                  }}
                >
                  <Option value={false}>False</Option>
                  <Option value={true}>True</Option>
                </Select>
                <Popover content="Option to display or hide the 'decline' button to drivers. (boolean)" trigger="hover" placement="right">
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item name="displayTimer" label="Display Timer" hasFeedback shouldUpdate={true} rules={[{ required: false }]}>
              <div className="d-flex align-items-center">
                <Select
                  placeholder="Select whether timer should be displayed"
                  className="mr-3"
                  onChange={(value) => {
                    form.setFieldsValue({
                      displayTimer: value,
                    });
                  }}
                >
                  <Option value={false}>False</Option>
                  <Option value={true}>True</Option>
                </Select>
                <Popover
                  content="The total distance and duration for the vehicle to reach the pickup or drop-off waypoint. (boolean)"
                  trigger="hover"
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item name="displayTipText" label="Display Tip Text" hasFeedback shouldUpdate={true} rules={[{ required: false }]}>
              <div className="d-flex align-items-center">
                <Select
                  placeholder="Select whether tip text should be displayed"
                  className="mr-3"
                  onChange={(value) => {
                    form.setFieldsValue({
                      displayTipText: value,
                    });
                  }}
                >
                  <Option value={false}>False</Option>
                  <Option value={true}>True</Option>
                </Select>
                <Popover
                  content="Option to display the total currency of the trip includes tip amounts or not. (boolean)"
                  trigger="hover"
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item
              name="displayPartnerDispatchButton"
              label="Display Partner Dispatch Button"
              hasFeedback
              shouldUpdate={true}
              rules={[{ required: false }]}
            >
              <div className="d-flex align-items-center">
                <Select
                  placeholder="Select whether partner dispatch button should be displayed"
                  className="mr-3"
                  onChange={(value) => {
                    form.setFieldsValue({
                      displayPartnerDispatchButton: value,
                    });
                  }}
                >
                  <Option value={false}>False</Option>
                  <Option value={true}>True</Option>
                </Select>
                <Popover
                  content="Button that denotes the order items are dispatched by the partner. (boolean)"
                  trigger="hover"
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <Form.Item
              name="acceptanceTimeoutDuration"
              label="Acceptance Timeout Duration"
              initialValue={60}
              hasFeedback
              shouldUpdate={true}
              rules={[{ required: false }]}
            >
              <div className="d-flex align-items-center">
                <InputNumber className="mr-3 w-100" n placeholder="Enter timeout duration" defaultValue={60} />
                <Popover
                  content="The validity period in which the driver can accept or reject any trip within 60 seconds after it has been submitted. (int32)"
                  trigger="hover"
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileApp;
