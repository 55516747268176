var FirebaseConfig = {};

if (process.env.REACT_APP_CROSS_NODE_ENV === "production") {
  FirebaseConfig = {
    apiKey: "AIzaSyAjY07-7Cz2D3ZUsY62znw6B1e91oCCFJU",
    authDomain: "idt-production.firebaseapp.com",
    projectId: "idt-production",
    storageBucket: "idt-production.appspot.com",
    messagingSenderId: "445663878178",
    appId: "1:445663878178:web:af90ec4f88b44303e97e7b",
    measurementId: "G-4WL7X6EJ0B",
  };
} else if (process.env.REACT_APP_CROSS_NODE_ENV === "prod-dev") {
  FirebaseConfig = {
    apiKey: "AIzaSyAZ9Nizzboa8k4IvkZVHgza41qq7C0-KBQ",
    authDomain: "idt-prod-dev.firebaseapp.com",
    projectId: "idt-prod-dev",
    storageBucket: "idt-prod-dev.appspot.com",
    messagingSenderId: "560599511418",
    appId: "1:560599511418:web:729482c7e31021414ba6c3",
    measurementId: "G-J6TV9JN0D9",
  };
} else if (process.env.REACT_APP_CROSS_NODE_ENV === "staging") {
  FirebaseConfig = {
    apiKey: "AIzaSyBLEdkoPUaofD2wp8_PW6dDU6rWQIKZ1rc",
    authDomain: "idt-staging.firebaseapp.com",
    projectId: "idt-staging",
    storageBucket: "idt-staging.appspot.com",
    messagingSenderId: "276576481808",
    appId: "1:276576481808:web:3eaaac4041cb61c29b1095",
    measurementId: "G-SCYGG886E5",
  };
} else if (process.env.REACT_APP_CROSS_NODE_ENV === "development") {
  FirebaseConfig = {
    apiKey: "AIzaSyDaSdyyL1TFPtiHIUiRoVsCT_SSjr_5LUI",
    authDomain: "idt-dev.firebaseapp.com",
    databaseURL: "https://robust-flow-290216-default-rtdb.firebaseio.com",
    projectId: "idt-dev",
    storageBucket: "idt-dev.appspot.com",
    messagingSenderId: "115031558026",
    appId: "1:115031558026:web:03c2bd77e90984be1bea73",
    measurementId: "G-RBZL1551PC",
  };
} else if (process.env.REACT_APP_CROSS_NODE_ENV === "development2") {
  FirebaseConfig = {
    apiKey: "AIzaSyDaSdyyL1TFPtiHIUiRoVsCT_SSjr_5LUI",
    authDomain: "idt-dev.firebaseapp.com",
    databaseURL: "https://robust-flow-290216-default-rtdb.firebaseio.com",
    projectId: "idt-dev",
    storageBucket: "idt-dev.appspot.com",
    messagingSenderId: "115031558026",
    appId: "1:115031558026:web:03c2bd77e90984be1bea73",
    measurementId: "G-RBZL1551PC",
  };
} else if (process.env.REACT_APP_CROSS_NODE_ENV === "development3") {
  FirebaseConfig = {
    apiKey: "AIzaSyDaSdyyL1TFPtiHIUiRoVsCT_SSjr_5LUI",
    authDomain: "idt-dev.firebaseapp.com",
    databaseURL: "https://robust-flow-290216-default-rtdb.firebaseio.com",
    projectId: "idt-dev",
    storageBucket: "idt-dev.appspot.com",
    messagingSenderId: "115031558026",
    appId: "1:115031558026:web:03c2bd77e90984be1bea73",
    measurementId: "G-RBZL1551PC",
  };
} else if (process.env.REACT_APP_CROSS_NODE_ENV === "development4") {
  FirebaseConfig = {
    apiKey: "AIzaSyDaSdyyL1TFPtiHIUiRoVsCT_SSjr_5LUI",
    authDomain: "idt-dev.firebaseapp.com",
    databaseURL: "https://robust-flow-290216-default-rtdb.firebaseio.com",
    projectId: "idt-dev",
    storageBucket: "idt-dev.appspot.com",
    messagingSenderId: "115031558026",
    appId: "1:115031558026:web:03c2bd77e90984be1bea73",
    measurementId: "G-RBZL1551PC",
  };
} else if (process.env.REACT_APP_CROSS_NODE_ENV === "development5") {
  FirebaseConfig = {
    apiKey: "AIzaSyDaSdyyL1TFPtiHIUiRoVsCT_SSjr_5LUI",
    authDomain: "idt-dev.firebaseapp.com",
    databaseURL: "https://robust-flow-290216-default-rtdb.firebaseio.com",
    projectId: "idt-dev",
    storageBucket: "idt-dev.appspot.com",
    messagingSenderId: "115031558026",
    appId: "1:115031558026:web:03c2bd77e90984be1bea73",
    measurementId: "G-RBZL1551PC",
  };
} else {
  FirebaseConfig = {
    apiKey: "AIzaSyDaSdyyL1TFPtiHIUiRoVsCT_SSjr_5LUI",
    authDomain: "idt-dev.firebaseapp.com",
    databaseURL: "https://robust-flow-290216-default-rtdb.firebaseio.com",
    projectId: "idt-dev",
    storageBucket: "idt-dev.appspot.com",
    messagingSenderId: "115031558026",
    appId: "1:115031558026:web:03c2bd77e90984be1bea73",
    measurementId: "G-RBZL1551PC",
  };
}

export default FirebaseConfig;
