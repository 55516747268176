import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Items from "./Items";
import Subscription from "./Subscription";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  items: Items,
  subscription: Subscription,
});

export default reducers;
