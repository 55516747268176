import {
  auth,
  googleAuthProvider,
  facebookAuthProvider,
  firebase,
} from "auth/FirebaseAuth";
import InterplaiDirectService from "./InterplaiDirectService";
import moment from "moment";
// import firebase from "firebase/app";

const FirebaseService = {};

FirebaseService.signInEmailRequest = async (email, password) =>
  await auth
    .signInWithEmailAndPassword(email, password)
    .then(async function (user) {
      await localStorage.setItem("userId", user.user.uid);

      /* Give a delay of 1 second as mentioned in doc */
      await new Promise((resolve) => setTimeout(resolve, 1000));

      await auth.currentUser
        .getIdTokenResult(true)
        .then(async (tokenResult) => {
          /* Write Token into the Local storage*/
          localStorage.setItem("encoded_auth_fb_token", tokenResult.token);

          /* Write expiration time into the local storage - converting it into browsers local time */
          localStorage.setItem(
            "expires_at",
            moment(tokenResult.expirationTime).local()
          );

          /* After storing  get the user from the interplai direct service */
          let getUser = await InterplaiDirectService.getUserByFirebaseId(
            user.user.uid
          );

          if (getUser) {
            localStorage.setItem("apiVersion", getUser.apiVersion);

            localStorage.setItem("colorTheme", "light");
          }
        })
        .catch((error) => {
          return error;
        });

      return user;
    })
    .catch((err) => {
      return err;
    });

FirebaseService.renewToken = async () => {
  if (auth.currentUser) {
    try {
      const tokenResult = await auth.currentUser.getIdTokenResult(true);

      /* Write Token into the Local storage*/
      localStorage.setItem("encoded_auth_fb_token", tokenResult.token);

      /* Write expiration time into the local storage - converting it into browsers local time */
      localStorage.setItem(
        "expires_at",
        moment(tokenResult.expirationTime).local()
      );
    } catch (error) {
      return error;
    }
  }
};

FirebaseService.signOutRequest = async () => {
  let loggedInUser = localStorage.getItem("userId");
  if (loggedInUser) {
    await auth
      .signOut()
      .then(async (user) => {})
      .catch((err) => err);
  }
};

FirebaseService.signInGoogleRequest = async () =>
  await auth
    .signInWithPopup(googleAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signInFacebookRequest = async () =>
  await auth
    .signInWithPopup(facebookAuthProvider)
    .then((user) => user)
    .catch((err) => err);

FirebaseService.signUpEmailRequest = async (
  email,
  password,
  full_name,
  company_name
) => {
  try {
    const user = await auth.createUserWithEmailAndPassword(email, password);

    localStorage.setItem("userId", user.user.uid);

    /* Give a delay of 1 second as mentioned in doc */
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const tokenResult = await auth.currentUser.getIdTokenResult(
      /* force refres*/ true
    );

    /* Write Token into the Local storage*/
    localStorage.setItem("encoded_auth_fb_token", tokenResult.token);

    /* Write expiration time into the local storage - converting it into browsers local time */
    localStorage.setItem(
      "expires_at",
      moment(tokenResult.expirationTime).local()
    );

    /* Create account with user*/
    let accountResponse = await InterplaiDirectService.createAccount(
      company_name,
      user.user.email,
      full_name,
      user.user.uid
    );

    return user;
  } catch (err) {
    return err;
  }
};

FirebaseService.sendPasswordResetEmail = async (email, actionCodeSettings) =>
  await auth
    .sendPasswordResetEmail(email, actionCodeSettings)
    .then((response) => response)
    .catch((err) => err);

FirebaseService.confirmpasswordreset = async (code, newPassword) => {
  try {
    let response = await InterplaiDirectService.resetForgotPassword(
      newPassword,
      code
    );
    return response;
  } catch (error) {
    return error;
  }
};

FirebaseService.createUser = async (email, password) =>
  await auth
    .createUserWithEmailAndPassword(email, password)
    .then(async function (user) {
      return user;
    })
    .catch((err) => err);

FirebaseService.updatePassword = async (password) => {
  try {
    const response = await auth.currentUser.updatePassword(password);

    const credential = new firebase.auth.EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    );

    await auth.currentUser.reauthenticateWithCredential(credential);

    await FirebaseService.renewToken();

    return response;
  } catch (error) {
    return error;
  }
};

export default FirebaseService;
