import React, { useState, useEffect } from "react";
import { Row, Tag, Card, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { formatDay } from "utils/operations";
import ChangePasswordForm from "views/app-views/admin/account/ChangePasswordForm";
import { connect } from "react-redux";

const Profile = (props) => {
  const { user, signOut, timezone } = props;
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  return (
    <>
      {user ? (
        <>
          <Row gutter={16}>
            <Card className="w-100 h-40" style={{ maxHeight: "60vh", overflowY: "auto" }}>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4 className="font-weight-light">Email: </h4>
                <h4 className="font-weight-bold">{user?.email}</h4>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4 className="font-weight-light">Name: </h4>
                <h4 className="font-weight-bold">{user?.fullName}</h4>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4 className="font-weight-light">Role: </h4>
                <h4 className="font-weight-bold">
                  <Tag color={user?.role === "owner" ? "#228C22" : "#658CBB"}>{user?.role.toUpperCase()}</Tag>
                </h4>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4 className="font-weight-light">Company Name: </h4>
                <h4 className="font-weight-bold">{user?.companyName}</h4>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4 className="font-weight-light">Member Since: </h4>
                <h4 className="font-weight-bold">
                  {user.createdTimestamp ? formatDay(user?.createdTimestamp, timezone) : formatDay(new Date(), timezone)}
                </h4>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4 className="font-weight-light">API Version: </h4>
                <h4 className="font-weight-bold">{user.apiVersion}.0</h4>
              </div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4 className="font-weight-light">Change Password: </h4>
                <Button type="link" color="#000" onClick={() => setChangePasswordModal(true)} icon={<EditOutlined />}></Button>
              </div>
              {changePasswordModal && (
                <div className="d-flex flex-column align-items-center mb-2">
                  <h4 className="font-weight-light mb-1">WARNING: You will be required to login again if your update your password.</h4>
                  <ChangePasswordForm setChangePasswordModal={setChangePasswordModal} signOut={signOut} />
                </div>
              )}
            </Card>
            <small className="justify-self-center">
              <strong>Note:</strong> You can edit your other details in the Users page.
            </small>
          </Row>
        </>
      ) : (
        <>
          <Row gutter={16}>
            <Card className="w-100 h-50 d-flex justify-content-around align-items-center">
              <h4 className="font-weight-light">No user details found!</h4>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const { items } = state;

  return {
    timezone: items.timezone,
  };
};

export default connect(mapStateToProps, null)(Profile);
