import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { PAYMENT_PREFIX_PATH } from "configs/AppConfig";

export const PaymentViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${PAYMENT_PREFIX_PATH}/`} component={lazy(() => import(`./pricing`))} />
        <Redirect from={`${PAYMENT_PREFIX_PATH}`} to={`${PAYMENT_PREFIX_PATH}/plans`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(PaymentViews);
