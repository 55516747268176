import React, { useState, useEffect } from "react";
import { Row, Form, Col, Input, InputNumber, Button, Popover, Select, Typography, Modal, Switch, Alert } from "antd";
import { UpOutlined, DownOutlined, InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";
import tickimg from "../../img/correct.png";

import { connect } from "react-redux";
import { updateLocations } from "redux/actions/Items";

const { Option } = Select;
const { Paragraph } = Typography;

const LocationEntry = (props) => {
  const { updateLocations, reduxZones, areZonesUpdated, showLocationModal, setShowLocationModal } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(false);
  const [advancedFields, setAdvancedFields] = useState(false);
  const [success, setSuccess] = useState({
    state: false,
    message: {
      id: null,
      zoneId: null,
    },
  });
  const [error, setError] = useState(null);

  const [zones, setZones] = useState([]);

  useEffect(() => {
    const fetchZones = async () => {
      const wm = await InterplaiDirectService.getWorldModels();
      setZones(wm);
    };
    fetchZones();
  }, []);

  useEffect(() => {
    if (areZonesUpdated) {
      setZones(reduxZones);
    }
  }, [areZonesUpdated, reduxZones]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4500);
    }
  }, [error]);

  const handleAdvancedView = () => {
    setAdvancedFields(!advancedFields);
  };

  const handleLocationClose = () => {
    setSuccess({
      state: false,
      message: {
        id: null,
        zoneId: null,
      },
    });
    setError(null);
    setShowLocationModal(false);
  };

  const onLocationSubmit = async (values) => {
    setLoading(true);
    try {
      let payload = {
        name: values.name,
        userLocationId: values.userLocationId,
      };
      if (coordinates) {
        payload = {
          ...payload,
          coordinates: {
            lat: Number(values.lat),
            lng: Number(values.lng),
          },
        };
      } else {
        payload = {
          ...payload,
          address: {
            street1: values.street1,
            street2: values.street2 || "",
            unit: values.unit || "",
            city: values.city,
            postalCode: values.postalCode,
            stateProvince: values.stateProvince,
            country: values.country,
          },
        };
      }
      if (advancedFields) {
        payload = {
          ...payload,
          phoneNumber: values.phoneNumber,
          supportsChatMessages: values.supportsChatMessages,
          supportsPhoneCalls: values.supportsPhoneCalls,
          supportsTextMessages: values.supportsTextMessages,
        };
      }

      const res = await InterplaiDirectService.createLocations(values.zoneId, payload);
      const alllocations = await InterplaiDirectService.getAllLocations(values.zoneId);
      updateLocations(alllocations);
      setSuccess({
        ...success,
        state: true,
        message: {
          id: res.id,
          zoneId: res.zoneId,
        },
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onLocationSubmit(values);
      })
      .catch((err) => {
        setError("One or more required fields are missing");
      });
  };

  const onLocationReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={showLocationModal}
      onCancel={handleLocationClose}
      title="Create a Location"
      maskClosable={false}
      footer={
        success.state
          ? [
              <Button key="close" type="primary" onClick={handleLocationClose} id="create-location-close">
                Close
              </Button>,
            ]
          : [
              <Button key="clear" onClick={onLocationReset} style={{ float: "left" }} id="create-location-reset">
                <ReloadOutlined />
                Clear all
              </Button>,
              <Button key="submit" htmlType="submit" type="primary" loading={loading} onClick={onSubmit} id="create-location-button">
                Create
              </Button>,

              <Button type="ghost" key="cancel" onClick={handleLocationClose} id="create-location-close">
                Cancel
              </Button>,
            ]
      }
      width={540}
    >
      {error && (
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
              <Alert message={error} showIcon type="error" closable />
            </Col>
          </Row>
        </div>
      )}
      <div style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "54vh" }}>
        {success.state ? (
          <Row gutter={16} justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="d-block mb-4">
                <img src={tickimg} className="mr-3" height="28px" width="28px" alt="success" />
                <strong>The location is successfully created!</strong>
              </div>
              <div className="d-block">
                <strong>Location ID: </strong>
                <Paragraph id="de-loc-id-success" copyable>
                  {success.message.id}
                </Paragraph>
              </div>
              <div className="d-block">
                <strong>Corresponding Zone ID: </strong>
                <Paragraph id="de-loc-zone-id-success" copyable>
                  {success.message.zoneId}
                </Paragraph>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form form={form} layout="vertical" name="create-location" preserve={false} className="m-3" id="create-location-form">
                  <Form.Item
                    name="zoneId"
                    label="Zone"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[{ required: true, message: "Please select a zone." }]}
                  >
                    <div className="d-flex align-items-center">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="mr-3"
                        placeholder="Select a zone"
                        filterOption={(input, option) =>
                          option.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          form.setFieldsValue({
                            zoneId: value,
                          });
                        }}
                      >
                        {zones.length > 0 &&
                          zones.map((worldmodel) => (
                            <Option key={worldmodel.id} value={worldmodel.id}>
                              <strong>{worldmodel.name}</strong> ({worldmodel.id})
                            </Option>
                          ))}
                      </Select>
                      <Popover content="The zone in which the location should be created. (string)" trigger="hover" placement="right">
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>

                  <Form.Item name="name" label="Name" hasFeedback shouldUpdate={true} initialValue={""} rules={[{ required: false }]}>
                    <div className="d-flex align-items-center">
                      <Input id="de-loc-name" autoComplete="off" className="mr-3" />
                      <Popover content="Name of the location. (string)" trigger="hover" placement="right">
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>

                  <Form.Item
                    name="userLocationId"
                    label="User Location ID"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[{ required: false }]}
                  >
                    <div className="d-flex align-items-center">
                      <Input id="de-loc-user-loc-id" autoComplete="off" className="mr-3" />
                      <Popover content="User-specified identifier of the location. (string)" trigger="hover" placement="right">
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>

                  <Form.Item name="coordinatesChecked" valuePropName="checked" defaultChecked={false}>
                    <Switch
                      id="de-loc-coordinates-check"
                      onChange={() => setCoordinates(!coordinates)}
                      checkedChildren="Enter address"
                      unCheckedChildren="Enter coordinates"
                      defaultChecked={false}
                    />
                  </Form.Item>

                  {!coordinates && (
                    <div className="d-block my-4">
                      <a className="mr-3 my-4 text-dark">ADDRESS</a>
                      <div className="p-3 mt-2 mb-3 border rounded">
                        <Form.Item
                          name="street1"
                          label="Street 1"
                          className="mt-3"
                          hasFeedback
                          shouldUpdate={true}
                          initialValue={""}
                          rules={[
                            {
                              required: true,
                              message: "Please enter street 1.",
                            },
                          ]}
                        >
                          <div className="d-flex align-items-center">
                            <Input id="de-loc-street1" autoComplete="off" className="mr-3" />
                            <Popover content="The name of the road where an address is located. (string)" trigger="hover" placement="right">
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="street2"
                          label="Street 2"
                          hasFeedback
                          shouldUpdate={true}
                          initialValue={""}
                          rules={[{ required: false }]}
                        >
                          <div className="d-flex align-items-center">
                            <Input id="de-loc-street2" autoComplete="off" className="mr-3" />
                            <Popover
                              content="Any extra information about where the address is located. (string)"
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item name="unit" label="Unit" hasFeedback shouldUpdate={true} initialValue={""} rules={[{ required: false }]}>
                          <div className="d-flex align-items-center">
                            <Input id="de-loc-units" autoComplete="off" className="mr-3" />
                            <Popover content="The name of the unit. (string)" trigger="hover" placement="right">
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="city"
                          label="City"
                          hasFeedback
                          shouldUpdate={true}
                          initialValue={""}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your city.",
                            },
                          ]}
                        >
                          <div className="d-flex align-items-center">
                            <Input id="de-loc-city" autoComplete="off" className="mr-3" />
                            <Popover content="The name of the city. (string)" trigger="hover" placement="right">
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="stateProvince"
                          label="State Province"
                          hasFeedback
                          shouldUpdate={true}
                          initialValue={""}
                          rules={[
                            {
                              required: true,
                              message: "Please enter state province.",
                            },
                          ]}
                        >
                          <div className="d-flex align-items-center">
                            <Input id="de-loc-state-province" autoComplete="off" className="mr-3" />
                            <Popover
                              content="The name of the subdivision of a country, for example a state, a region or a province. (string)"
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="postalCode"
                          label="Postal Code"
                          hasFeedback
                          shouldUpdate={true}
                          initialValue={""}
                          rules={[
                            {
                              required: true,
                              message: "Please enter postal code.",
                            },
                          ]}
                        >
                          <div className="d-flex align-items-center">
                            <Input id="de-loc-postal-code" autoComplete="off" className="mr-3" />
                            <Popover
                              content="A postal code (or zip-code) that is used by a country's postal authority. (string)"
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="country"
                          label="Country"
                          hasFeedback
                          shouldUpdate={true}
                          initialValue={""}
                          rules={[
                            {
                              required: true,
                              message: "Please enter country.",
                            },
                          ]}
                        >
                          <div className="d-flex align-items-center">
                            <Input id="de-loc-country" autoComplete="off" className="mr-3" />
                            {/* <Select
                              showSearch
                              optionFilterProp="children"
                              className="mr-3"
                              placeholder="Select country"
                              filterOption={(input, option) =>
                                option.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {countries.length > 0 &&
                                countries.map((c, idx) => (
                                  <Option key={idx} value={c.name} className="my-0">
                                    <p>{c.name}</p>
                                  </Option>
                                ))}
                            </Select> */}
                            <Popover
                              content="Countries can be identified by name or country code. (string)"
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  )}

                  {coordinates && (
                    <>
                      <a className="my-4 text-dark">COORDINATES</a>
                      <div className="p-3 mt-2 mb-3 border rounded">
                        <Form.Item
                          name="lat"
                          label="Latitude"
                          className="mt-3"
                          hasFeedback
                          initialValue={""}
                          shouldUpdate={true}
                          rules={[
                            {
                              required: true,
                              message: "Please enter latitude.",
                            },
                          ]}
                        >
                          <div className="d-flex align-items-center">
                            <InputNumber
                              id="de-loc-coordinates-lat"
                              className="mr-3"
                              style={{
                                width: "100%",
                              }}
                            />
                            <Popover
                              content={
                                <div className="d-flex flex-column">
                                  <div>
                                    <strong>Coordinate latitude.</strong>
                                    &nbsp;(double)
                                  </div>
                                </div>
                              }
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="lng"
                          label="Longitude"
                          hasFeedback
                          initialValue={""}
                          shouldUpdate={true}
                          rules={[
                            {
                              required: true,
                              message: "Please enter longitude.",
                            },
                          ]}
                        >
                          <div className="d-flex align-items-center">
                            <InputNumber
                              id="de-loc-coordinates-lng"
                              className="mr-3"
                              style={{
                                width: "100%",
                              }}
                            />
                            <Popover
                              content={
                                <div className="d-flex flex-column">
                                  <div>
                                    <strong>Coordinate longitude.</strong>
                                    &nbsp;(double)
                                  </div>
                                </div>
                              }
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                      </div>
                    </>
                  )}

                  <a className="my-3" onClick={handleAdvancedView}>
                    <strong>
                      Advanced Location Options&nbsp;
                      {advancedFields ? <UpOutlined /> : <DownOutlined />}
                    </strong>
                  </a>
                  {advancedFields && (
                    <div className="my-4 mx-3">
                      <Form.Item
                        name="phoneNumber"
                        label="Phone Number"
                        className="mt-3"
                        hasFeedback
                        shouldUpdate={true}
                        initialValue={""}
                        rules={[{ required: false }]}
                      >
                        <div className="d-flex align-items-center">
                          <Input id="de-loc-phone-number" autoComplete="off" className="mr-3" />
                          <Popover
                            content="The phone number of the device added during registration. Enter without dashes or spaces. For example, 5556667777. (string)"
                            trigger="hover"
                            placement="right"
                          >
                            <InfoCircleOutlined />
                          </Popover>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name="supportsTextMessages"
                        label="Supports Text Messages"
                        hasFeedback
                        shouldUpdate={true}
                        initialValue={false}
                        rules={[{ required: false }]}
                      >
                        <div className="d-flex align-items-center">
                          <Select
                            id="de-loc-text-message"
                            className="mr-3"
                            onChange={(value) => {
                              form.setFieldsValue({
                                supportsTextMessages: value,
                              });
                            }}
                            defaultValue={false}
                          >
                            <Option id="de-loc-text-message-false" value={false}>
                              False
                            </Option>
                            <Option id="de-loc-text-message-true" value={true}>
                              True
                            </Option>
                          </Select>
                          <Popover
                            content="Gives users the option to allow or deny text messages. (boolean)"
                            trigger="hover"
                            placement="right"
                          >
                            <InfoCircleOutlined />
                          </Popover>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name="supportsPhoneCalls"
                        label="Supports Phone Calls"
                        hasFeedback
                        shouldUpdate={true}
                        initialValue={false}
                        rules={[{ required: false }]}
                      >
                        <div className="d-flex align-items-center">
                          <Select
                            id="de-loc-phone-calls"
                            className="mr-3"
                            onChange={(value) => {
                              form.setFieldsValue({
                                supportsPhoneCalls: value,
                              });
                            }}
                            defaultValue={false}
                          >
                            <Option id="de-loc-phone-calls-false" value={false}>
                              False
                            </Option>
                            <Option id="de-loc-phone-calls-true" value={true}>
                              True
                            </Option>
                          </Select>
                          <Popover
                            content="Calls can be made to the phone number associated with the address. (boolean)"
                            trigger="hover"
                            placement="right"
                          >
                            <InfoCircleOutlined />
                          </Popover>
                        </div>
                      </Form.Item>
                      <Form.Item
                        name="supportsChatMessages"
                        label="Supports Chat Messages"
                        hasFeedback
                        shouldUpdate={true}
                        initialValue={false}
                        rules={[{ required: false }]}
                      >
                        <div className="d-flex align-items-center">
                          <Select
                            id="de-loc-chat-message"
                            className="mr-3"
                            onChange={(value) => {
                              form.setFieldsValue({
                                supportsChatMessages: value,
                              });
                            }}
                            defaultValue={false}
                          >
                            <Option id="de-loc-chat-message-false" value={false}>
                              False
                            </Option>
                            <Option id="de-loc-chat-message-true" value={true}>
                              True
                            </Option>
                          </Select>
                          <Popover
                            content="The chat feature is available for the phone number associated with the address. (boolean)"
                            trigger="hover"
                            placement="right"
                          >
                            <InfoCircleOutlined />
                          </Popover>
                        </div>
                      </Form.Item>
                    </div>
                  )}
                </Form>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxZones: state.items.zones,
    areZonesUpdated: state.items.areZonesUpdated,
  };
};

const mapDispatchToProps = {
  updateLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationEntry);
