export const SIGNIN = "SIGNIN";
export const AUTHENTICATED = "AUTHENTICATED";
export const REQUIREACCEPT = "REQUIREACCEPT";
export const REQUIREACCOUNTSELECT = "REQUIREACCOUNTSELECT";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const SHOW_SUCCESS_MESSAGE = "SHOW_SUCCESS_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const AUTH_TOKEN = "auth_token";
export const ENCODED_AUTH_TOKEN = "encoded_auth_token";
export const SIGNIN_WITH_GOOGLE = "SIGNIN_WITH_GOOGLE";
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED = "SIGNIN_WITH_GOOGLE_AUTHENTICATED";
export const SIGNIN_WITH_FACEBOOK = "SIGNIN_WITH_FACEBOOK";
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED = "SIGNIN_WITH_FACEBOOK_AUTHENTICATED";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_REQUESTED = "FORGOT_PASSWORD_REQUESTED";
export const CONFIRM_FORGOT_PASSWORD_RESET = "CONFIRM_FORGOT_PASSWORD_RESET";
export const FORGOT_PASSWORD_RESET_SUCCESS = "FORGOT_PASSWORD_RESET_SUCCESS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const RESET_FORM = "RESET_FORM";
