import { UPDATE_ZONES, UPDATE_LOCATIONS, UPDATE_TRIPS, UPDATE_VEHICLES, UPDATE_TIMEZONE, UPDATE_SERVICES } from "../constants/Items";

const initState = {
  zones: [],
  vehicles: [],
  locations: [],
  trips: [],
  services: [],
  areZonesUpdated: false,
  areTripsUpdated: false,
  areLocationsUpdated: false,
  areVehiclesUpdated: false,
  areServicesUpdated: false,
  timezone: localStorage.getItem("preferredTimezone") || "America/Detroit",
};

const items = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_ZONES:
      return {
        ...state,
        zones: action.payload,
        areZonesUpdated: true,
      };
    case UPDATE_TRIPS:
      return {
        ...state,
        trips: action.payload,
        areTripsUpdated: true,
      };
    case UPDATE_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
        areVehiclesUpdated: true,
      };
    case UPDATE_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
        areLocationsUpdated: true,
      };
    case UPDATE_SERVICES:
      return {
        ...state,
        services: action.payload,
        areServicesUpdated: true,
      };
    case UPDATE_TIMEZONE:
      return {
        ...state,
        timezone: action.payload,
      };
    default:
      return state;
  }
};

export default items;
