import React from "react";
import { Form, Input, InputNumber, Popover, Select, DatePicker, TimePicker } from "antd";
import { InfoCircleOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";

import Waypoints from "../trip/sub-forms/Waypoints";
import Manifests from "../trip/sub-forms/Manifests";
import TPPay from "../trip/sub-forms/TransportationProviderPay";

const { Option } = Select;

const RfqTripForm = ({
  form,
  waypointList,
  setWaypointList,
  manifestList,
  setManifestList,
  advancedTripOptions,
  setAdvancedTripOptions,
  optimizationTimeWindow,
  setOptimizationTimeWindow,
  manifests,
  setManifests,
  transportationProviderPay,
  setTransportationProvidedPay,
}) => {
  return (
    <>
      <Form.Item name="userTripId" label="User Trip ID" hasFeedback shouldUpdate={true} initialValue={""} rules={[{ required: false }]}>
        <div className="d-flex align-items-center">
          <Input autoComplete="off" className="mr-3" placeholder="Enter user trip ID" />
          <Popover content="User-specified identifier of the trip. (string)" trigger="hover" placement="right">
            <InfoCircleOutlined />
          </Popover>
        </div>
      </Form.Item>

      <Form.Item
        name="maxStopsAllowed"
        label="Maximum Stops Allowed"
        hasFeedback
        shouldUpdate={true}
        initialValue={""}
        rules={[{ required: false }]}
      >
        <div className="d-flex align-items-center">
          <InputNumber
            autoComplete="off"
            className="mr-3"
            style={{
              width: "100%",
            }}
          />
          <Popover content="Maximum number of locations that can be visited in a route. (int32)" trigger="hover" placement="right">
            <InfoCircleOutlined />
          </Popover>
        </div>
      </Form.Item>
      <Form.Item
        name="routingScenarioMode"
        label="Routing Scenario Mode"
        hasFeedback
        shouldUpdate={true}
        initialValue={""}
        rules={[{ required: true, message: "Please specify routing scenario mode." }]}
      >
        <div className="d-flex align-items-center">
          <Select
            className="mr-3"
            onChange={(value) => {
              form.setFieldsValue({
                routingScenarioMode: value,
              });
            }}
          >
            <Option value="none">None</Option>
            <Option value="spsd">Single Pickup & Single Dropoff (SPSD)</Option>
            <Option value="spmd">Single Pickup & Multi Dropoff (SPMD)</Option>
            <Option value="mpsd">Multi Pickup & Single Dropoff (MPSD)</Option>
            <Option value="mpmd">Multi Pickup & Multi Dropoff (MPMD)</Option>
          </Select>
          <Popover
            content={
              <div className="d-flex flex-column">
                <div>
                  <strong>The delivery scenario used when optimizing the trip.</strong>
                  &nbsp;(string)
                </div>
                <br />
                <small>None : no restrictions on the delivery scenario.</small>
                <small>S.P.S.D. : single pickup and single drop-off.</small>
                <small>S.P.M.D. : single pickup and multi drop-off.</small>
                <small>M.P.S.D. : multi pickup and single drop-off.</small>
                <small>M.P.M.D. : muti pickup and multi drop-off.</small>
              </div>
            }
            trigger="hover"
            placement="right"
          >
            <InfoCircleOutlined />
          </Popover>
        </div>
      </Form.Item>

      <div className="my-3">
        <a className="my-4 text-dark" onClick={() => setOptimizationTimeWindow(!optimizationTimeWindow)}>
          OPTIMIZATION TIME WINDOW&nbsp;{optimizationTimeWindow ? <UpOutlined /> : <DownOutlined />}
        </a>
        {optimizationTimeWindow && (
          <div className="mt-4">
            <div className="d-flex align-items-center">
              <Form.Item
                label="Start Date"
                className="w-100"
                name="startDate"
                rules={[{ required: true, message: "Please specify start date." }]}
              >
                <DatePicker className="mr-2" />
              </Form.Item>
              <Form.Item
                label="Start Time"
                className="w-100"
                name="startTime"
                rules={[{ required: true, message: "Please specify start time." }]}
              >
                <TimePicker className="mr-2" />
              </Form.Item>

              <Popover
                content="The earliest time for the trip to start being optimized. The default for “startTimestamp” is the time the
                  trip is created. The date-time format is specified by RFC 3339, section 5.6; for example,
                  2017-07-21T17:32:28Z."
                trigger="hover"
                placement="right"
              >
                <InfoCircleOutlined className="align-self-center" />
              </Popover>
            </div>
            <div className="d-flex align-items-center">
              <Form.Item
                label="End Date"
                className="w-100"
                name="endDate"
                rules={[{ required: true, message: "Please specify end date." }]}
              >
                <DatePicker className="mr-2" />
              </Form.Item>
              <Form.Item
                label="End Time"
                className="w-100"
                name="endTime"
                rules={[{ required: true, message: "Please specify end time." }]}
              >
                <TimePicker className="mr-2" />
              </Form.Item>
              <Popover
                content={
                  <div className="d-flex">
                    The latest time for the trip to finish being optimized. The default for “endTimestamp” is 60 minutes after the trip is
                    created. The date-time format is specified by RFC 3339, section 5.6; for example, 2017-07-21T17:32:28Z.
                  </div>
                }
                trigger="hover"
                placement="right"
              >
                <InfoCircleOutlined />
              </Popover>
            </div>
          </div>
        )}
      </div>

      <div className="mt-3">
        <a className="d-block text-dark"> WAYPOINTS</a>
        <Waypoints form={form} waypointList={waypointList} setWaypointList={setWaypointList} />
      </div>

      <a className="my-3" onClick={() => setAdvancedTripOptions(!advancedTripOptions)}>
        <strong>Advanced Trip Options&nbsp;{advancedTripOptions ? <UpOutlined /> : <DownOutlined />}</strong>
      </a>

      {advancedTripOptions && (
        <div className="px-3">
          <div className="mt-3">
            <a className="d-block text-dark" onClick={() => setManifests(!manifests)}>
              {" "}
              MANIFESTS&nbsp;{manifests ? <UpOutlined /> : <DownOutlined />}
            </a>
            {manifests && <Manifests form={form} manifestList={manifestList} setManifestList={setManifestList} />}
          </div>

          <div className="mt-3">
            <a className="d-block text-dark" onClick={() => setTransportationProvidedPay(!transportationProviderPay)}>
              {" "}
              TRANSPORTATION PROVIDER PAY&nbsp;{transportationProviderPay ? <UpOutlined /> : <DownOutlined />}
            </a>
            {transportationProviderPay && <TPPay form={form} />}
          </div>
        </div>
      )}
    </>
  );
};

export default RfqTripForm;
