export const getApiBaseUrl = function (ver) {
  let api_url = "";

  if (ver == "1") {
    if (process.env.REACT_APP_CROSS_NODE_ENV === "production") {
      api_url = "https://api.interplai.io/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "staging") {
      api_url = "https://api.staging.direct.interplai.io/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development") {
      api_url = "https://i-direct-api-njsi5yolsq-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development2") {
      api_url = "https://i-direct-api-l3iwalfzpq-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development3") {
      api_url = "https://i-direct-api-gl6pw4ruka-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development4") {
      api_url = "https://i-direct-api-o7vxc2jftq-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development5") {
      api_url = "https://i-direct-api-ixrpcy5hba-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "prod-dev") {
      api_url = "https://dev.api.interplai.io/v1";
    } else {
      api_url = "http://172.19.0.2:2000/v1";
    }
  } else {
    if (process.env.REACT_APP_CROSS_NODE_ENV === "production") {
      api_url = "https://api.interplai.io/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "staging") {
      api_url = "https://api.staging.direct.interplai.io/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development") {
      api_url = "https://i-direct-api-njsi5yolsq-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development2") {
      api_url = "https://i-direct-api-l3iwalfzpq-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development3") {
      api_url = "https://i-direct-api-gl6pw4ruka-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development4") {
      api_url = "https://i-direct-api-o7vxc2jftq-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development5") {
      api_url = "https://i-direct-api-ixrpcy5hba-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "prod-dev") {
      api_url = "https://dev.api.interplai.io/v2";
    } else {
      api_url = "http://172.19.0.2:2000/v2";
    }
  }

  return api_url;
};
