import React, { useState, useEffect } from "react";
import {
  Row,
  Form,
  Col,
  Input,
  InputNumber,
  Button,
  Popover,
  Select,
  Typography,
  Switch,
  Modal,
  Alert,
} from "antd";
import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";
import tickimg from "../../img/correct.png";
import errorimg from "../../img/cross.png";

const { Option } = Select;
const { Paragraph } = Typography;

const QuoteEntry = ({ showQuoteModal, setShowQuoteModal }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({
    state: false,
    message: {
      id: null,
      zoneId: null,
    },
  });
  const [error, setError] = useState(null);

  const [zones, setZones] = useState([]);
  const [rfqList, setRfqList] = useState([]);
  const [rfqLoading, setRfqLoading] = useState(false);
  const [manualRfq, setManualRfq] = useState(false);

  useEffect(() => {
    const fetchZones = async () => {
      const wm = await InterplaiDirectService.getWorldModels();
      setZones(wm);
    };
    fetchZones();
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4500);
    }
  }, [error]);

  const handleZoneChange = async (value) => {
    form.setFieldsValue({
      zoneId: value,
    });
    setRfqLoading(true);
    try {
      const res = await InterplaiDirectService.getAllRFQInAZone(value);
      setRfqList(res);
      setRfqLoading(false);
    } catch (error) {
      setRfqLoading(false);
    }
  };

  const handleQuoteClose = () => {
    setSuccess({
      state: false,
      message: {
        id: null,
        zoneId: null,
      },
    });
    setError(null);
    setShowQuoteModal(false);
  };

  const onQuoteSubmit = async (values) => {
    setLoading(true);
    try {
      let payload = {
        fee: Number(values.fee),
        currency: values.currency,
      };

      let rfqId = values.requestforquotesId;
      if (manualRfq) {
        rfqId = values.manualRfqId;
      }

      const res = await InterplaiDirectService.createQuotes(
        values.zoneId,
        rfqId,
        payload
      );
      setSuccess({
        ...success,
        state: true,
        message: {
          id: res.id,
          zoneId: res.zoneId,
        },
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onQuoteSubmit(values);
      })
      .catch((err) => {
        setError("One or more required fields are missing");
      });
  };

  const onQuoteReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={showQuoteModal}
      onCancel={handleQuoteClose}
      title="Create a Quote"
      maskClosable={false}
      footer={
        success.state
          ? [
              <Button key="close" type="primary" onClick={handleQuoteClose}>
                Close
              </Button>,
            ]
          : [
              <Button
                key="clear"
                onClick={onQuoteReset}
                style={{ float: "left" }}
              >
                <ReloadOutlined />
                Clear all
              </Button>,
              <Button
                key="submit"
                htmlType="submit"
                type="primary"
                loading={loading}
                onClick={onSubmit}
                id="quote-entry button"
              >
                Create
              </Button>,

              <Button type="ghost" key="cancel" onClick={handleQuoteClose}>
                Cancel
              </Button>,
            ]
      }
      width={540}
    >
      {error && (
        <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
              <Alert message={error} showIcon type="error" closable />
            </Col>
          </Row>
        </div>
      )}
      <div
        style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "56vh" }}
      >
        {success.state ? (
          <Row gutter={16} justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="d-block mb-4">
                <img
                  src={tickimg}
                  className="mr-3"
                  height="28px"
                  width="28px"
                  alt="success"
                />
                <strong>The quote is successfully created!</strong>
              </div>
              <div className="d-block">
                <strong>Quote ID: </strong>
                <Paragraph copyable>{success.message.id}</Paragraph>
              </div>
              <div className="d-block">
                <strong>Corresponding Zone ID: </strong>
                <Paragraph copyable>{success.message.zoneId}</Paragraph>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form
                  form={form}
                  layout="vertical"
                  name="create-quote"
                  preserve={false}
                  className="m-3"
                  id="quote-entry-form"
                >
                  <div className="d-flex justify-content-center mb-2">
                    <p>
                      Note: Only provider accounts can create quotes in their
                      respective zones.
                    </p>
                  </div>
                  <Form.Item
                    name="zoneId"
                    label="Zone"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[
                      { required: true, message: "Please select a zone." },
                    ]}
                  >
                    <div className="d-flex align-items-center">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="mr-3"
                        placeholder="Select a zone"
                        filterOption={(input, option) =>
                          option.props.children[0].props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.props.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          form.setFieldsValue({
                            zoneId: value,
                          });
                          handleZoneChange(value);
                        }}
                      >
                        {zones.length > 0 &&
                          zones.map((worldmodel) => (
                            <Option key={worldmodel.id} value={worldmodel.id}>
                              <strong>{worldmodel.name}</strong> (
                              {worldmodel.id})
                            </Option>
                          ))}
                      </Select>
                      <Popover
                        content="The zone in which the vehicle is to be created. (string)"
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>

                  {!manualRfq && (
                    <Form.Item
                      name="requestforquotesId"
                      label="Request For Quotes ID"
                      rules={[
                        {
                          required: !manualRfq,
                          message: "RFQ ID is required. Select a zone first.",
                        },
                      ]}
                      initialValue={""}
                    >
                      <div className="d-flex align-items-center">
                        <Select
                          showSearch
                          loading={rfqLoading}
                          optionFilterProp="children"
                          className="mr-3"
                          placeholder={
                            rfqLoading
                              ? "Loading RFQ options..."
                              : "Select request for quote ID"
                          }
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            form.setFieldsValue({
                              requestforquotesId: value,
                            });
                          }}
                        >
                          {rfqList.length > 0 &&
                            rfqList.map((rfq) => (
                              <Option key={rfq.id} value={rfq.id}>
                                RFQ - {rfq.id}
                              </Option>
                            ))}
                        </Select>
                        <Popover
                          content="Interplai-specified identifier of the request for quotes document. (string)"
                          trigger="hover"
                          placement="right"
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                    </Form.Item>
                  )}

                  <Form.Item
                    name="manualRfqChecked"
                    valuePropName="checked"
                    defaultChecked={false}
                  >
                    <Switch
                      onChange={() => setManualRfq(!manualRfq)}
                      checkedChildren="Select RFQ ID from options"
                      unCheckedChildren="Enter RFQ ID manually"
                      defaultChecked={false}
                    />
                  </Form.Item>

                  {manualRfq && (
                    <Form.Item
                      name="manualRfqId"
                      label="Request For Quotes ID"
                      rules={[{ required: manualRfq, message: "Enter RFQ ID" }]}
                    >
                      <div className="d-flex align-items-center">
                        <Input
                          className="mr-3 w-100"
                          placeholder="Enter RFQ ID"
                        />
                        <Popover
                          content="Interplai-specified identifier of the request for quotes document. (string)"
                          trigger="hover"
                          placement="right"
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                    </Form.Item>
                  )}

                  <div className="p-3 border rounded">
                    <Form.Item
                      name="fee"
                      label="Fee"
                      rules={[{ required: true, message: "Enter fee" }]}
                    >
                      <div className="d-flex align-items-center">
                        <InputNumber
                          className="mr-3 w-100"
                          placeholder="Enter fee"
                        />
                        <Popover
                          content="Pricing quoted by the provider. (number)"
                          trigger="hover"
                          placement="right"
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="currency"
                      label="Currency"
                      rules={[{ required: true, message: "Enter currency" }]}
                    >
                      <div className="d-flex align-items-center">
                        <Select
                          className="mr-3"
                          placeholder="Select currency"
                          onChange={(value) => {
                            form.setFieldsValue({
                              currency: value,
                            });
                          }}
                        >
                          <Option value="usd">
                            United States Dollar (USD)
                          </Option>
                          <Option value="veb">Venezuelan Bolivar (VEB)</Option>
                          <Option value="svc">Salvadoran Colón (SVC)</Option>
                          <Option value="hnl">Honduran Lempira (HNL)</Option>
                          <Option value="nio">Nicaraguan Córdoba (NIO)</Option>
                          <Option value="jmd">Jamaican Dollar (JMD)</Option>
                          <Option value="gtq">Guatemalan Quetzal (GTQ)</Option>
                        </Select>
                        <Popover
                          content="Currency code for the payment. Options include USD, VEB, SVC, HNL, NIO, JMD, GTQ. (string)"
                          trigger="hover"
                          placement="right"
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                    </Form.Item>
                  </div>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};

export default QuoteEntry;
