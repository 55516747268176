import React, { CSSProperties, useState, useEffect, useContext } from "react";
import { VersionContext } from "../../Context";
import { SocketContext } from "context/socket";
import { connect } from "react-redux";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import AppViews from "views/app-views";
import { Layout, Grid, Alert } from "antd";
import "./index.css";
import CircleLoader from "react-spinners/CircleLoader";

import navigationConfig from "configs/NavigationConfig";
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_SIDE, NAV_TYPE_TOP, DIR_RTL, DIR_LTR } from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import InterplaiDirectService from "services/InterplaiDirectService";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const { globalLoader } = useContext(VersionContext);

  const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname);

  const override = {
    display: "block",
    alignSelf: "center",
    top: "75%",
    margin: "auto",
    borderColor: "green",
  };

  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}

      {globalLoader ? (
        <div className="example">
          <CircleLoader
            color={"#26A65B"}
            loading={globalLoader}
            cssOverride={override}
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          <Layout className="app-container">
            {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
            <Layout className="app-layout" style={getLayoutDirectionGutter()}>
              <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
                <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
                {/* {alertMessage.isMessage && <Alert type={alertMessage.type} description={alertMessage.message} closable className="mb-3" />} */}
                <Content>
                  <AppViews />
                </Content>
              </div>
              {/* <Footer /> */}
            </Layout>
          </Layout>
          {isMobile ? <MobileNav routeInfo={currentRouteInfo} /> : null}
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(React.memo(AppLayout));
