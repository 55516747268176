import {
  ApiOutlined,
  PieChartOutlined,
  LineChartOutlined,
  ReadOutlined,
  WalletOutlined,
  CloudUploadOutlined,
  DatabaseOutlined,
  MessageOutlined,
  CompassOutlined,
  QuestionCircleOutlined,
  DashboardOutlined,
  FileTextOutlined,
  FormOutlined,
  HeatMapOutlined,
  UserOutlined,
  ClusterOutlined,
  SettingOutlined,
  SubnodeOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";

var apiVersion = localStorage.getItem("apiVersion");

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/operations`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "view-dashboard",
        path: `${APP_PREFIX_PATH}/operations/dashboard`,
        title: "sidenav.dashboard.overview",
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "view-map",
        path: `${APP_PREFIX_PATH}/operations/map`,
        title: "sidenav.dashboard.map",
        icon: CompassOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "view-analytics",
      //   path: `${APP_PREFIX_PATH}/operations/analytics`,
      //   title: "sidenav.dashboard.analytics",
      //   icon: PieChartOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "view-operations-table",
        path: `${APP_PREFIX_PATH}/operations/table`,
        title: "sidenav.dashboard.operations",
        icon: ClusterOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "table-zones",
            path: `${APP_PREFIX_PATH}/operations/table/zones`,
            title: "sidenav.dashboard.operations.zones",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "table-trips",
            path: `${APP_PREFIX_PATH}/operations/table/trips`,
            title: "sidenav.dashboard.operations.trips",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "table-routes",
            path: `${APP_PREFIX_PATH}/operations/table/routes`,
            title: "sidenav.dashboard.operations.routes",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "table-vehicles",
            path: `${APP_PREFIX_PATH}/operations/table/vehicles`,
            title: "sidenav.dashboard.operations.vehicles",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "table-locations",
            path: `${APP_PREFIX_PATH}/operations/table/locations`,
            title: "sidenav.dashboard.operations.locations",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "table-services",
            path: `${APP_PREFIX_PATH}/operations/table/services`,
            title: "sidenav.dashboard.operations.services",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "table-rfq",
            path: `${APP_PREFIX_PATH}/operations/table/requests-for-quotes`,
            title: "sidenav.dashboard.operations.rfq",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "table-quotes",
            path: `${APP_PREFIX_PATH}/operations/table/quotes`,
            title: "sidenav.dashboard.operations.quotes",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "view-import-and-export",
        path: `${APP_PREFIX_PATH}/operations/import-items`,
        title: "sidenav.dataTransfer.importandexport",
        icon: CloudUploadOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "view-data-entry",
        title: "sidenav.dataTransfer.dataEntry",
        icon: FormOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "data-entry-zone",
            title: "sidenav.dataTransfer.dataEntry.zone",
            expand: true,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "data-entry-vehicle",
            title: "sidenav.dataTransfer.dataEntry.vehicle",
            expand: true,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "data-entry-location",
            title: "sidenav.dataTransfer.dataEntry.location",
            expand: true,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "data-entry-service",
            title: "sidenav.dataTransfer.dataEntry.service",
            expand: true,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "data-entry-trip-basic",
            title: "sidenav.dataTransfer.dataEntry.tripBasic",
            expand: true,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "data-entry-trip",
            title: "sidenav.dataTransfer.dataEntry.tripAdvanced",
            expand: true,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "data-entry-rfq",
            title: "sidenav.dataTransfer.dataEntry.rfq",
            expand: true,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "data-entry-quote",
            title: "sidenav.dataTransfer.dataEntry.quote",
            expand: true,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "data-entry-cost-matrix",
            title: "sidenav.dataTransfer.dataEntry.costMatrix",
            expand: true,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "view-chat",
        path: `${APP_PREFIX_PATH}/operations/messages`,
        title: "sidenav.dashboard.operations.chatMessages",
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

// const visualizationNavTree =
//   apiVersion == 1
//     ? [
//         {
//           key: "visualization",
//           path: `${APP_PREFIX_PATH}/visualization`,
//           title: "sidenav.visualization",
//           icon: HeatMapOutlined,
//           breadcrumb: false,
//           submenu: [
//             {
//               key: "view-visualization",
//               path: `${APP_PREFIX_PATH}/visualization`,
//               title: "sidenav.visualization",
//               icon: HeatMapOutlined,
//               breadcrumb: false,
//               submenu: [],
//             },
//           ],
//         },
//       ]
//     : [];

const billingNavTree = [
  {
    key: "billing",
    path: `${APP_PREFIX_PATH}/billing`,
    title: "sidenav.billing-and-pricing",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      // {
      //   key: "view-billing-reports",
      //   path: `${APP_PREFIX_PATH}/billing/reports`,
      //   title: "sidenav.billing.reports",
      //   icon: FileTextOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "view-payment-settings",
        path: `${APP_PREFIX_PATH}/billing/payment-settings`,
        title: "sidenav.billing.payment-settings",
        icon: WalletOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "view-invoices",
      //   path: `${APP_PREFIX_PATH}/billing/invoices`,
      //   title: "sidenav.billing.invoices",
      //   icon: DollarCircleOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const apiNavTree = [
  {
    key: "api",
    path: `${APP_PREFIX_PATH}/admin`,
    title: "sidenav.administration",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      // {
      //   key: "view-api-reports",
      //   path: `${APP_PREFIX_PATH}/api/reports`,
      //   title: "sidenav.api.reports",
      //   icon: FileTextOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "view-preferences",
        path: `${APP_PREFIX_PATH}/admin/settings`,
        title: "sidenav.account.preference-settings",
        icon: SettingOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "view-credentials",
        path: `${APP_PREFIX_PATH}/admin/api-keys`,
        title: "sidenav.api.credentials",
        icon: ApiOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "view-all-users",
        path: `${APP_PREFIX_PATH}/admin/users`,
        title: "sidenav.user.userslist",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "view-intergrations",
      //   path: `${APP_PREFIX_PATH}/admin/integrations`,
      //   title: "sidenav.dashboard.operations.integrations",
      //   icon: SubnodeOutlined,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: "view-integrations-marketplace",
      //       path: `${APP_PREFIX_PATH}/admin/integrations/marketplace`,
      //       title: "sidenav.account.integrations.marketplace",
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "view-integrations-register",
      //       path: `${APP_PREFIX_PATH}/admin/integrations/register`,
      //       title: "sidenav.account.integrations.register",
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //     {
      //       key: "retrieve-transactions",
      //       path: `${APP_PREFIX_PATH}/admin/integrations/transactions`,
      //       title: "sidenav.account.integrations.transactions",
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
    ],
  },
];

const supportNavTree = [
  {
    key: "support",
    path: `${APP_PREFIX_PATH}/support`,
    title: "sidenav.support",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "support-cases",
        path: `${APP_PREFIX_PATH}/support/cases`,
        title: "sidenav.support.cases",
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "support-documentation",
        path: `${APP_PREFIX_PATH}/support/documentation`,
        title: "sidenav.support.documentation",
        icon: ReadOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree, ...apiNavTree, ...billingNavTree, ...supportNavTree];

export default navigationConfig;
