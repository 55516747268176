import React, { useState, useEffect } from "react";
import { Form, Button, Select, Input, InputNumber, Popover, Switch } from "antd";
import { InfoCircleOutlined, PlusOutlined, MinusCircleOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";

const { Option } = Select;

const TPPay = () => {
  return (
    <div className="mb-4 mt-2 p-3 border rounded">
      <Form.Item name="fee" label="Fee" rules={[{ required: false }]}>
        <InputNumber className="mr-3 w-100" placeholder="Enter fee" />
      </Form.Item>
      <Form.Item name="currency" label="Currency" rules={[{ required: false }]}>
        <Select className="mr-3" placeholder="Select currency">
          <Option value="usd">United States Dollar (USD)</Option>
          <Option value="veb">Venezuelan Bolivar (VEB)</Option>
          <Option value="svc">Salvadoran Colón (SVC)</Option>
          <Option value="hnl">Honduran Lempira (HNL)</Option>
          <Option value="nio">Nicaraguan Córdoba (NIO)</Option>
          <Option value="jmd">Jamaican Dollar (JMD)</Option>
          <Option value="gtq">Guatemalan Quetzal (GTQ)</Option>
        </Select>
      </Form.Item>
      <Form.Item name="customerTip" label="Customer Tip" rules={[{ required: false }]}>
        <InputNumber className="mr-3 w-100" placeholder="Enter customer tip" />
      </Form.Item>
    </div>
  );
};

export default TPPay;
