import React, { useState, useEffect } from "react";
import {
  Row,
  Form,
  Col,
  Input,
  InputNumber,
  Button,
  Popover,
  Select,
  Typography,
  Modal,
  Space,
  Alert,
} from "antd";
import {
  UpOutlined,
  DownOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";
import tickimg from "../../img/correct.png";
import errorimg from "../../img/cross.png";

import { connect } from "react-redux";
import { updateVehicles } from "redux/actions/Items";

const { Option } = Select;
const { Paragraph } = Typography;

const VehicleEntry = (props) => {
  const {
    updateVehicles,
    reduxZones,
    areZonesUpdated,
    showVehicleModal,
    setShowVehicleModal,
  } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [advancedFields, setAdvancedFields] = useState(false);
  const [success, setSuccess] = useState({
    state: false,
    message: {
      id: null,
      zoneId: null,
      userVehicleId: null,
    },
  });
  const [error, setError] = useState(null);

  const [zones, setZones] = useState([]);

  useEffect(() => {
    const fetchZones = async () => {
      const wm = await InterplaiDirectService.getWorldModels();
      setZones(wm);
    };
    fetchZones();
  }, []);

  useEffect(() => {
    if (areZonesUpdated) {
      setZones(reduxZones);
    }
  }, [areZonesUpdated, reduxZones]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4500);
    }
  }, [error]);

  const handleAdvancedView = () => {
    setAdvancedFields(!advancedFields);
  };

  const handleVehicleClose = () => {
    setSuccess({
      state: false,
      message: {
        id: null,
        zoneId: null,
        userVehicleId: null,
      },
    });
    setError(null);
    setShowVehicleModal(false);
  };

  const onVehicleSubmit = async (values) => {
    setLoading(true);
    try {
      let payload = {
        userVehicleId: values.userVehicleId,
        dutyMode: values.dutyMode || "onDuty",
        coordinates: {
          lat: Number(values.lat),
          lng: Number(values.lng),
        },
      };
      if (advancedFields) {
        payload = {
          ...payload,
          fixedCost: Number(values.fixedCost),
          capacity: Number(values.capacity),
          driverName: values.driverName,
          driverPhoneNumber: values.driverPhoneNumber,
          licensePlateNumber: values.licensePlateNumber,
        };

        if (values.speed) {
          payload = { ...payload, speed: values.speed };
        }
        if (values.services && values.services.length > 0) {
          payload = { ...payload, services: values.services };
        }
      }
      const res = await InterplaiDirectService.createVehicles(
        values.zoneId,
        payload
      );
      const allvehicles = await InterplaiDirectService.getAllVehicles(
        values.zoneId
      );
      updateVehicles(allvehicles);
      setSuccess({
        ...success,
        state: true,
        message: {
          id: res.id,
          zoneId: res.zoneId,
          userVehicleId: res.userVehicleId ? res.userVehicleId : "<unnamed>",
        },
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onVehicleSubmit(values);
      })
      .catch((err) => {
        setError("One or more required fields are missing");
      });
  };

  const onVehicleReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={showVehicleModal}
      onCancel={handleVehicleClose}
      title="Create a Vehicle"
      maskClosable={false}
      footer={
        success.state
          ? [
              <Button
                key="close"
                type="primary"
                onClick={handleVehicleClose}
                id="create-vehicle-close"
              >
                Close
              </Button>,
            ]
          : [
              <Button
                key="clear"
                onClick={onVehicleReset}
                style={{ float: "left" }}
                id="create-vehicle-reset"
              >
                <ReloadOutlined />
                Clear all
              </Button>,
              <Button
                key="submit"
                htmlType="submit"
                type="primary"
                loading={loading}
                onClick={onSubmit}
                id="create-vehicle-button"
              >
                Create
              </Button>,

              <Button
                type="ghost"
                key="cancel"
                onClick={handleVehicleClose}
                id="create-vehicle-close"
              >
                Cancel
              </Button>,
            ]
      }
      width={540}
      className="mb-4"
    >
      {error && (
        <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
              <Alert message={error} showIcon type="error" closable />
            </Col>
          </Row>
        </div>
      )}
      <div
        style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "60vh" }}
      >
        {success.state ? (
          <Row gutter={16} justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="d-block mb-4">
                <img
                  src={tickimg}
                  className="mr-3"
                  height="28px"
                  width="28px"
                  alt="success"
                />
                <strong>The vehicle is successfully created!</strong>
              </div>
              <div className="d-block">
                <strong>Vehicle ID: </strong>
                <Paragraph copyable>{success.message.id}</Paragraph>
              </div>
              <div className="d-block">
                <strong>User Vehicle ID: </strong>
                <Paragraph>{success.message.userVehicleId}</Paragraph>
              </div>
              <div className="d-block">
                <strong>Corresponding Zone ID: </strong>
                <Paragraph copyable>{success.message.zoneId}</Paragraph>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form
                  form={form}
                  layout="vertical"
                  name="create-vehicle"
                  preserve={false}
                  className="m-3"
                  id="create-vehicle-form"
                >
                  <Form.Item
                    name="zoneId"
                    label="Zone"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[
                      { required: true, message: "Please select a zone." },
                    ]}
                  >
                    <div className="d-flex align-items-center">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="mr-3"
                        placeholder="Select a zone"
                        filterOption={(input, option) =>
                          option.props.children[0].props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.props.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          form.setFieldsValue({
                            zoneId: value,
                          });
                        }}
                      >
                        {zones.length > 0 &&
                          zones.map((worldmodel) => (
                            <Option key={worldmodel.id} value={worldmodel.id}>
                              <strong>{worldmodel.name}</strong> (
                              {worldmodel.id})
                            </Option>
                          ))}
                      </Select>
                      <Popover
                        content="The zone in which the vehicle is to be created. (string)"
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="userVehicleId"
                    label="User Vehicle ID"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[{ required: false }]}
                  >
                    <div className="d-flex align-items-center">
                      <Input
                        id="de-veh-user-veh-id"
                        autoComplete="off"
                        className="mr-3"
                      />
                      <Popover
                        content="User-specified identifier of the vehicle. (string)"
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="dutyMode"
                    label="Duty Mode"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={"onDuty"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the duty mode of the vehicle.",
                      },
                    ]}
                  >
                    <div className="d-flex align-items-center">
                      <Select
                        id="de-veh-duty-mode"
                        className="mr-3"
                        onChange={(value) => {
                          form.setFieldsValue({
                            dutyMode: value,
                          });
                        }}
                        defaultValue={"onDuty"}
                      >
                        <Option id="de-veh-duty-mode-onduty" value="onDuty">
                          On Duty
                        </Option>
                        <Option
                          id="de-veh-duty-mode-dutyprivate"
                          value="onDutyPrivate"
                        >
                          On Duty Private
                        </Option>
                        <Option id="de-veh-duty-mode-offduty" value="offDuty">
                          Off Duty
                        </Option>
                      </Select>
                      <Popover
                        content={
                          <div className="d-flex flex-column">
                            <div>
                              <strong>
                                A vehicle's ability to perform mobility task.
                              </strong>
                              &nbsp;(string)
                            </div>
                            <br />
                            <small>
                              On Duty : the vehicle and the driver are available
                              to be assigned a mobility task.
                            </small>
                            <small>
                              On Duty Private : the vehicles and the driver are
                              online but not available to use for an
                              optimization solution..
                            </small>
                            <small>
                              Off Duty : the driver and the vehicle are not
                              available to accept any tasks.
                            </small>
                          </div>
                        }
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                  <a className="my-3 text-dark">COORDINATES</a>
                  <div className="p-3 mt-2 mb-3 border rounded">
                    <Form.Item
                      name="lat"
                      label="Latitude"
                      className="mt-3"
                      hasFeedback
                      initialValue={""}
                      shouldUpdate={true}
                      rules={[
                        { required: true, message: "Please enter latitude." },
                      ]}
                    >
                      <div className="d-flex align-items-center">
                        <InputNumber
                          id="de-veh-coordinates-lat"
                          className="mr-3"
                          style={{
                            width: "100%",
                          }}
                        />
                        <Popover
                          content={
                            <div className="d-flex flex-column">
                              <div>
                                <strong>Coordinate latitude.</strong>
                                &nbsp;(double)
                              </div>
                            </div>
                          }
                          trigger="hover"
                          placement="right"
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="lng"
                      label="Longitude"
                      hasFeedback
                      initialValue={""}
                      shouldUpdate={true}
                      rules={[
                        { required: true, message: "Please enter longitude." },
                      ]}
                    >
                      <div className="d-flex align-items-center">
                        <InputNumber
                          id="de-veh-coordinates-lng"
                          className="mr-3"
                          style={{
                            width: "100%",
                          }}
                        />
                        <Popover
                          content={
                            <div className="d-flex flex-column">
                              <div>
                                <strong>Coordinate longitude.</strong>
                                &nbsp;(double)
                              </div>
                            </div>
                          }
                          trigger="hover"
                          placement="right"
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                    </Form.Item>
                  </div>

                  <a className="my-3" onClick={handleAdvancedView}>
                    <strong>
                      Advanced Vehicle Options&nbsp;
                      {advancedFields ? <UpOutlined /> : <DownOutlined />}
                    </strong>
                  </a>
                  {advancedFields && (
                    <div className="my-4 mx-3">
                      <div className="my-3">
                        <Form.Item
                          name="speed"
                          label="Speed"
                          hasFeedback
                          initialValue={""}
                          shouldUpdate={true}
                          rules={[{ required: false }]}
                        >
                          <div className="d-flex align-items-center">
                            <InputNumber
                              id="de-veh-speed"
                              className="mr-3"
                              style={{
                                width: "100%",
                              }}
                            />
                            <Popover
                              content={
                                <div className="d-flex flex-column">
                                  <div>
                                    <strong>
                                      Maximum vehicle speed, in meters per
                                      second (m/s).
                                    </strong>
                                    &nbsp;(float)
                                  </div>
                                </div>
                              }
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="capacity"
                          label="Capacity"
                          hasFeedback
                          initialValue={0}
                          shouldUpdate={true}
                          rules={[{ required: false }]}
                        >
                          <div className="d-flex align-items-center">
                            <InputNumber
                              id="de-veh-capacity"
                              className="mr-3"
                              style={{
                                width: "100%",
                              }}
                              defaultValue={0}
                            />
                            <Popover
                              content={
                                <div className="d-flex flex-column">
                                  <div>
                                    <strong>
                                      Amount of cargo that the vehicle can
                                      carry.
                                    </strong>
                                    &nbsp;(int32)
                                  </div>
                                </div>
                              }
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="fixedCost"
                          label="Fixed Cost"
                          hasFeedback
                          initialValue={0}
                          shouldUpdate={true}
                          rules={[{ required: false }]}
                        >
                          <div className="d-flex align-items-center">
                            <InputNumber
                              id="de-veh-fixedCost"
                              className="mr-3"
                              style={{
                                width: "100%",
                              }}
                              defaultValue={0}
                            />
                            <Popover
                              content={
                                <div className="d-flex flex-column">
                                  <div>
                                    <strong>
                                      A set cost factor that may be assigned to
                                      a vehicle.
                                    </strong>
                                    &nbsp;(int32)
                                  </div>
                                </div>
                              }
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="driverName"
                          label="Driver Name"
                          hasFeedback
                          shouldUpdate={true}
                          initialValue={""}
                          rules={[{ required: false }]}
                        >
                          <div className="d-flex align-items-center">
                            <Input
                              id="de-veh-driverName"
                              autoComplete="off"
                              className="mr-3"
                            />
                            <Popover
                              content="Name of the driver assigned to the vehicle. (string)"
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="driverPhoneNumber"
                          label="Driver Phone Number"
                          hasFeedback
                          shouldUpdate={true}
                          initialValue={""}
                          rules={[{ required: false }]}
                        >
                          <div className="d-flex align-items-center">
                            <Input
                              id="de-veh-driverPhoneNumber"
                              autoComplete="off"
                              className="mr-3"
                            />
                            <Popover
                              content="The phone number of the driver assigned to the vehicle. (string)"
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.Item
                          name="licensePlateNumber"
                          label="License Plate Number"
                          hasFeedback
                          shouldUpdate={true}
                          initialValue={""}
                          rules={[{ required: false }]}
                        >
                          <div className="d-flex align-items-center">
                            <Input
                              id="de-veh-licensePlateNumber"
                              autoComplete="off"
                              className="mr-3"
                            />
                            <Popover
                              content="Vehicle's registration number. (string)"
                              trigger="hover"
                              placement="right"
                            >
                              <InfoCircleOutlined />
                            </Popover>
                          </div>
                        </Form.Item>
                        <Form.List name="services" className="my-4">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Space
                                  key={key}
                                  className="my-4 d-block w-100"
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...restField}
                                    label="Service ID"
                                    name={[name, "serviceId"]}
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Service ID"
                                      className="w-100"
                                    />
                                  </Form.Item>
                                  <MinusCircleOutlined
                                    onClick={() => remove(name)}
                                  />
                                </Space>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  icon={<PlusOutlined />}
                                >
                                  Add Service ID
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </div>
                    </div>
                  )}
                </Form>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxZones: state.items.zones,
    areZonesUpdated: state.items.areZonesUpdated,
  };
};

const mapDispatchToProps = {
  updateVehicles,
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleEntry);
