import React, { useState, useEffect, useContext, useCallback } from "react";
import { connect } from "react-redux";
import { Layout, Tag, Switch, Popover } from "antd";
import { VersionContext } from "../../Context";
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined } from "@ant-design/icons";
import Logo from "./Logo";
import NavDelegate from "./NavDelegate";
import NavProfile from "./NavProfile";
import NavSearch from "./NavSearch";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from "constants/ThemeConstant";
import utils from "utils";
import InterplaiDirectService from "services/InterplaiDirectService";
import { useThemeSwitcher } from "react-css-theme-switcher";

const { Header } = Layout;

export const HeaderNav = (props) => {
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, direction } = props;
  const [searchActive, setSearchActive] = useState(false);
  const { apiVersion, setApiVersion, setGlobalLoader } = useContext(VersionContext);

  const { switcher, currentTheme, themes } = useThemeSwitcher();

  const [isDarkMode, setIsDarkMode] = useState(currentTheme === themes.dark);

  useEffect(() => {
    localStorage.setItem("colorTheme", isDarkMode ? themes.dark : themes.light);
  }, [isDarkMode]);

  const onChangeTheme = () => {
    setIsDarkMode((prevIsDarkMode) => {
      const newIsDarkMode = !prevIsDarkMode;
      localStorage.setItem("colorTheme", newIsDarkMode ? themes.dark : themes.light);
      switcher({ theme: newIsDarkMode ? themes.dark : themes.light });
      return newIsDarkMode;
    });
  };

  // For toggling API version
  const [user, setUser] = useState(null);

  const options = [
    {
      value: 1,
      label: "1",
    },
    {
      value: 2,
      label: "2",
    },
  ];

  useEffect(async () => {
    const userObj = await InterplaiDirectService.loadUser();
    setUser(userObj);
  }, []);

  // version select onchange event
  const handleVersionChange = async (value) => {
    setApiVersion(value);
    setGlobalLoader(true);
    await InterplaiDirectService.updateUserApiVersion(user.userId, value);
    localStorage.setItem("apiVersion", JSON.stringify(value));
    setGlobalLoader(false);
    window.location.reload(false);
  };

  const onSearchActive = () => {
    setSearchActive(true);
  };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === "dark" ? "#00000" : "#ffffff");
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <a href="/app/operations/dashboard">
          <Logo logoType={navMode} />
        </a>
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                </li>
              )}
              {isMobile ? (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onSearchActive();
                  }}
                >
                  <SearchOutlined />
                </li>
              ) : (
                <li className="ant-menu-item ant-menu-item-only-child" style={{ cursor: "auto" }}>
                  {/* <SearchInput mode={mode} isMobile={isMobile} /> */}
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right">
            {apiVersion == "2" && (
              <div className="mt-2 align-self-center mr-3">
                <Popover content="Toggle color mode">
                  <Switch onChange={onChangeTheme} checked={isDarkMode} checkedChildren="Light" unCheckedChildren="Dark" />
                </Popover>
              </div>
            )}
            <div className="mt-2 align-self-center ml-2">
              <Tag color="#0096FF">
                <strong>API Version {apiVersion}.0</strong>
              </Tag>
            </div>
            {/* <Select
              className="my-3 mr-3 ml-1"
              defaultValue={apiVersion}
              style={{
                width: 60,
              
              onChange={handleVersionChange}
              options={options}
            /> */}
            <NavDelegate />
            <NavProfile />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction };
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);
