import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button, Popover, Select, Typography, Modal, message, Alert } from "antd";
import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";
import moment from "moment";

import tickimg from "../../img/correct.png";

import { connect } from "react-redux";
import { updateTrips } from "redux/actions/Items";

import TripForm from "./TripForm";

import "./index.css";

const { Option } = Select;
const { Paragraph } = Typography;

const TripEntry = (props) => {
  const { reduxZones, areZonesUpdated, showTripModal, setShowTripModal } = props;
  const [form] = Form.useForm();

  const [selectedZone, setSelectedZone] = useState(null);
  const [waypointList, setWaypointList] = useState([]);
  const [advancedTripOptions, setAdvancedTripOptions] = useState(false);
  const [optimizationTimeWindow, setOptimizationTimeWindow] = useState(false);
  const [manifests, setManifests] = useState(false);
  const [excludedVehicles, setExcludedVehicles] = useState(false);
  const [services, setServices] = useState(false);
  const [mobileApp, setMobileApp] = useState(false);
  const [transportationProviderPay, setTransportationProvidedPay] = useState(false);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({
    state: false,
    message: {
      id: null,
      name: "",
      url: "",
    },
  });
  const [error, setError] = useState(null);

  const [zones, setZones] = useState([]);

  useEffect(() => {
    const fetchZones = async () => {
      const wm = await InterplaiDirectService.getWorldModels();
      setZones(wm);
    };
    fetchZones();
  }, []);

  useEffect(() => {
    if (areZonesUpdated) {
      setZones(reduxZones);
    }
  }, [areZonesUpdated, reduxZones]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4500);
    }
  }, [error]);

  const handleTripClose = () => {
    setSuccess({
      state: false,
      message: {
        id: null,
        name: null,
      },
    });
    setError(null);
    setShowTripModal(false);
  };

  const onTripSubmit = async (values) => {
    setLoading(true);
    try {
      if (waypointList.length < 2) {
        message.warning("At least two waypoints are required!");
        setLoading(false);
      } else if (waypointList.length > 2) {
        message.warning("You can have upto two waypoints!");
        setLoading(false);
      } else {
        let payload = {
          routingScenarioMode: values.routingScenarioMode,
        };

        if (values.userTripId) {
          payload = { ...payload, userTripId: values.userTripId };
        }

        if (values.maxStopsAllowed) {
          payload = { ...payload, maxStopsAllowed: Number(values.maxStopsAllowed) };
        }

        if (values.manuallyAssignedVehicleId) {
          payload = { ...payload, manuallyAssignedVehicleId: values.manuallyAssignedVehicleId };
        }

        if (optimizationTimeWindow) {
          const startDate = moment(values.startDate).format("YYYY-MM-DD");
          const startTime = moment(values.startTime).format("HH:mm:ss");
          const endDate = moment(values.endDate).format("YYYY-MM-DD");
          const endTime = moment(values.endTime).format("HH:mm:ss");
          payload = {
            ...payload,
            optimizationTimeWindow: {
              startTimestamp: startDate + "T" + startTime + "Z",
              endTimestamp: endDate + "T" + endTime + "Z",
            },
          };
        }

        let formattedWaypoints = values.waypoints.map((wp) => {
          let waypoint = {
            userWaypointId: wp.userwayPointId,
            action: wp.action,
            waypointSequence: Number(wp.waypointSequence),
            serviceTime: Number(wp.serviceTime),
            demand: Number(wp.demand),
            handlingInstructions: wp.handlingInstructions,
          };

          if (wp.newLocationChecked) {
            waypoint = {
              ...waypoint,
              location: {
                phoneNumber: wp.newLocation.phoneNumber,
                supportsPhoneCalls: wp.newLocation.supportsPhoneCalls,
                supportsTextMessages: wp.newLocation.supportsTextMessages,
                supportsChatMessages: wp.newLocation.supportsChatMessages,
                name: wp.newLocation.name,
                userLocationId: wp.newLocation.userLocationId,
              },
            };
            if (wp.newLocation.coordinatesChecked) {
              waypoint = {
                ...waypoint,
                location: {
                  ...waypoint.location,
                  coordinates: {
                    lat: parseFloat(wp.newLocation.lat),
                    lng: parseFloat(wp.newLocation.lng),
                  },
                },
              };
            } else {
              waypoint = {
                ...waypoint,
                location: {
                  ...waypoint.location,
                  address: wp.newLocation.address,
                },
              };
            }
          } else {
            waypoint = {
              ...waypoint,
              location: {
                id: wp.locationId,
              },
            };
          }

          if (wp.timeWindowChecked) {
            const startDate = moment(wp.startDate).format("YYYY-MM-DD");
            const startTime = moment(wp.startTime).format("HH:mm:ss");
            const endDate = moment(wp.endDate).format("YYYY-MM-DD");
            const endTime = moment(wp.endTime).format("HH:mm:ss");
            waypoint = {
              ...waypoint,
              timeWindow: {
                startTimestamp: startDate + "T" + startTime + "Z",
                endTimestamp: endDate + "T" + endTime + "Z",
              },
            };
          }

          if (wp.handlingSteps.length > 0) {
            const reformattedHandlingSteps = wp.handlingSteps.map((item) => {
              return {
                ...item,
                ...item.additionalData,
                additionalData: undefined,
              };
            });
            waypoint = {
              ...waypoint,
              handlingSteps: reformattedHandlingSteps,
            };
          }

          // fallbackHandlingSteps
          if (wp.action === "dropoff" && wp.fallbackHandlingSteps) {
            waypoint = {
              ...waypoint,
              fallbackHandlingSteps: [
                {
                  name: wp.fallbackHandlingSteps,
                },
              ],
            };
          }

          if (wp.manifestIds.length > 0) {
            waypoint = {
              ...waypoint,
              manifestIds: wp.manifestIds.map((item) => item.manifestId),
            };
          }

          return waypoint;
        });

        payload = { ...payload, waypoints: formattedWaypoints };

        if (advancedTripOptions) {
          if (manifests) {
            payload = {
              ...payload,
              manifests: values.manifests,
            };
          }

          if (excludedVehicles) {
            payload = {
              ...payload,
              excludedVehicles: values.excludedVehicles,
            };
          }

          if (services) {
            payload = {
              ...payload,
              services: values.services,
            };
          }

          if (transportationProviderPay) {
            payload = {
              ...payload,
              transportationProviderPay: {
                fee: Number(values.fee),
                currency: values.currency,
                customerTip: Number(values.customerTip),
              },
            };
          }

          if (mobileApp) {
            payload = {
              ...payload,
              mobileApp: {},
            };

            if (values.basePay || values.peakPay || values.customerTip) {
              payload.mobileApp.driverPay = {
                basePay: Number(values.basePay),
                peakPay: Number(values.peakPay),
                customerTip: Number(values.customerTip),
              };
            }

            if (
              values.verifyOrderItems ||
              values.scanOrderItems ||
              values.provideChangetoCustomer ||
              values.displayAcceptButton ||
              values.displayDeclineButton ||
              values.displayTimer ||
              values.displayTipText ||
              values.displayPartnerDispatchButton ||
              values.acceptanceTimeoutDuration
            ) {
              payload.mobileApp.settings = {
                verifyOrderItems: values.verifyOrderItems,
                scanOrderItems: values.scanOrderItems,
                provideChangetoCustomer: values.provideChangetoCustomer,
                displayAcceptButton: values.displayAcceptButton,
                displayDeclineButton: values.displayDeclineButton,
                displayTimer: values.displayTimer,
                displayTipText: values.displayTipText,
                displayPartnerDispatchButton: values.displayPartnerDispatchButton,
                acceptanceTimeoutDuration: Number(values.acceptanceTimeoutDuration),
              };
            }
          }
        }

        const res = await InterplaiDirectService.createTrips(values.zoneId, payload);
        const alltrips = await InterplaiDirectService.getAllTripsInAZone(values.zoneId);
        updateTrips(alltrips);
        setSuccess({
          ...success,
          state: true,
          message: {
            id: res.id,
            zoneId: res.zoneId,
            url: res?.trackingUrl ?? "",
          },
        });
        setLoading(false);
      }
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onTripSubmit(values);
      })
      .catch((err) => {
        setError("One or more required fields are missing");
      });
  };

  const onTripReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={showTripModal}
      onCancel={handleTripClose}
      title="Create a Trip (Advanced)"
      maskClosable={false}
      footer={
        success.state
          ? [
              <Button key="close" type="primary" onClick={handleTripClose} id="create-trip-close">
                Close
              </Button>,
            ]
          : [
              <Button key="clear" onClick={onTripReset} style={{ float: "left" }} id="create-trip-reset">
                <ReloadOutlined />
                Clear all
              </Button>,
              <Button key="submit" htmlType="submit" type="primary" loading={loading} onClick={onSubmit} id="create-trip-button">
                Create
              </Button>,

              <Button type="ghost" key="cancel" onClick={handleTripClose} id="create-trip-close">
                Cancel
              </Button>,
            ]
      }
      width={600}
    >
      {error && (
        <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
              <Alert message={error} showIcon type="error" closable />
            </Col>
          </Row>
        </div>
      )}
      <div style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "60vh" }}>
        {success.state ? (
          <Row gutter={16} justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="d-block mb-4">
                <img src={tickimg} className="mr-3" height="28px" width="28px" alt="success" />
                <strong>The trip is successfully created!</strong>
              </div>
              <div className="d-block">
                <strong>Trip ID: </strong>
                <Paragraph copyable>{success.message.id}</Paragraph>
              </div>
              <div className="d-block">
                <strong>Corresponding Zone ID: </strong>
                <Paragraph copyable>{success.message.zoneId}</Paragraph>
              </div>
              {success.message.url && (
                <div className="d-block">
                  <strong>Order Tracking Url: </strong>
                  <Paragraph copyable>{success.message.url}</Paragraph>
                </div>
              )}
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form form={form} layout="vertical" name="create-trip" preserve={false} className="m-3" id="create-trip-form">
                  <Form.Item
                    name="zoneId"
                    label="Zone"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[{ required: true, message: "Please select a zone." }]}
                  >
                    <div className="d-flex align-items-center mb-4">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="mr-3"
                        placeholder="Select a zone"
                        filterOption={(input, option) =>
                          option.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          form.setFieldsValue({
                            zoneId: value,
                          });
                          setSelectedZone(value);
                        }}
                      >
                        {zones.length > 0 &&
                          zones.map((worldmodel) => (
                            <Option key={worldmodel.id} value={worldmodel.id}>
                              <strong>{worldmodel.name}</strong> ({worldmodel.id})
                            </Option>
                          ))}
                      </Select>
                      <Popover content="The zone in which the vehicle is to be created. (string)" trigger="hover" placement="right">
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                  <TripForm
                    form={form}
                    selectedZone={selectedZone}
                    waypointList={waypointList}
                    setWaypointList={setWaypointList}
                    advancedTripOptions={advancedTripOptions}
                    setAdvancedTripOptions={setAdvancedTripOptions}
                    optimizationTimeWindow={optimizationTimeWindow}
                    setOptimizationTimeWindow={setOptimizationTimeWindow}
                    manifests={manifests}
                    setManifests={setManifests}
                    excludedVehicles={excludedVehicles}
                    setExcludedVehicles={setExcludedVehicles}
                    mobileApp={mobileApp}
                    setMobileApp={setMobileApp}
                    services={services}
                    setServices={setServices}
                    transportationProviderPay={transportationProviderPay}
                    setTransportationProvidedPay={setTransportationProvidedPay}
                  />
                </Form>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxZones: state.items.zones,
    areZonesUpdated: state.items.areZonesUpdated,
  };
};

const mapDispatchToProps = {
  updateTrips,
};

export default connect(mapStateToProps, mapDispatchToProps)(TripEntry);
