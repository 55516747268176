import { UPDATE_ZONES, UPDATE_LOCATIONS, UPDATE_TRIPS, UPDATE_VEHICLES, UPDATE_TIMEZONE, UPDATE_SERVICES } from "../constants/Items";

export const updateZones = (newZones) => ({
  type: UPDATE_ZONES,
  payload: newZones,
});

export const updateVehicles = (newVehicles) => ({
  type: UPDATE_VEHICLES,
  payload: newVehicles,
});

export const updateLocations = (newLocations) => ({
  type: UPDATE_LOCATIONS,
  payload: newLocations,
});

export const updateTrips = (newTrips) => ({
  type: UPDATE_TRIPS,
  payload: newTrips,
});

export const updateServices = (newServices) => ({
  type: UPDATE_SERVICES,
  payload: newServices,
});

export const updateTimezone = (newTz) => ({
  type: UPDATE_TIMEZONE,
  payload: newTz,
});
