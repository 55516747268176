import React, { useState } from "react";
import { Form, Button, Select, Input, InputNumber, Switch } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const Manifests = ({ form }) => {
  const [itemList, setItemList] = useState([
    {
      dimensions: {
        checked: false,
      },
    },
  ]);

  const appendItemArray = () => {
    setItemList((prevState) => [
      ...prevState,
      {
        dimensions: {
          checked: false,
        },
      },
    ]);
  };

  const trimItemArray = (index) => {
    setItemList((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  const handleItem = (index) => {
    setItemList((prevState) => {
      const newState = [...prevState];
      newState[index].dimensions.checked = !newState[index].dimensions.checked;
      return newState;
    });
  };

  return (
    <div className="mb-4 mt-2 p-3 border rounded">
      <Form.List name="manifests" className="my-3" initialValue={[]}>
        {(manifests, { add, remove }) => (
          <>
            {manifests.map((manifest, index) => (
              <Form.Item key={manifest.key} label={`Manifest ${index + 1}`} style={{ border: "1px solid #f0f0f0", padding: "16px" }}>
                <Form.Item name={[manifest.name, "id"]} label="ID" rules={[{ required: true, message: "ID is required." }]}>
                  <Input placeholder="Enter ID" className="mr-3" />
                </Form.Item>
                <Form.Item name={[manifest.name, "userManifestId"]} label="User Manifest ID" rules={[{ required: false }]}>
                  <Input placeholder="Enter Manifest ID" className="mr-3" />
                </Form.Item>

                <a className="text-dark mt-4 mb-3">
                  ITEMS <small>(Minimum one item required)</small>
                </a>

                <Form.List
                  name={[manifest.name, "items"]}
                  className="my-3"
                  initialValue={[
                    {
                      userManifestItemId: "",
                      name: "",
                      description: "",
                      quantity: 1,
                      weight: 0,
                      price: 0,
                      dimensions: {
                        height: 0,
                        length: 0,
                        width: 0,
                      },
                    },
                  ]}
                >
                  {(items, { add, remove }) => (
                    <>
                      {items.map((item, index) => (
                        <Form.Item key={item.key} label={`Item ${index + 1}`} style={{ border: "1px solid #f0f0f0", padding: "16px" }}>
                          <Form.Item
                            className="mt-3"
                            name={[item.name, "userManifestItemId"]}
                            label="User Manifest Item ID"
                            rules={[{ required: false }]}
                          >
                            <Input placeholder="Enter manifest item ID" className="mr-3" />
                          </Form.Item>
                          <Form.Item
                            name={[item.name, "name"]}
                            label="Name"
                            rules={[{ required: true, message: "Item name is required." }]}
                          >
                            <Input placeholder="Enter name of the item" className="mr-3" />
                          </Form.Item>
                          <Form.Item name={[item.name, "description"]} label="Description" rules={[{ required: false }]}>
                            <Input.TextArea placeholder="Enter description" className="mr-3" rows={3} />
                          </Form.Item>
                          <Form.Item
                            name={[item.name, "quantity"]}
                            label="Quantity"
                            rules={[{ required: true, message: "Quantity is required." }]}
                          >
                            <InputNumber className="mr-3 w-100" />
                          </Form.Item>
                          <Form.Item name={[item.name, "price"]} label="Price" rules={[{ required: false }]}>
                            <InputNumber className="mr-3 w-100" placeholder="Enter price of the item" />
                          </Form.Item>
                          <Form.Item name={[item.name, "weight"]} label="Weight" rules={[{ required: false }]}>
                            <InputNumber className="mr-3 w-100" placeholder="Enter weight of the item" />
                          </Form.Item>
                          <Form.Item name={[item.name, "dimensionChecked"]} valuePropName="checked" initialValue={false}>
                            <Switch
                              onChange={() => handleItem(index)}
                              defaultChecked={false}
                              checkedChildren="Hide Dimensions"
                              unCheckedChildren="Dimensions"
                            />
                          </Form.Item>
                          {itemList[index].dimensions.checked && (
                            <div className="p-3 border rounded mb-3 mt-2">
                              <Form.Item name={[item.name, "dimensions", "height"]} label="Height" rules={[{ required: false }]}>
                                <InputNumber className="mr-3 w-100" />
                              </Form.Item>
                              <Form.Item name={[item.name, "dimensions", "length"]} label="Length" rules={[{ required: false }]}>
                                <InputNumber className="mr-3 w-100" />
                              </Form.Item>
                              <Form.Item name={[item.name, "dimensions", "width"]} label="Width" rules={[{ required: false }]}>
                                <InputNumber className="mr-3 w-100" />
                              </Form.Item>
                            </div>
                          )}

                          <Form.Item name={[item.name, "mustBeUpright"]} label="Must Be Upright" rules={[{ required: false }]}>
                            <Switch className="mr-3" />
                          </Form.Item>

                          {items.length > 1 ? (
                            <Button
                              className="mt-2"
                              type="link"
                              onClick={() => {
                                remove(item.name);
                                trimItemArray(index);
                              }}
                              style={{ marginLeft: 8, marginTop: 1 }}
                            >
                              <MinusCircleOutlined /> Remove Item
                            </Button>
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                            appendItemArray();
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add Item
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <Form.Item name={[manifest.name, "totalCost"]} label="Total Cost" rules={[{ required: false }]}>
                  <InputNumber placeholder="Enter total tost" className="mr-3 w-100" />
                </Form.Item>
                <Form.Item name={[manifest.name, "expectedChange"]} label="Expected Change" rules={[{ required: false }]}>
                  <InputNumber placeholder="Enter expected change" className="mr-3 w-100" />
                </Form.Item>
                <Form.Item name={[manifest.name, "paymentType"]} label="Payment Type" rules={[{ required: false }]}>
                  <Select placeholder="Select payment type">
                    <Option value="cash">Cash</Option>
                    <Option value="prepaid">Prepaid</Option>
                  </Select>
                </Form.Item>
                <Form.Item name={[manifest.name, "transactionType"]} label="Transaction Type" rules={[{ required: false }]}>
                  <Select placeholder="Select transaction type">
                    <Option value="grocery">Grocery</Option>
                    <Option value="restaurant">Restaurant</Option>
                    <Option value="errand">Errand</Option>
                  </Select>
                </Form.Item>
                <Form.Item name={[manifest.name, "currency"]} label="Currency" rules={[{ required: false }]}>
                  <Select placeholder="Select currency">
                    <Option value="usd">United States Dollar (USD)</Option>
                    <Option value="veb">Venezuelan Bolivar (VEB)</Option>
                    <Option value="svc">Salvadoran Colón (SVC)</Option>
                    <Option value="hnl">Honduran Lempira (HNL)</Option>
                    <Option value="nio">Nicaraguan Córdoba (NIO)</Option>
                    <Option value="jmd">Jamaican Dollar (JMD)</Option>
                    <Option value="gtq">Guatemalan Quetzal (GTQ)</Option>
                  </Select>
                </Form.Item>

                <Button className="mb-2" type="link" onClick={() => remove(manifest.name)} style={{ marginLeft: 8 }}>
                  <MinusCircleOutlined /> Remove Manifest
                </Button>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                }}
                icon={<PlusOutlined />}
              >
                Add Manifest
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default Manifests;
