import FirebaseService from "./FirebaseService";
import http from "../auth/http";

const InterplaiDirectService = {};
const moment = require("moment");

InterplaiDirectService.getApiUrl = function (ver) {
  let api_url = "";

  if (ver == "1") {
    if (process.env.REACT_APP_CROSS_NODE_ENV === "production") {
      api_url = "https://api.interplai.io/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "staging") {
      api_url = "https://api.staging.direct.interplai.io/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development") {
      api_url = "https://i-direct-api-njsi5yolsq-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development2") {
      api_url = "https://i-direct-api-l3iwalfzpq-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development3") {
      api_url = "https://i-direct-api-gl6pw4ruka-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development4") {
      api_url = "https://i-direct-api-o7vxc2jftq-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development5") {
      api_url = "https://i-direct-api-ixrpcy5hba-uk.a.run.app/v1";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "prod-dev") {
      api_url = "https://dev.api.interplai.io/v1";
    } else {
      api_url = "http://172.19.0.2:2000/v1";
    }
  } else {
    if (process.env.REACT_APP_CROSS_NODE_ENV === "production") {
      api_url = "https://api.interplai.io/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "staging") {
      api_url = "https://api.staging.direct.interplai.io/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development") {
      api_url = "https://i-direct-api-njsi5yolsq-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development2") {
      api_url = "https://i-direct-api-l3iwalfzpq-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development3") {
      api_url = "https://i-direct-api-gl6pw4ruka-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development4") {
      api_url = "https://i-direct-api-o7vxc2jftq-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development5") {
      api_url = "https://i-direct-api-ixrpcy5hba-uk.a.run.app/v2";
    } else if (process.env.REACT_APP_CROSS_NODE_ENV === "prod-dev") {
      api_url = "https://dev.api.interplai.io/v2";
    } else {
      api_url = "http://172.19.0.2:2000/v2";
    }
  }

  return api_url;
};

InterplaiDirectService.frontendUrl = function () {
  let url = "";
  if (process.env.REACT_APP_CROSS_NODE_ENV === "production") {
    url = "https://console.interplai.io";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "staging") {
    url = "https://console.staging.interplai.io";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development") {
    url = "https://i-direct-client-admin-njsi5yolsq-uk.a.run.app";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development2") {
    url = "https://i-direct-client-admin-l3iwalfzpq-uk.a.run.app";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development3") {
    url = "https://i-direct-client-admin-gl6pw4ruka-uk.a.run.app";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development4") {
    url = "https://i-direct-client-admin-o7vxc2jftq-uk.a.run.app";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development5") {
    url = "https://i-direct-client-admin-ixrpcy5hba-uk.a.run.app";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "prod-dev") {
    url = "https://dev.console.interplai.io";
  } else {
    url = "https://172.19.0.11:3000";
  }
  return url;
};

InterplaiDirectService.clientConsoleLink = function () {
  let url = "";
  if (process.env.REACT_APP_CROSS_NODE_ENV === "production") {
    url = "https://dev.console.interplai.io";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "staging") {
    url = "https://console.interplai.io";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development") {
    url = "https://console.interplai.io";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development2") {
    url = "https://console.interplai.io";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development3") {
    url = "https://console.interplai.io";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development4") {
    url = "https://console.interplai.io";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "development5") {
    url = "https://console.interplai.io";
  } else if (process.env.REACT_APP_CROSS_NODE_ENV === "prod-dev") {
    url = "https://console.interplai.io";
  } else {
    url = "https://console.interplai.io";
  }
  return url;
};

InterplaiDirectService.hasAbility = function (permission, type) {
  const role = localStorage.getItem("role");
  const abilities = JSON.parse(localStorage.getItem("abilities"));

  if (type === "action") {
    const rolePermission = abilities.find((obj) => obj.name === permission);

    if (!rolePermission) {
      return true;
    }

    if (rolePermission) {
      if (rolePermission.roles.includes(role)) {
        return true;
      } else {
        return false;
      }
    }
  } else if (type === "group") {
    const rolePermission = abilities.find((obj) => obj.group === permission);

    // if permission not defined return true
    if (!rolePermission) {
      return true;
    }

    if (rolePermission) {
      // check if any view permissions available in that group for the current role
      const permissions = abilities.filter((obj) => obj.group === permission && obj.name.startsWith("view") && obj.roles.includes(role));
      // if so allow else reject
      if (permissions.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
};

InterplaiDirectService.getApiHeaders = async () => {
  let accountId = localStorage.getItem("accountId");

  var currentTime = moment().local();
  var expiryTime = moment(localStorage.getItem("expires_at")).local();

  const differenceDuration = moment.duration(expiryTime.diff(currentTime));
  const remainingMinutes = differenceDuration.minutes();

  /* If minutes less than 3 refresh the token */
  if (remainingMinutes <= 3) {
    await FirebaseService.renewToken();
  }

  const headers = {
    xAPIKey: accountId + ".NKK8QT7-44A40D6-HKCNVHQ-FEMNMHQ",
    encodedAuthFbToken: localStorage.getItem("encoded_auth_fb_token"),
    userId: localStorage.getItem("userId"),
    accountId: localStorage.getItem("accountId"),
    delegateAccountId: null,
  };

  if (localStorage.getItem("role") === "superAdmin") {
    headers.delegateAccountId = localStorage.getItem("delegateAccountId") ? localStorage.getItem("delegateAccountId") : null;
  }

  return headers;
};

// Auth
InterplaiDirectService.requestForgotPassword = async (email) => {
  let response = await http.post(`/auth/forgot-password`, { email: email });
  return response.data;
};

InterplaiDirectService.resetForgotPassword = async (password, token) => {
  let response = await http.post(`/auth/reset-password`, {
    password: password,
    token: token,
  });
  return response.data;
};

// User
InterplaiDirectService.getUserByFirebaseId = async function (userId) {
  let response = await http.get(`admin/users/firebase/${userId}`);

  await localStorage.setItem("userId", response.data.id);
  await localStorage.setItem("email", response.data.email);
  await localStorage.setItem("status", response.data.status);
  await localStorage.setItem("role", response.data.role);
  await localStorage.setItem("fullName", response.data.fullName);
  await localStorage.setItem("accountId", response.data.accountId);
  await localStorage.setItem("companyName", response.data.companyName);
  await localStorage.setItem("abilities", JSON.stringify(response.data.abilities));
  await localStorage.setItem("apiVersion", response.data.apiVersion);
  await localStorage.setItem("userCreatedTimestamp", response.data.createdTimestamp);

  return await InterplaiDirectService.loadUser();
};

InterplaiDirectService.getUserByEmail = async function (emailId) {
  let response = await http.get(`admin/users/by-email/${emailId}`);
  return response.data;
};

InterplaiDirectService.loadUser = async () => {
  const accountId = await localStorage.getItem("accountId");

  /* If there is no user account return null else build user object from local storage and return */

  if (accountId) {
    let fullName = localStorage.getItem("fullName");
    let fullNameTokens = fullName.toUpperCase().split(" ");
    let nameInitials = fullNameTokens[0].charAt(0);

    if (fullNameTokens.length > 1) {
      nameInitials = nameInitials + fullNameTokens[fullNameTokens.length - 1].charAt(0);
    }

    /* Build user object */

    let user = {
      status: localStorage.getItem("status"),
      role: localStorage.getItem("role"),
      userId: localStorage.getItem("userId"),
      email: localStorage.getItem("email"),
      isOnline: localStorage.getItem("isOnline"),
      fullName: localStorage.getItem("fullName"),
      nameInitials: nameInitials,
      accountId: localStorage.getItem("accountId"),
      companyName: localStorage.getItem("companyName"),
      apiVersion: localStorage.getItem("apiVersion"),
      createdTimestamp: localStorage.getItem("userCreatedTimestamp"),
    };

    return user;
  }

  return null;
};

InterplaiDirectService.createAccount = async function (company_name, email, full_name, firebaseUserId) {
  let newUserAccount = {
    accountType: "free",
    approvedIpAddresses: "Any",
    companyName: company_name,
    email: email,
    fullName: full_name,
    firebaseUserId: firebaseUserId,
  };

  let response = await http.post(`admin/accounts/signup`, newUserAccount);

  // account based
  localStorage.setItem("accountId", response.data.account.id);
  localStorage.setItem("companyName", response.data.account.companyName);

  // user based
  localStorage.setItem("userId", response.data.user.id);
  localStorage.setItem("status", response.data.user.status);
  localStorage.setItem("role", response.data.user.role);
  localStorage.setItem("email", response.data.user.email);
  localStorage.setItem("fullName", response.data.user.fullName);
  localStorage.setItem("abilities", JSON.stringify(response.data.user.abilities));
  localStorage.setItem("isOnline", true);
  localStorage.setItem("apiVersion", "2");

  return await InterplaiDirectService.loadUser();
};

InterplaiDirectService.getAccount = async function (accountId) {
  let response = await http.get(`admin/accounts/${accountId}`);
  return response.data;
};

InterplaiDirectService.getAccounts = async function () {
  let response = await http.get(`admin/accounts`);

  return response.data;
};

// Update API version
InterplaiDirectService.updateUserApiVersion = async function (id, version) {
  await localStorage.setItem("apiVersion", JSON.stringify(version));
  const response = await http.patch(`admin/users/api-version/${id}`, {
    version: version,
  });
  return response.data;
};

InterplaiDirectService.getRoles = async function () {
  let response = await http.get(`admin/accounts/roles`);

  return response.data;
};

InterplaiDirectService.validIpAddresses = function (ipAdresses) {
  let _ipAddresses = ipAdresses.split(",");
  let response = { validateStatus: "success", errorMsg: null };
  if (ipAdresses !== "Any") {
    let ipformat =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    _ipAddresses.forEach(function (ipAddress) {
      if (!ipAddress.match(ipformat)) {
        response = {
          validateStatus: "error",
          errorMsg: "IP addresses are not in valid format.",
        };
      }
    });
  }
  return response;
};

InterplaiDirectService.getTimestampSeconds = function (timestamp) {
  timestamp.forEach(function (element) {
    element.createdTimestampSeconds = element.createdTimestamp._seconds;
    let createdDate = new Date(element.createdTimestamp._seconds * 1000).toLocaleDateString("en-US");
    element.createdDate = createdDate;
    element.lastUpdatedTimestampSeconds = element.lastUpdatedTimestamp._seconds;
  });
  return timestamp;
};

// Dashboard and Operations Table
InterplaiDirectService.getDashboardOverview = async function () {
  const response = await http.get(`admin/dashboard`);
  return response.data;
};

InterplaiDirectService.getAllRoutesInAZone = async function (zoneId) {
  const response = await http.get(`admin/zones/${zoneId}/routes?limit=250`);
  return response.data;
};

InterplaiDirectService.getAllRoutesInAZoneWithDetailedWaypoints = async function (zoneId) {
  const response = await http.get(`admin/zones/${zoneId}/detailed-routes?limit=250&verbose=${true}`);
  return response.data;
};

InterplaiDirectService.acceptOrRejectRoutes = async function (zoneId, optimizationSolutionId, payload) {
  try {
    const response = await http.post(`admin/zones/${zoneId}/optimizationsolutions/${optimizationSolutionId}/routes/action`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while performing route action!");
    }
  }
};

InterplaiDirectService.getAllWaypoints = async function (zoneId, tripId) {
  const response = await http.get(`admin/zones/${zoneId}/trips/${tripId}/waypoints`);
  return response.data;
};

InterplaiDirectService.getWaypointById = async function (zoneId, tripId, waypointId) {
  const response = await http.get(`admin/zones/${zoneId}/trips/${tripId}/waypoints/${waypointId}`);
  return response.data;
};

InterplaiDirectService.updateWaypointAction = async function (zoneId, tripId, payload) {
  const response = await http.post(`admin/zones/${zoneId}/trips/${tripId}/waypoints/action`, payload);
  return response.data;
};

InterplaiDirectService.getAllTripsInAZone = async function (zoneId) {
  const response = await http.get(`admin/zones/${zoneId}/trips?limit=250&verbose=true`);
  return response.data;
};

InterplaiDirectService.getDashboardOverviewV2 = async function () {
  const response = await http.get(`admin/utilization`);
  return response.data;
};

// Billings and Stripe
InterplaiDirectService.getBillingReports = async function (mode, startDate, endDate) {
  const start = moment(startDate).format("YYYY-MM-DD");
  const end = moment(endDate).format("YYYY-MM-DD");

  let response = await http.get(`admin/billings/reports?groupby=${mode.toLowerCase()}&startdate=${start}&enddate=${end}`);

  return response.data;
};

InterplaiDirectService.getPaymentSettings = async function () {
  let response = await http.get(`admin/billings/payment-settings`);
  return response.data;
};

InterplaiDirectService.addBillingEmail = async function (email) {
  let response = await http.post(`admin/billings/add-billing-email`, {
    email: email,
  });

  return response.data;
};

InterplaiDirectService.updateBillingEmail = async function (email) {
  let response = await http.post(`admin/billings/update-billing-email`, {
    email: email,
  });

  return response.data;
};

InterplaiDirectService.getInvoices = async function (page) {
  let response = await http.get(`admin/billings/invoices?page=${page}`);

  return response.data;
};

InterplaiDirectService.getStripePublishableKey = function () {
  let publishableKey = "";
  if (process.env.REACT_APP_CROSS_NODE_ENV === "production") {
    publishableKey = "pk_live_51HUfK5EK2fstQckS84z1ku2vCLBmpsAv9Icd9LujF6XRmpTtvZN96Y9jUeszpJ1cWMSmBcEaZm8GB3XaagDH0RQa00Ny0gS215";
  } else {
    publishableKey = "pk_test_51HUfK5EK2fstQckSgWBvmkYahXlHPkNdyKkahf0mew0rFIch4lbv5wsUHeO5FVQdmbbBtQHGzCnRP8AXe1cZR8g600vVzTV2k6";
  }
  return publishableKey;
};

InterplaiDirectService.setupIntent = async function () {
  let response = await http.get(`admin/billings/setup-intent`);
  return response.data;
};

InterplaiDirectService.listPaymentMethods = async function () {
  let response = await http.get(`admin/billings/payment-methods`);
  return response.data;
};

InterplaiDirectService.listSubscriptions = async function () {
  let response = await http.get(`admin/billings/subscriptions-list`);
  return response.data;
};

// InterplaiDirectService.updatePaymentMethod = async function () {
//   let response = await http.get(`admin/billings/update-payment`);
//   return response.data;
// };

InterplaiDirectService.createStripeSubscription = async function (payload) {
  try {
    let response = await http.post(`admin/billings/create-subscription`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while creating the subscription!");
    }
  }
};

InterplaiDirectService.updateStripeSubscription = async function (payload) {
  try {
    let response = await http.post(`admin/billings/update-subscription`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the subscription!");
    }
  }
};

InterplaiDirectService.cancelStripeSubscription = async function () {
  try {
    let response = await http.post(`admin/billings/cancel-subscription`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while cancelling the subscription!");
    }
  }
};

InterplaiDirectService.retrieveUpcomingInvoice = async function () {
  let response = await http.get(`admin/billings/upcoming-invoice`);
  return response.data;
};

InterplaiDirectService.defaultCard = async function (payload) {
  let response = await http.post(`admin/billings/default-card`, payload);
  return response.data;
};

InterplaiDirectService.detachCard = async function (card) {
  let response = await http.post(`admin/billings/detach-card/${card}`);
  return response.data;
};

InterplaiDirectService.licensedCheckoutSession = async function (payload) {
  let response = await http.post(`admin/billings/licensed-checkout`, payload);
  return response.data;
};

InterplaiDirectService.meteredCheckoutSession = async function (payload) {
  let response = await http.post(`admin/billings/metered-checkout`, payload);
  return response.data;
};
InterplaiDirectService.interestedInEnterprise = async function (payload) {
  let response = await http.post(`admin/billings/interested-in-enterprise`, payload);
  return response.data;
};

// API
InterplaiDirectService.getApiReports = async function (mode, startDate, endDate) {
  const start = moment(startDate).format("YYYY-MM-DD");
  const end = moment(endDate).format("YYYY-MM-DD");

  let response = await http.get(`admin/api/reports?groupby=${mode.toLowerCase()}&startdate=${start}&enddate=${end}`);

  return response.data;
};

InterplaiDirectService.getApiKeys = async function () {
  let response = await http.get(`admin/api/keys`);

  return response.data;
};

InterplaiDirectService.createApiKey = async function (newApiKey) {
  let response = await http.post(`admin/api/keys`, newApiKey);

  return response.data;
};

InterplaiDirectService.patchApiKeyById = async function (id, patchApiKey) {
  let response = await http.patch(`admin/api/keys/${id}`, patchApiKey);

  return response.data;
};

InterplaiDirectService.deleteApiKeyById = async function (apiKeyId) {
  let response = await http.delete(`admin/api/keys/${apiKeyId}`);

  return response.data;
};

// Account Users
InterplaiDirectService.getAllUsers = async function (roles) {
  const response = await http.get(`admin/client-users?roles=${roles}&limit=250`);
  return response.data;
};

InterplaiDirectService.getAllVehicles = async (zoneId) => {
  const response = await http.get(`admin/zones/${zoneId}/vehicles?limit=250`);

  return response.data;
};

InterplaiDirectService.getVehicleById = async (zoneId, vehicleId) => {
  const response = await http.get(`admin/zones/${zoneId}/vehicles/${vehicleId}`);

  return response.data;
};

InterplaiDirectService.addUser = async function (user) {
  const accountId = localStorage.getItem("accountId");

  try {
    let response = await http.post(`admin/users?newuserstatus=${true}`, {
      ...user,
      accountId: accountId,
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while creating the user.");
    }
  }
};

InterplaiDirectService.updateUser = async function (id, user) {
  try {
    let response = await http.patch(`admin/users/${id}`, user);

    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the user.");
    }
  }
};

InterplaiDirectService.getProfilePhoto = async (userId) => {
  try {
    let response = await http.get(`admin/users/${userId}/profilephoto`);

    return response;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while fetching image.");
    }
  }
};

// Logged in state of the user
InterplaiDirectService.updateUserLoginStatus = async function (id) {
  try {
    let response = await http.patch(`admin/users/online-status/${id}`);

    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while patching the current timestamp of the user.");
    }
  }
};

InterplaiDirectService.deleteuser = async function (id) {
  try {
    let response = await http.delete(`admin/users/${id}`);

    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while toggling the state of the user.");
    }
  }
};

// Visualization
InterplaiDirectService.getWorldModels = async function () {
  let response = await http.get(`admin/${localStorage.getItem("apiVersion") == "1" ? "worldmodels" : "zones"}/all?limit=250`);
  return response.data;
};

InterplaiDirectService.getVroSolutions = async function (worldModelId, startDate, endDate) {
  let response = await http.get(`admin/vrosolution/${worldModelId}?startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};

InterplaiDirectService.getRoutes = async function (worldModelId, startDate, endDate) {
  let response = await http.get(`admin/route/${worldModelId}?startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};

InterplaiDirectService.getRequests = async function (worldModelId, startDate, endDate) {
  let response = await http.get(`admin/request/${worldModelId}?startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};

InterplaiDirectService.getUuIds = async function (worldModelId, startDate, endDate) {
  let response = await http.get(`admin/visualization/${worldModelId}/gen2-data-uuids?startDate=${startDate}&endDate=${endDate}`);
  return response.data;
};

InterplaiDirectService.getVisualizationDataByVroSolution = async function (worldModelId, vroSolutionId, queryParams) {
  const response = await http.get(`admin/visualization/${worldModelId}/map-data/vrosolutions/${vroSolutionId}${queryParams}`);
  return response.data;
};

InterplaiDirectService.getVisualizationDataByRoutes = async function (worldModelId, routeId, queryParams) {
  const response = await http.get(`admin/visualization/${worldModelId}/map-data/routes/${routeId}${queryParams}`);
  return response.data;
};

InterplaiDirectService.getVisualizationDataByUuId = async function (worldModelId, uuid, queryParams) {
  const response = await http.get(`admin/visualization/${worldModelId}/map-data/vehicles/${uuid}${queryParams}`);
  return response.data;
};

InterplaiDirectService.getVisualizationDataByRequests = async function (worldModelId, requestId, queryParams) {
  const response = await http.get(`admin/visualization/${worldModelId}/map-data/requests/${requestId}${queryParams}`);
  return response.data;
};

InterplaiDirectService.acceptInvite = async function () {
  const response = await http.patch(`admin/invite/accept`);
  localStorage.setItem("status", response.data.status);
  return response.data;
};

InterplaiDirectService.rejectInvite = async function () {
  const response = await http.patch(`admin/invite/reject`);
  InterplaiDirectService.clearLocalStorage();
  return response.data;
};

InterplaiDirectService.clearLocalStorage = async function () {
  localStorage.clear();
};

// Waypoint attachment
InterplaiDirectService.getAllAttachmentsInAWaypoint = async function (zoneId, tripId, waypointId) {
  const response = await http.get(`admin/zones/${zoneId}/trips/${tripId}/waypoints/${waypointId}/attachments`);
  return response.data;
};

InterplaiDirectService.fetchAttachmentById = async function (zoneId, tripId, waypointId, attachmentType, attachmentId) {
  const response = await http.get(
    `app/zones/${zoneId}/trips/${tripId}/waypoints/${waypointId}/${attachmentType}/attachments/${attachmentId}`
  );
  return response.data;
};

// Data Transfer - CSV and Data Entry & Tables
InterplaiDirectService.createZones = async function (payload) {
  try {
    let response = await http.post(`admin/zones`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while creating the new zone(s)");
    }
  }
};

InterplaiDirectService.updateZones = async function (payload) {
  try {
    let response = await http.patch(`admin/zones`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the zone(s)");
    }
  }
};

InterplaiDirectService.updateZoneById = async function (zoneId, payload) {
  try {
    let response = await http.patch(`admin/zones/${zoneId}`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the zone");
    }
  }
};

InterplaiDirectService.performZoneAction = async function (zoneId) {
  try {
    let response = await http.post(`admin/zones/${zoneId}/action`, {
      action: "startOptimization",
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while performing zone action.");
    }
  }
};

InterplaiDirectService.deleteZones = async function (payload) {
  try {
    let response = await http.delete(`admin/zones/`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while deleting the zones");
    }
  }
};

InterplaiDirectService.deleteZoneById = async function (zoneId) {
  try {
    let response = await http.delete(`admin/zones/${zoneId}`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while deleting the zone");
    }
  }
};

InterplaiDirectService.createVehicles = async function (zoneId, payload) {
  try {
    let response = await http.post(`admin/zones/${zoneId}/vehicles`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while creating the new vehicle(s)");
    }
  }
};

InterplaiDirectService.updateVehicles = async function (zoneId, payload) {
  try {
    let response = await http.patch(`admin/zones/${zoneId}/vehicles`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the vehicle(s)");
    }
  }
};

InterplaiDirectService.updateVehicleById = async function (zoneId, vehicleId, payload) {
  try {
    let response = await http.patch(`admin/zones/${zoneId}/vehicles/${vehicleId}`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the vehicle");
    }
  }
};

InterplaiDirectService.deleteVehicleById = async function (zoneId, vehicleId) {
  try {
    let response = await http.delete(`admin/zones/${zoneId}/vehicles/${vehicleId}`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while deleting the vehicle");
    }
  }
};

InterplaiDirectService.getAllLocations = async (zoneId) => {
  const response = await http.get(`admin/zones/${zoneId}/locations?limit=250`);

  return response.data;
};

InterplaiDirectService.createLocations = async function (zoneId, payload) {
  try {
    let response = await http.post(`admin/zones/${zoneId}/locations`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while creating the new location(s)");
    }
  }
};

InterplaiDirectService.updateLocations = async function (zoneId, payload) {
  try {
    let response = await http.patch(`admin/zones/${zoneId}/locations`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the location(s)");
    }
  }
};

InterplaiDirectService.updateLocationById = async function (zoneId, locationId, payload) {
  try {
    let response = await http.patch(`admin/zones/${zoneId}/locations/${locationId}`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the location");
    }
  }
};

InterplaiDirectService.deleteLocationById = async function (zoneId, locationId) {
  try {
    let response = await http.delete(`admin/zones/${zoneId}/locations/${locationId}`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while deleting the location");
    }
  }
};

InterplaiDirectService.createTrips = async function (zoneId, payload) {
  try {
    let response = await http.post(`admin/zones/${zoneId}/trips`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while creating the new trip(s)");
    }
  }
};

InterplaiDirectService.updateTrips = async function (zoneId, payload) {
  try {
    let response = await http.patch(`admin/zones/${zoneId}/trips`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the trip(s)");
    }
  }
};

InterplaiDirectService.updateTripById = async function (zoneId, tripId, payload) {
  try {
    let response = await http.patch(`admin/zones/${zoneId}/trips/${tripId}`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the trip");
    }
  }
};

InterplaiDirectService.updateTripAction = async function (zoneId, payload) {
  try {
    let response = await http.post(`admin/zones/${zoneId}/trips/action`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the trip action!");
    }
  }
};

InterplaiDirectService.getAllServices = async function (zoneId) {
  try {
    let response = await http.get(`admin/zones/${zoneId}/services`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while getting the services");
    }
  }
};

InterplaiDirectService.createServices = async function (zoneId, payload) {
  try {
    let response = await http.post(`admin/zones/${zoneId}/services`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while creating the new service(s)");
    }
  }
};

InterplaiDirectService.patchServiceById = async function (zoneId, id, payload) {
  try {
    let response = await http.patch(`admin/zones/${zoneId}/services/${id}`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the service");
    }
  }
};

InterplaiDirectService.deleteServiceById = async function (zoneId, id) {
  try {
    let response = await http.delete(`admin/zones/${zoneId}/services/${id}`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while deleting the service");
    }
  }
};

InterplaiDirectService.calculateCostMatrix = async function (payload) {
  try {
    let response = await http.post(`admin/costmatrices`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while calculating the cost matrix");
    }
  }
};

InterplaiDirectService.getAllRFQInAZone = async function (zoneId) {
  let response = await http.get(`admin/zones/${zoneId}/detailed-requestforquotes?limit=250`);
  return response.data;
};

InterplaiDirectService.getAllQuotesInAZoneByRfqID = async function (zoneId, rfqId) {
  let response = await http.get(`admin/zones/${zoneId}/requestforquotes/${rfqId}/quotes`);
  return response.data;
};

InterplaiDirectService.getAllQuotesInAZone = async function (zoneId) {
  let response = await http.get(`admin/zones/${zoneId}/quotes`);
  return response.data;
};

InterplaiDirectService.createQuotes = async function (zoneId, rfqId, payload) {
  try {
    let response = await http.post(`admin/zones/${zoneId}/requestforquotes/${rfqId}/quotes`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while creating the quote(s)");
    }
  }
};

InterplaiDirectService.quoteAction = async function (zoneId, rfqId, quoteId, action) {
  try {
    let response = await http.post(`admin/zones/${zoneId}/requestforquotes/${rfqId}/quotes/action`, {
      quoteId: quoteId,
      action: action,
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      // Fallback to generic error message
      throw new Error(err.message || "Something went wrong while performing this quote action!");
    }
  }
};

InterplaiDirectService.rfqAction = async function (zoneId, payload) {
  try {
    let response = await http.post(`admin/zones/${zoneId}/requestforquotes/action`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while performing this RFQ action!");
    }
  }
};

//----------------------------data transfer -------------------------//

InterplaiDirectService.getPreferences = async function () {
  let response = await http.get(`admin/preferences`);
  return response.data;
};

InterplaiDirectService.getAppOptions = async function () {
  let response = await http.get(`admin/preferences/app-options`);
  const arrayOfOptions = Object.values(response.data); // The response is an object instead of an array
  return arrayOfOptions;
};

InterplaiDirectService.updatePreferences = async function (payload) {
  try {
    let response = await http.patch(`admin/preferences`, payload);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating account preferences!");
    }
  }
};

//------------------- Chat messages --------------------------------//

InterplaiDirectService.getAllMessages = async function () {
  let response = await http.get(`admin/messages`);
  return response.data;
};

InterplaiDirectService.createNewMessage = async function (driverId, dispatcherId, payload) {
  let response = await http.post(`admin/messages?driveruserid=${driverId}&dispatcheruserid=${dispatcherId}`, payload);
  return response.data;
};

InterplaiDirectService.readMessage = async function (messageId, driverId, dispatcherId) {
  let response = await http.post(`admin/messages/${messageId}/readmessage?driveruserid=${driverId}&dispatcheruserid=${dispatcherId}`);
  return response.data;
};

InterplaiDirectService.getDriverDeviceStatus = async function (id) {
  let response = await http.get(`admin/mobiledevices?userid=${id}`);
  return response.data;
};

//----------------------------------Map--------------------------------------//
InterplaiDirectService.getPolylineCoordinates = async function (arr) {
  const formattedCoordinates = arr.map(({ lat, lng }) => `${lng},${lat}`).join(";");

  try {
    let response = await http.post(`app/osrm`, {
      coordinates: formattedCoordinates,
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while fetching the osrm coordinates");
    }
  }
};
//----------------------------------Map----------------------------------------//

//-----------------------------------DSP---------------------------------------//

InterplaiDirectService.getAllDsps = async function () {
  try {
    let response = await http.get(`admin/dsp`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while fetching the DSPs!");
    }
  }
};

InterplaiDirectService.getAllDspsAndKeys = async function () {
  try {
    let response = await http.get(`admin/dsp/public-connect-keys`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while fetching the DSP connect keys!");
    }
  }
};

InterplaiDirectService.getDspByAccountId = async function () {
  try {
    let response = await http.get(`admin/dsp/by-account`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while fetching the DSP by account ID!");
    }
  }
};

InterplaiDirectService.signUpAsADsp = async function () {
  try {
    let response = await http.post(`admin/dsp`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while registering the DSP!");
    }
  }
};

InterplaiDirectService.updateDsp = async function () {
  try {
    let response = await http.patch(`admin/dsp`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while updating the DSP!");
    }
  }
};
//-----------------------------------DSP---------------------------------------//

//............................3rd party transactions--------------------------------..//
InterplaiDirectService.getAllTransactions = async function (zoneId, startDate, endDate) {
  try {
    let response = await http.get(`admin/transactions`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while retrieving transactions!");
    }
  }
};

//-------------------------------------KPIs------------------------------------//
// Vehicle count
InterplaiDirectService.vehicleAvailabilityCount = async function (zoneId, startDate, endDate) {
  try {
    let response = await http.get(`admin/zones/${zoneId}/vehicles/status?starttimestamp=${startDate}&endtimestamp=${endDate}`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while retrieving vehicles count!");
    }
  }
};

InterplaiDirectService.tripCompletionRate = async function (zoneId, startDate, endDate) {
  try {
    let response = await http.get(`admin/zones/${zoneId}/trips/status?starttimestamp=${startDate}&endtimestamp=${endDate}`);
    return response.data;
  } catch (err) {
    if (err.response && err.response.data && err.response.data.message) {
      throw new Error(err.response.data.message);
    } else {
      throw new Error("Something went wrong while retrieving trips count!");
    }
  }
};

export default InterplaiDirectService;
