import React, { useState, useEffect, useContext } from "react";
import { Provider } from "react-redux";
import { VersionContext } from "Context";
import { NotificationContext } from "context/notificationContext";
import { notification } from "antd";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import { SocketContext, socket } from "./context/socket";
import FirebaseService from "services/FirebaseService";
import moment from "moment";
import InterplaiDirectService from "services/InterplaiDirectService";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const [apiVersion, setApiVersion] = useState(
    localStorage.getItem("apiVersion")
  );
  const [globalLoader, setGlobalLoader] = useState(false);

  const openNotification = (status, msg) => {
    notification.open({
      message: status,
      description: msg,
    });
  };

  // Disconnect socket on app unmount
  useEffect(() => {
    // Register client with socket
    const accountId = localStorage.getItem("accountId");

    if (accountId) {
      socket.emit("registerClient", accountId);
    }

    return () => {
      socket.disconnect();
    };
  }, [localStorage.getItem("accountId")]);

  // Runs every 30 mins to check if the firebase ID needs to be renewed
  useEffect(() => {
    setInterval(async () => {
      var currentTime = moment().local();
      var expiryTime = moment(localStorage.getItem("expires_at")).local();

      const differenceDuration = moment.duration(expiryTime.diff(currentTime));
      const remainingMinutes = differenceDuration.minutes();

      //If minutes less than 5 refresh the token
      if (remainingMinutes <= 5) {
        await FirebaseService.renewToken();
      }
    }, 270000);
  }, []);

  // If dispatcher is logged in, update the timestamp every 5 mins
  useEffect(() => {
    const patchTimestamp = async () => {
      const userRole = localStorage.getItem("role");
      const id = localStorage.getItem("userId");

      if (userRole === "dispatcher") {
        await InterplaiDirectService.updateUserLoginStatus(id);
      }
    };

    const interval = setInterval(patchTimestamp, 300000);

    // Check if the dispatcher is already logged in
    const initialUserRole = localStorage.getItem("role");

    if (initialUserRole === "dispatcher") {
      patchTimestamp();
    }

    return () => {
      clearInterval(interval);
    };
  }, [localStorage.getItem("role")]);

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={
          localStorage.getItem("colorTheme")
            ? localStorage.getItem("colorTheme")
            : THEME_CONFIG.currentTheme
        }
        insertionPoint="styles-insertion-point"
      >
        <VersionContext.Provider
          value={{ apiVersion, globalLoader, setApiVersion, setGlobalLoader }}
        >
          <SocketContext.Provider value={{ socket }}>
            <Provider store={store}>
              <Router>
                <Switch>
                  <Route path="/" component={Views} />
                </Switch>
              </Router>
            </Provider>
          </SocketContext.Provider>
        </VersionContext.Provider>
      </ThemeSwitcherProvider>
    </div>
  );
}

export default App;
