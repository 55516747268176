export const parseCostMatrixInput = (input) => {
  const inputArray = input.split(",");
  const outputArray = [];
  for (let i = 0; i < inputArray.length; i += 2) {
    const lat = Number(inputArray[i].trim());
    const lng = Number(inputArray[i + 1].trim());
    if (isNaN(lat) || isNaN(lng)) {
      outputArray.push({});
    }
    outputArray.push({ lat, lng });
  }
  return outputArray;
};

export const algoDesc = (algo) => {
  let desc = "";
  if (algo === "haversine") {
    desc = "Cost Matrix is caculated using the great-circle distance between two points on a sphere.";
  } else if (algo === "roadNetwork") {
    desc = "Cost Matrix is calculated using the road network.";
  } else {
    desc = "(No description found.)";
  }
  return desc;
};
