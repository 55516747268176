import React, { useState, useEffect, useContext } from "react";
import { Menu, Dropdown, Avatar, Modal } from "antd";
import { connect } from "react-redux";
import { SocketContext } from "context/socket";
import {
  KeyOutlined,
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  SecurityScanOutlined,
  LogoutOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import InterplaiDirectService from "../../services/InterplaiDirectService";
import { useThemeSwitcher } from "react-css-theme-switcher";
import Profile from "views/app-views/admin/profile/index.js";

export const NavProfile = ({ signOut }) => {
  const { socket } = useContext(SocketContext);
  const [user, setUser] = useState(null);

  const [viewProfile, setViewProfile] = useState(false);

  const { switcher, themes } = useThemeSwitcher();

  const handleLogout = () => {
    switcher({ theme: themes.light });
    socket.disconnect();
    signOut();
  };

  useEffect(async () => {
    if (user === null) {
      const userObj = await InterplaiDirectService.loadUser();
      setUser(userObj);
    }
  }, []);

  const profileMenu = (
    <div className="nav-profile nav-dropdown" id="nav-profile-ddown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            <h4 className="mb-0 font-weight-light">
              Hello,&nbsp;<span className="font-weight-bold">{user && user.fullName}</span>!
            </h4>
            <span className="text-muted mb-2">{user && user.companyName}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item onClick={() => setViewProfile(true)}>
            <span className="bg-none">
              <Icon className="mr-3" type={SettingOutlined} />
              <span className="font-weight-normal">Profile</span>
            </span>
          </Menu.Item>
          <Menu.Item id="logout" onClick={handleLogout}>
            <span>
              <PoweroffOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>

      <Modal
        closeable
        maskClosable={false}
        title="User Profile"
        visible={viewProfile}
        onCancel={() => setViewProfile(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={null}
        width={960}
        style={{ maxWidth: "82vw" }}
      >
        <Profile user={user} signOut={signOut} />
      </Modal>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item id="avatar">
          <Avatar id="dp" style={{ backgroundColor: "#467534" }}>
            {user && user.nameInitials}
          </Avatar>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
