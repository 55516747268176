import React, { useState, useEffect } from "react";
import { Row, Form, Col, Input, Button, Popover, Modal, Alert } from "antd";
import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";
import tickimg from "../../img/correct.png";
import { parseCostMatrixInput, algoDesc } from "utils/data-entry";
import "./index.css";

const { TextArea } = Input;

const CostMatrixEntry = ({ showCostMatrixModal, setShowCostMatrixModal }) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({
    state: false,
    message: {
      id: null,
      name: null,
    },
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4500);
    }
  }, [error]);

  const DownloadTXT = ({ data, fileName }) => {
    const handleClick = () => {
      const blob = new Blob([data], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    };

    return (
      <a onClick={handleClick}>
        <strong>Download</strong>
      </a>
    );
  };

  const handleNewCalc = () => {
    setSuccess({
      state: false,
      message: {
        id: null,
        name: null,
      },
    });
    setError(null);
    setShowCostMatrixModal(true);
  };

  const handleCostMatrixClose = () => {
    setSuccess({
      state: false,
      message: {
        id: null,
        name: null,
      },
    });
    setError(null);
    setShowCostMatrixModal(false);
  };

  const onCostMatrixSubmit = async (values) => {
    setLoading(true);
    try {
      let payload = {
        coordinates: {
          sources: parseCostMatrixInput(values.sources),
          destinations: parseCostMatrixInput(values.destinations),
        },
      };
      const res = await InterplaiDirectService.calculateCostMatrix(payload);
      setSuccess({
        ...success,
        state: true,
        message: {
          algorithm: res.algorithm,
          distances: res.distances.toString(),
          durations: res.durations.toString(),
        },
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onCostMatrixSubmit(values);
      })
      .catch((err) => {
        setError("One or more required fields are missing");
      });
  };

  const onCostMatrixReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={showCostMatrixModal}
      onCancel={handleCostMatrixClose}
      title="Calculate Cost Matrix"
      maskClosable={false}
      footer={
        success.state
          ? [
              <Button
                key="new-calc"
                type="primary"
                onClick={handleNewCalc}
                style={{ backgroundColor: "#228B22", float: "left" }}
              >
                New Cost Matrix Calculation
              </Button>,
              <Button
                key="close"
                type="primary"
                onClick={handleCostMatrixClose}
                id="cost-matrix-close"
              >
                Close
              </Button>,
            ]
          : [
              <Button
                key="clear"
                onClick={onCostMatrixReset}
                style={{ float: "left" }}
                id="cost-matrix-reset"
              >
                <ReloadOutlined />
                Clear all
              </Button>,
              <Button
                key="submit"
                htmlType="submit"
                type="primary"
                loading={loading}
                onClick={onSubmit}
                id="cost-matrix-button"
              >
                Calculate
              </Button>,

              <Button type="ghost" key="cancel" onClick={handleCostMatrixClose}>
                Cancel
              </Button>,
            ]
      }
      width={540}
    >
      {error && (
        <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
              <Alert message={error} showIcon type="error" closable />
            </Col>
          </Row>
        </div>
      )}
      <div
        style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "56vh" }}
      >
        {success.state ? (
          <Row gutter={16} justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="d-block mb-4">
                <img
                  src={tickimg}
                  className="mr-3"
                  height="28px"
                  width="28px"
                  alt="success"
                />
                <strong>The cost matrix is successfully calculated!</strong>
              </div>
              <div className="d-flex flex-column flex-start align-items-center my-3">
                <p>
                  Algorithm:{" "}
                  <strong>
                    {success.message.algorithm.slice(0, 1).toUpperCase() +
                      success.message.algorithm.slice(1)}
                  </strong>
                </p>
                <small>
                  Description:
                  <strong>{algoDesc(success.message.algorithm)}</strong>
                </small>
              </div>
              <div className="d-flex align-items-center my-3">
                <div id="textarea" className="mr-3">
                  {success.message.distances}
                </div>
                <DownloadTXT
                  data={success.message.distances}
                  fileName="distances.txt"
                />
              </div>
              <div className="d-flex align-items-center my-3">
                <div id="textarea" className="mr-3">
                  {success.message.durations}
                </div>
                <DownloadTXT
                  data={success.message.durations}
                  fileName="durations.txt"
                />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form
                  form={form}
                  layout="vertical"
                  name="calculate-cost-matrix"
                  preserve={false}
                  className="m-3"
                  id="cost-matrix-form"
                >
                  <Form.Item
                    name="sources"
                    label="Sources"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter source coordinates separated by comma.",
                      },
                    ]}
                  >
                    <div className="d-flex align-items-center">
                      <TextArea
                        id="de-cm-source"
                        rows={4}
                        autoComplete="off"
                        className="mr-3"
                        placeholder="Enter latitudes and longitudes separated by comma, e.g., [lat],[long],[lat],[long],..."
                      />
                      <Popover
                        content="Source coordinates. (string)"
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="destinations"
                    label="Destinations"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message:
                          "Please enter destination coordinates separated by comma.",
                      },
                    ]}
                  >
                    <div className="d-flex align-items-center">
                      <TextArea
                        id="de-cm-destination"
                        rows={4}
                        autoComplete="off"
                        className="mr-3"
                        placeholder="Enter latitudes and longitudes separated by comma, e.g., [lat],[long],[lat],[long],..."
                      />
                      <Popover
                        content="Destination coordinates. (string)"
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};

export default CostMatrixEntry;
