import React, { useState, useEffect } from "react";
import { Row, Col, Card, Checkbox, Form, Input, InputNumber, Popover, Radio, Select, Space } from "antd";
import { InfoCircleOutlined, UpOutlined, DownOutlined, ReloadOutlined } from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";

import Manifests from "./sub-forms/Manifests";

const { Option } = Select;

const TripForm = (props) => {
  const { form, selectedZone, manifests, setManifests } = props;
  const [vehicles, setVehicles] = useState([]);
  const [vehicleListLoading, setVehicleListLoading] = useState(false);

  const fetchVehicles = async (zoneid) => {
    setVehicleListLoading(true);
    try {
      if (zoneid) {
        const allVehicles = await InterplaiDirectService.getAllVehicles(zoneid);
        const availableVehicles = allVehicles.filter((vehicle) => vehicle.dutyMode === "onDuty");
        setVehicles(availableVehicles);
      } else {
        return setVehicles([]);
      }
      setVehicleListLoading(false);
    } catch (err) {
      console.error(err);
      setVehicleListLoading(false);
    }
  };

  useEffect(() => {
    fetchVehicles(selectedZone);
  }, [selectedZone]);

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="maxStopsAllowed"
            label="Maximum Stops Allowed"
            hasFeedback
            shouldUpdate={true}
            initialValue={4}
            rules={[{ required: false }]}
          >
            <div className="d-flex align-items-center">
              <InputNumber
                autoComplete="off"
                className="mr-3"
                placeholder="(Enter a whole number) Minimum accepted value is 2"
                style={{
                  width: "100%",
                }}
                defaultValue={4}
              />
              <Popover content="Maximum number of locations that can be visited in a route. (int32)" trigger="hover" placement="right">
                <InfoCircleOutlined />
              </Popover>
            </div>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            name="manuallyAssignedVehicleId"
            label={
              <div className={`${vehicleListLoading ? "d-flex justify-content-between w-100 align-items-center" : ""}`}>
                <span>Manually Assigned Vehicle</span>
                {selectedZone && vehicleListLoading && (
                  <span className="cursor-pointer text-info font-size-sm font-weight-normal mx-2">Loading vehicle options...</span>
                )}
              </div>
            }
            hasFeedback
            shouldUpdate={true}
            initialValue={""}
            rules={[{ required: false }]}
          >
            <div className="d-flex align-items-center">
              <Select
                suffixIcon={
                  <Popover trigger="hover" content="Reset">
                    <ReloadOutlined
                      className="mr-3"
                      style={{ cursor: "pointer", color: "#228B22", height: "26px", width: "26px" }}
                      onClick={() => {
                        form.resetFields(["manuallyAssignedVehicleId"]);
                      }}
                    />
                  </Popover>
                }
                showSearch
                optionFilterProp="children"
                className={"mr-3"}
                onChange={(value) => {
                  form.setFieldsValue({
                    manuallyAssignedVehicleId: value,
                  });
                }}
                loading={vehicleListLoading}
                placeholder={selectedZone && vehicleListLoading ? "Updating vehicle options..." : "Select a vehicle"}
              >
                {vehicles.length > 0 &&
                  vehicles.map((vehicle) => (
                    <Option key={vehicle.id} value={vehicle.id}>
                      {vehicle.id + ":" + (vehicle?.licensePlateNumber || "<no-license-plate-number>")}
                    </Option>
                  ))}
              </Select>
              <Popover content="Manually assign a on-duty vehicle to this trip. (string)" trigger="hover" placement="right">
                <InfoCircleOutlined />
              </Popover>
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card title="Pick-up Details">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Name of the location" className="w-100" name="pickupName" rules={[{ required: false }]}>
                <Input autoComplete="off" className="mr-2" placeholder="e.g., Zoe's Bistro" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Phone number" className="w-100" name="pickupPhoneNumber" rules={[{ required: false }]}>
                <Input autoComplete="off" className="mr-2" placeholder="e.g., +14155550132" />
              </Form.Item>
            </Col>
            <Card title="Address">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Street 1"
                  className="w-100"
                  name="pickupStreet1"
                  rules={[{ required: true, message: "Street 1 is required" }]}
                >
                  <Input autoComplete="off" className="mr-2" placeholder="Enter street name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Street 2" className="w-100" name="pickupStreet2" rules={[{ required: false }]}>
                  <Input autoComplete="off" className="mr-2" placeholder="Enter street name 2" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Unit" className="w-100" name="pickupUnit" rules={[{ required: false }]}>
                  <Input autoComplete="off" className="mr-2" placeholder="Enter unit" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="City" className="w-100" name="pickupCity" rules={[{ required: true, message: "City is required" }]}>
                  <Input autoComplete="off" className="mr-2" placeholder="Enter city" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="State Province"
                  className="w-100"
                  name="pickupStateProvince"
                  rules={[{ required: true, message: "State province is required" }]}
                >
                  <Input autoComplete="off" className="mr-2" placeholder="Enter state province" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Postal Code"
                  className="w-100"
                  name="pickupPostalCode"
                  rules={[{ required: true, message: "Postal code is required" }]}
                >
                  <Input autoComplete="off" className="mr-2" placeholder="Enter postal code" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Country"
                  className="w-100"
                  name="pickupCountry"
                  rules={[{ required: true, message: "Country is required" }]}
                >
                  <Input autoComplete="off" className="mr-2" placeholder="Enter country" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Notes for pick-up address"
                  className="w-100"
                  name="pickupHandlingInstructions"
                  rules={[{ required: false }]}
                >
                  <Input.TextArea rows={3} autoComplete="off" className="mr-2" placeholder="e.g, Pick up at the front desk" />
                </Form.Item>
              </Col>
            </Card>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Handling Steps" className="w-100" name="pickupHandlingSteps" rules={[{ required: false }]}>
                <Checkbox.Group className="mr-2 w-100">
                  <Row>
                    <Col span={12}>
                      <Checkbox value="collectSignature">Collect signature</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="scanBarCode">Scan barcode</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="scanQrCode">Scan Qr code</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="checkOrderItems">Check order items</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="takePhoto">Take photo</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card title="Drop-off Details">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Name of the location" className="w-100" name="dropoffName" rules={[{ required: false }]}>
                <Input autoComplete="off" className="mr-2" placeholder="e.g., Wilmer Residence" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Phone number" className="w-100" name="dropoffPhoneNumber" rules={[{ required: false }]}>
                <Input autoComplete="off" className="mr-2" placeholder="e.g., +14155550132" />
              </Form.Item>
            </Col>
            <Card title="Address">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Street 1"
                  className="w-100"
                  name="dropoffStreet1"
                  rules={[{ required: true, message: "Street 1 is required" }]}
                >
                  <Input autoComplete="off" className="mr-2" placeholder="Enter street name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Street 2" className="w-100" name="dropoffStreet2" rules={[{ required: false }]}>
                  <Input autoComplete="off" className="mr-2" placeholder="Enter street name 2" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Unit" className="w-100" name="dropoffUnit" rules={[{ required: false }]}>
                  <Input autoComplete="off" className="mr-2" placeholder="Enter unit" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="City" className="w-100" name="dropoffCity" rules={[{ required: true, message: "City is required" }]}>
                  <Input autoComplete="off" className="mr-2" placeholder="Enter city" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="State Province"
                  className="w-100"
                  name="dropoffStateProvince"
                  rules={[{ required: true, message: "State province is required" }]}
                >
                  <Input autoComplete="off" className="mr-2" placeholder="Enter state province" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Postal Code"
                  className="w-100"
                  name="dropoffPostalCode"
                  rules={[{ required: true, message: "Postal code is required" }]}
                >
                  <Input autoComplete="off" className="mr-2" placeholder="Enter postal code" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Country"
                  className="w-100"
                  name="dropoffCountry"
                  rules={[{ required: true, message: "Country is required" }]}
                >
                  <Input autoComplete="off" className="mr-2" placeholder="Enter country" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  label="Notes for drop-off address"
                  className="w-100"
                  name="dropoffHandlingInstructions"
                  rules={[{ required: false }]}
                >
                  <Input.TextArea rows={3} autoComplete="off" className="mr-2" placeholder="e.g, Do not press the bell" />
                </Form.Item>
              </Col>
            </Card>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Handling Steps" className="w-100" name="dropoffHandlingSteps" rules={[{ required: false }]}>
                <Checkbox.Group className="mr-2 w-100">
                  <Row>
                    <Col span={12}>
                      <Checkbox value="collectSignature">Collect signature</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="scanBarCode">Scan barcode</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="scanQrCode">Scan QR code</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="checkOrderItems">Check order items</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="takePhoto">Take photo</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="verifyAge">Verify age (min. age 21)</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="collectPayment">Collect payment</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="performNoContactDelivery">Perform no-contact delivery</Checkbox>
                    </Col>
                    <Col span={12}>
                      <Checkbox value="handOrderToCustomer">Hand order to customer</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="Fallback Handling Steps"
                className="w-100"
                name="dropoffFallbackHandlingSteps"
                rules={[{ required: false }]}
              >
                <Radio.Group>
                  <Radio value={"leaveOrderAtDoor"}>Leave order at door</Radio>
                  <Radio value={"discardOrder"}>Discard order</Radio>
                  <Radio value={"returnOrder"}>Return order</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-2">
          <a className="font-weight-bold" onClick={() => setManifests(!manifests)}>
            {" "}
            Add Manifest&nbsp;{manifests ? <UpOutlined /> : <DownOutlined />}
          </a>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          {manifests && <Manifests form={form} />}
        </Col>
      </Row>
    </>
  );
};

export default TripForm;
