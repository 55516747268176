import React from "react";
import { Form, Button, Select, Input, InputNumber, Popover, Switch, DatePicker, TimePicker } from "antd";
import { InfoCircleOutlined, PlusOutlined, MinusCircleOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";

const { Option } = Select;

const Waypoints = ({ waypointList, setWaypointList }) => {
  const appendArray = () => {
    setWaypointList((prevState) => [
      ...prevState,
      {
        location: {
          checked: false,
          address: false,
        },
        timewindow: false,
        handlingSteps: [],
      },
    ]);
  };

  const trimArray = (index) => {
    setWaypointList((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  const handleWaypoint = (index) => {
    setWaypointList((prevState) => {
      const newState = [...prevState];
      newState[index].location.checked = !newState[index].location.checked;
      return newState;
    });
  };

  const handleLocationCoordinates = (index) => {
    setWaypointList((prevState) => {
      const newState = [...prevState];
      newState[index].location.coordinates = !newState[index].location.coordinates;
      return newState;
    });
  };

  const handleWaypointTimewindow = (index) => {
    setWaypointList((prevState) => {
      const newState = [...prevState];
      newState[index].timewindow = !newState[index].timewindow;
      return newState;
    });
  };

  const addHandlingStep = (waypointIndex) => {
    setWaypointList((prevState) => {
      const newState = [...prevState];
      if (!newState[waypointIndex].handlingSteps) {
        newState[waypointIndex].handlingSteps = [];
      }
      newState[waypointIndex].handlingSteps.push({ type: null, additionalData: {} });
      return newState;
    });
  };

  // const updateHandlingStep = (waypointIndex, stepIndex, selectedType) => {
  //   setWaypointList((prevState) => {
  //     const newState = [...prevState];
  //     const handlingSteps = newState[waypointIndex].handlingSteps || [];
  //     if (!handlingSteps[stepIndex]) {
  //       handlingSteps[stepIndex] = { type: null, additionalData: {} };
  //     }
  //     handlingSteps[stepIndex].type = selectedType;
  //     handlingSteps[stepIndex].additionalData = {};
  //     newState[waypointIndex].handlingSteps = handlingSteps;
  //     return newState;
  //   });
  // };

  const updateHandlingStep = (waypointIndex, stepIndex, selectedType, additionalData) => {
    setWaypointList((prevState) => {
      const newState = [...prevState];
      const handlingSteps = newState[waypointIndex].handlingSteps || [];

      // If the handling step at stepIndex doesn't exist, initialize it.
      if (!handlingSteps[stepIndex]) {
        handlingSteps[stepIndex] = { type: null, additionalData: {} };
      }

      // Update the handling step, merging type and additionalData
      handlingSteps[stepIndex] = {
        ...additionalData,
        type: selectedType,
      };

      newState[waypointIndex].handlingSteps = handlingSteps;
      return newState;
    });
  };

  const removeHandlingStep = (waypointIndex, stepIndex) => {
    setWaypointList((prevState) => {
      const newState = [...prevState];
      newState[waypointIndex].handlingSteps.splice(stepIndex, 1);
      return newState;
    });
  };

  return (
    <div className="mb-4 mt-2 p-3 border rounded">
      <small className="d-block mb-3">(Minimum two waypoints required)</small>
      <Form.List name="waypoints" className="my-3">
        {(waypoints, { add, remove }) => (
          <>
            {waypoints.map((waypoint, index) => (
              <Form.Item
                key={waypoint.key}
                label={`Waypoint ${index + 1}`}
                style={{ border: "1px solid #f0f0f0", padding: "16px" }}
                rules={[
                  {
                    required: true,
                    message: `Please input the waypoint ${index + 1}`,
                    validator: (_, steps) => {
                      if (!steps || steps.length < 2) {
                        return Promise.reject(new Error("At least two waypoints are required"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Form.Item label="User Waypoint ID" name={[waypoint.name, "userWaypointId"]} initialValue={""} className="mt-2">
                  <Input id={`de-wp-user-loc-id-${index}`} placeholder="User specific identifier of the waypoint" />
                </Form.Item>
                <Form.Item name={[waypoint.name, "newLocationChecked"]} valuePropName="checked" initialValue={false}>
                  <Switch
                    id={`de-wp-new-loc-checked-${index}`}
                    onChange={() => handleWaypoint(index)}
                    defaultChecked={false}
                    checkedChildren="Location ID"
                    unCheckedChildren="New Location"
                  />
                </Form.Item>

                {!waypointList[index].location.checked && (
                  <Form.Item
                    name={[waypoint.name, "locationId"]}
                    label="Location ID"
                    initialValue={""}
                    hidden={waypointList[index].checked}
                    rules={[
                      {
                        required: !waypointList[index].location.checked,
                        message: "Location ID is required",
                      },
                    ]}
                  >
                    <Input id={`de-wp-trip-loc-id-${index}`} placeholder="Enter location ID" />
                  </Form.Item>
                )}

                {waypointList[index].location.checked && (
                  <div className="px-3 py-2 border rounded">
                    <Form.Item label="Name" name={[waypoint.name, "newLocation", "name"]} initialValue={""}>
                      <Input id={`de-wp-trip-name-${index}`} placeholder="Enter name of the location" />
                    </Form.Item>

                    <Form.Item label="User Location ID" name={[waypoint.name, "newLocation", "userLocationId"]} initialValue={""}>
                      <Input id={`de-wp-user-loc-id-${index}`} placeholder="Enter user location ID" />
                    </Form.Item>

                    <div className="my-4 d-block">
                      <Form.Item name={[waypoint.name, "newLocation", "coordinatesChecked"]} valuePropName="checked">
                        <Switch
                          id={`de-wp-coordinates-checked-${index}`}
                          onChange={() => handleLocationCoordinates(index)}
                          checkedChildren="Enter Address"
                          unCheckedChildren="Enter Coordinates"
                          defaultChecked={false}
                        />
                      </Form.Item>

                      {!waypointList[index].location.coordinates && (
                        <div className="mt-2">
                          <a className="mb-3 text-dark">ADDRESS</a>
                          <div className="mt-2 mb-3 p-3 border rounded">
                            <Form.Item
                              name={[waypoint.name, "newLocation", "address", "street1"]}
                              label="Street 1"
                              initialValue={""}
                              rules={[
                                {
                                  required: !waypointList[index].location.coordinates,
                                  message: "Street 1 is required",
                                },
                              ]}
                            >
                              <Input id={`de-wp-trip-street1-${index}`} placeholder="Enter street 1" />
                            </Form.Item>

                            <Form.Item
                              name={[waypoint.name, "newLocation", "address", "street2"]}
                              initialValue={""}
                              label="Street 2"
                              rules={[{ required: false }]}
                            >
                              <Input id={`de-wp-trip-street2-${index}`} placeholder="Enter street 2" />
                            </Form.Item>

                            <Form.Item
                              name={[waypoint.name, "newLocation", "address", "unit"]}
                              initialValue={""}
                              label="Unit"
                              rules={[{ required: false }]}
                            >
                              <Input id={`de-wp-trip-unit-${index}`} placeholder="Enter unit" />
                            </Form.Item>

                            <Form.Item
                              name={[waypoint.name, "newLocation", "address", "city"]}
                              label="City"
                              initialValue={""}
                              rules={[
                                {
                                  required: !waypointList[index].location.coordinates,
                                  message: "City is required",
                                },
                              ]}
                            >
                              <Input id={`de-wp-trip-city-${index}`} placeholder="Enter city" />
                            </Form.Item>

                            <Form.Item
                              name={[waypoint.name, "newLocation", "address", "stateProvince"]}
                              label="State Province"
                              initialValue={""}
                              rules={[
                                {
                                  required: !waypointList[index].location.coordinates,
                                  message: "State Province is required",
                                },
                              ]}
                            >
                              <Input id={`de-wp-trip-state-${index}`} placeholder="Enter state province" />
                            </Form.Item>

                            <Form.Item
                              name={[waypoint.name, "newLocation", "address", "postalCode"]}
                              label="Postal Code"
                              initialValue={""}
                              rules={[
                                {
                                  required: !waypointList[index].location.coordinates,
                                  message: "Postal/Zip code is required",
                                },
                              ]}
                            >
                              <Input id={`de-wp-trip-postal-${index}`} placeholder="Enter postal code" />
                            </Form.Item>
                            <Form.Item
                              name={[waypoint.name, "newLocation", "address", "country"]}
                              label="Country"
                              initialValue={""}
                              rules={[
                                {
                                  required: !waypointList[index].location.coordinates,
                                  message: "Country is required",
                                },
                              ]}
                            >
                              <Input id={`de-wp-trip-country-${index}`} placeholder="Enter country" />
                            </Form.Item>
                          </div>
                        </div>
                      )}

                      {waypointList[index].location.coordinates && (
                        <div className="mt-2">
                          <a className="mb-3 text-dark">COORDINATES</a>
                          <div className="p-3 mb-3 border rounded">
                            <Form.Item
                              name={[waypoint.name, "newLocation", "lat"]}
                              label="Latitude"
                              shouldUpdate={true}
                              rules={[
                                {
                                  required: waypointList[index].location.coordinates,
                                  message: "Please enter latitude.",
                                },
                              ]}
                            >
                              <InputNumber
                                id={`de-wp-trip-coor-lat-${index}`}
                                className="mr-3"
                                style={{
                                  width: "100%",
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              name={[waypoint.name, "newLocation", "lng"]}
                              label="Longitude"
                              shouldUpdate={true}
                              rules={[
                                {
                                  required: waypointList[index].location.coordinates,
                                  message: "Please enter longitude.",
                                },
                              ]}
                            >
                              <InputNumber
                                id={`de-wp-trip-coor-lng-${index}`}
                                className="mr-3"
                                style={{
                                  width: "100%",
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      )}
                    </div>

                    <Form.Item name={[waypoint.name, "newLocation", "phoneNumber"]} label="Phone Number">
                      <div className="d-flex align-items-center">
                        <Input id={`de-wp-trip-phone-number-${index}`} className="mr-3" placeholder="Enter phone number" />
                        <Popover
                          content="The phone number of the device added during registration. Enter without dashes or spaces. For example, 5556667777. (string)"
                          trigger="hover"
                          placement="right"
                        >
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name={[waypoint.name, "newLocation", "supportsTextMessages"]}
                      label="Supports Text Messages"
                      initialValue={false}
                    >
                      <Select id={`de-wp-trip-text-message-${index}`} className="mr-3" defaultValue={false}>
                        <Option id={`de-wp-trip-text-message-false-${index}`} value={false}>
                          False
                        </Option>
                        <Option id={`de-wp-trip-text-message-true-${index}`} value={true}>
                          True
                        </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[waypoint.name, "newLocation", "supportsPhoneCalls"]}
                      label="Supports Phone Calls"
                      initialValue={false}
                    >
                      <Select id={`de-wp-trip-phone-calls-${index}`} className="mr-3" defaultValue={false}>
                        <Option id={`de-wp-trip-phone-calls-false-${index}`} value={false}>
                          False
                        </Option>
                        <Option id={`de-wp-trip-phone-calls-true-${index}`} value={true}>
                          True
                        </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[waypoint.name, "newLocation", "supportsChatMessages"]}
                      label="Supports Chat Messages"
                      initialValue={false}
                    >
                      <Select id={`de-wp-trip-chat-messages-${index}`} className="mr-3" defaultValue={false}>
                        <Option id={`de-wp-trip-chat-messages-false-${index}`} value={false}>
                          False
                        </Option>
                        <Option id={`de-wp-trip-chat-messages-trip-true-${index}`} value={true}>
                          True
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                )}

                <Form.Item name={[waypoint.name, "timeWindowChecked"]} valuePropName="checked" className="mt-2">
                  <Switch
                    onChange={() => handleWaypointTimewindow(index)}
                    checkedChildren="Hide Time Window"
                    unCheckedChildren="Show Time Window"
                  />
                </Form.Item>

                {waypointList[index].timewindow && (
                  <>
                    <div className="d-flex align-items-center">
                      <Form.Item
                        className="w-100"
                        label="Start Date"
                        name={[waypoint.name, "startDate"]}
                        rules={[
                          {
                            required: true,
                            message: "Please specify start date.",
                          },
                        ]}
                      >
                        <DatePicker className="mr-2" />
                      </Form.Item>
                      <Form.Item
                        className="w-100"
                        label="Start Time"
                        name={[waypoint.name, "startTime"]}
                        rules={[
                          {
                            required: true,
                            message: "Please specify start time.",
                          },
                        ]}
                      >
                        <TimePicker className="mr-2" />
                      </Form.Item>
                      <Popover
                        content="Start time for the time window is when the vehicle should not arrive at the location before the start time. The DateTime notation is defined by RFC 3339, section 5.6, for example, 2017-07-21T17:32:28Z."
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined className="align-self-center" />
                      </Popover>
                    </div>
                    <div className="d-flex align-items-center">
                      <Form.Item
                        className="w-100"
                        label="End Date"
                        name={[waypoint.name, "endDate"]}
                        rules={[
                          {
                            required: true,
                            message: "Please specify end date.",
                          },
                        ]}
                      >
                        <DatePicker className="mr-2" />
                      </Form.Item>
                      <Form.Item
                        className="w-100"
                        label="End Time"
                        name={[waypoint.name, "endTime"]}
                        rules={[
                          {
                            required: true,
                            message: "Please specify end time.",
                          },
                        ]}
                      >
                        <TimePicker className="mr-2" />
                      </Form.Item>
                      <Popover
                        content="End time for the time window is when the vehicle should not arrive at the location after the end time. The DateTime notation is defined by RFC 3339, section 5.6, for example, 2017-07-21T17:32:28Z."
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </>
                )}

                <Form.Item
                  name={[waypoint.name, "waypointSequence"]}
                  label="Waypoint Sequence"
                  initialValue={index}
                  rules={[
                    {
                      required: true,
                      message: "Waypoint sequence is required",
                    },
                  ]}
                >
                  <InputNumber id={`de-wp-trip-waypoint-sequence-${index}`} defaultValue={index} style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item name={[waypoint.name, "action"]} label="Action" rules={[{ required: true, message: "Action is required" }]}>
                  <Select placeholder="Select action" id={`waypoint-action-${index}`}>
                    <Option value="pickup" id={`wp-action-pickup-${index}`}>
                      Pickup
                    </Option>
                    <Option value="dropoff" id={`wp-action-dropoff-${index}`}>
                      Dropoff
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item name={[waypoint.name, "demand"]} label="Demand" initialValue={0} rules={[{ required: false }]}>
                  <InputNumber defaultValue={0} style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item name={[waypoint.name, "serviceTime"]} label="Service Time" initialValue={0} rules={[{ required: false }]}>
                  <InputNumber defaultValue={0} style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                  name={[waypoint.name, "handlingInstructions"]}
                  label="Handling Instructions"
                  initialValue={""}
                  rules={[{ required: false }]}
                >
                  <Input id={`de-wp-trip-Handling-Instructions-${index}`} placeholder="Enter handling instructions" />
                </Form.Item>

                <Form.List name={[waypoint.name, "handlingSteps"]} initialValue={[]}>
                  {(handlingSteps, { add, remove }) => (
                    <>
                      {handlingSteps.map((step, idx) => (
                        <Form.Item
                          key={step.key}
                          label={`Handling Step ${idx + 1}`}
                          style={{ display: "flex" }}
                          rules={[
                            {
                              required: true,
                              message: `Please input the handling step ${idx + 1}`,
                            },
                          ]}
                        >
                          <Form.Item
                            name={[step.name, "name"]}
                            noStyle
                            rules={[
                              {
                                required: true,
                                message: "Handling step name is required",
                              },
                            ]}
                          >
                            <Select
                              id={`de-wp-trip-Handling-steps-${index}-${idx}`}
                              placeholder="Select handling step name"
                              onChange={(value) => updateHandlingStep(index, idx, value)}
                            >
                              <Option id={`de-wp-trip-Handling-steps-sign-${index}-${idx}`} value="collectSignature">
                                Collect Signature
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-bar-${index}-${idx}`} value="scanBarCode">
                                Scan Bar Code
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-qr-${index}-${idx}`} value="scanQrCode">
                                Scan QR Code
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-order-${index}-${idx}`} value="checkOrderItems">
                                Check Order Items
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-photo-${index}-${idx}`} value="takePhoto">
                                Take Photo
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-age-${index}-${idx}`} value="verifyAge">
                                Verify Age
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-payment-${index}-${idx}`} value="collectPayment">
                                Collect Payment
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-nocontact-${index}-${idx}`} value="performNoContactDelivery">
                                Perform No Contact Delivery
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-pincode-${index}-${idx}`} value="pinCode">
                                Pincode
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-verifyid-${index}-${idx}`} value="verifyIdentification">
                                Verify Identification
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-handover-${index}-${idx}`} value="handOrderToCustomer">
                                Hand Order To Customer
                              </Option>
                              <Option id={`de-wp-trip-Handling-steps-leaveatdoor-${index}-${idx}`} value="leaveOrderAtDoor">
                                Leave Order At Door
                              </Option>
                            </Select>
                          </Form.Item>

                          {waypointList[index].handlingSteps[idx]?.type === "verifyAge" && (
                            <Form.Item
                              name={[step.name, "additionalData", "minimumAge"]}
                              label="Minimum Age"
                              rules={[{ required: false }]}
                              className="mt-3 mb-2"
                              initialValue={21}
                            >
                              <InputNumber className="w-100" placeholder="Enter minimum age" />
                            </Form.Item>
                          )}

                          {waypointList[index].handlingSteps[idx]?.type === "collectSignature" && (
                            <Form.Item
                              name={[step.name, "additionalData", "collectSignerName"]}
                              label="Collect Signer Name"
                              rules={[{ required: false }]}
                              className="mt-3 mb-2"
                              initialValue={false}
                            >
                              <Select className="w-100">
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                              </Select>
                            </Form.Item>
                          )}

                          {waypointList[index].handlingSteps[idx]?.type === "collectSignature" && (
                            <Form.Item
                              name={[step.name, "additionalData", "collectSignerRelationship"]}
                              label="Collect Signer Relationship"
                              rules={[{ required: false }]}
                              initialValue={false}
                              className="mt-3 mb-2"
                            >
                              <Select className="w-100">
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                              </Select>
                            </Form.Item>
                          )}

                          {waypointList[index].handlingSteps[idx]?.type === "verifyIdentification" && (
                            <Form.Item
                              name={[step.name, "additionalData", "nameToVerify"]}
                              label="Name To Verify"
                              rules={[{ required: false }]}
                              className="mt-3 mb-2"
                            >
                              <Input className="w-100" placeholder="Enter name to verify" />
                            </Form.Item>
                          )}

                          {waypointList[index].handlingSteps[idx]?.type === "pinCode" && (
                            <Form.Item
                              name={[step.name, "additionalData", "pinCodeToVerify"]}
                              label="Pincode To Verify"
                              rules={[{ required: false }]}
                              className="mt-3 mb-2"
                            >
                              <Input className="w-100" placeholder="Enter pincode" />
                            </Form.Item>
                          )}

                          {(waypointList[index].handlingSteps[idx]?.type === "scanBarCode" ||
                            waypointList[index].handlingSteps[idx]?.type === "scanQrCode") && (
                            <Form.Item
                              name={[step.name, "additionalData", "value"]}
                              label="Value"
                              rules={[{ required: false }]}
                              className="mt-3 mb-2"
                            >
                              <Input className="w-100" placeholder="Enter value" />
                            </Form.Item>
                          )}

                          {handlingSteps.length >= 1 ? (
                            <Button
                              type="link"
                              onClick={() => {
                                remove(step.name);
                                removeHandlingStep(index, idx);
                              }}
                              style={{ marginLeft: 8 }}
                            >
                              <MinusCircleOutlined /> Remove Handling Step
                            </Button>
                          ) : null}
                        </Form.Item>
                      ))}

                      <Form.Item>
                        <Button
                          id={`de-add-handling-steps-${index}`}
                          type="dashed"
                          onClick={() => {
                            add();
                            addHandlingStep(index);
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add Handling Step
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <Form.Item
                  name={[waypoint.name, "fallbackHandlingSteps"]}
                  help="Only for dropoff waypoint(s)"
                  label="Fallback Handling Steps"
                  rules={[{ required: false }]}
                >
                  <Select placeholder="Select fallback step (in case order can't be completed)" id={`waypoint-fbhs-${index}`}>
                    <Option value={"leaveOrderAtDoor"} id={`waypoint-fbhs-lad-${index}`}>
                      Leave Order At Door
                    </Option>
                    <Option value={"discardOrder"} id={`waypoint-fbhs-do-${index}`}>
                      Discard Order
                    </Option>
                    <Option value={"returnOrder"} id={`waypoint-fbhs-ro-${index}`}>
                      Return Order
                    </Option>
                  </Select>
                </Form.Item>

                <Form.List name={[waypoint.name, "manifestIds"]} initialValue={[]}>
                  {(manifestIds, { add, remove }) => (
                    <>
                      {manifestIds.map((step, index) => (
                        <Form.Item
                          key={step.key}
                          label={`Manifest ID ${index + 1}`}
                          style={{ display: "flex" }}
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                        >
                          <Form.Item
                            name={[step.name, "manifestId"]}
                            noStyle
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                            <Input placeholder="Enter manifest ID" />
                          </Form.Item>

                          {manifestIds.length >= 1 ? (
                            <Button type="link" onClick={() => remove(step.name)} style={{ marginLeft: 8 }}>
                              <MinusCircleOutlined /> Remove Manifest
                            </Button>
                          ) : null}
                        </Form.Item>
                      ))}

                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                          Add Manifest ID
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                <Button
                  type="link"
                  onClick={() => {
                    remove(waypoint.name);
                    trimArray(index);
                  }}
                  style={{ marginLeft: 8, marginTop: 4 }}
                >
                  <MinusCircleOutlined /> Remove Waypoint
                </Button>
              </Form.Item>
            ))}

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                  appendArray();
                }}
                icon={<PlusOutlined />}
              >
                Add Waypoint
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default Waypoints;
