import { Form, Input, Switch, Popover, Radio, Table, Spin } from "antd";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

const ThirdParty = (props) => {
  const { isManual, setIsManual, publicDsps, selectedPublicDsps, setSelectedPublicDsps, listLoading } = props;

  const publicDspColumns = [
    {
      title: "Partner",
      dataIndex: "companyName",
      key: "companyName",
      render: (_, record) => <strong>{record.companyName}</strong>,
      align: "center",
    },
    {
      title: "Connect Key",
      dataIndex: "connectApiKey",
      key: "connectApiKey",
      render: (_, record) => <strong>{record.connectApiKey.slice(0, 4) + "****" + record.connectApiKey.slice(-4)}</strong>,
      align: "center",
    },
    {
      title: "Accept",
      dataIndex: "action",
      key: "action",
      render: (_, record) => <Radio disabled />,
      align: "right",
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedPublicDsps,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`Public DSPs selected: ${selectedRowKeys}`);
      setSelectedPublicDsps(selectedRowKeys);
    },
  };

  return (
    <>
      <a className="text-dark">THIRD PARTY/CONNECT KEY DETAILS</a>
      <div className="p-3 mt-2 border rounded align-items-center">
        <Form.Item name="isManualKey" valuePropName="checked" defaultChecked={false}>
          <Switch
            id="de-rfq-is-manual-key"
            onChange={() => setIsManual(!isManual)}
            checkedChildren="Select from the list of DSPs"
            unCheckedChildren="Enter connect key(s) manually"
            defaultChecked={false}
          />
        </Form.Item>
        {isManual ? (
          <Form.Item
            name="connectApiKey"
            label="Private Connect API Key(s)"
            hasFeedback
            shouldUpdate={true}
            initialValue={""}
            rules={[{ required: isManual, message: "Enter connect api-key(s)" }]}
          >
            <div className="d-flex align-items-center">
              <TextArea
                rows={3}
                autoComplete="off"
                className="mr-3"
                id="de-rfq-connect-key"
                placeholder="Enter public connect api keys separated by comma"
              />
              <Popover content="Public Connect API key(s). (string)" trigger="hover" placement="right">
                <InfoCircleOutlined />
              </Popover>
            </div>
          </Form.Item>
        ) : listLoading ? (
          <div className="example d-flex justify-content-center">
            <Spin spinning={listLoading} indicator={antIcon} className="mr-2" />
            <p>Fetching public delivery partners...</p>
          </div>
        ) : (
          <Table
            className="my-3 p-1 border rounded pb-0"
            dataSource={publicDsps}
            columns={publicDspColumns}
            rowKey="connectApiKey"
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            pagination={false}
          />
        )}
        {!isManual && (
          <small className="mx-1 mt-0">
            Note: Make sure you have an active payment method saved before accepting a quote from a public delivery partner if your third
            party transaction type is instant.
          </small>
        )}
      </div>
    </>
  );
};

export default ThirdParty;
