import {
  UPDATE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_NEXT_BILLING_DATE,
  UPDATE_SUBSCRIPTION_RECURRING_MODE,
} from "../constants/Subscription";

const initState = {
  reduxSubscriptionPlan: "free",
  planMode: "monthly",
  nextBillingDate: localStorage.getItem("nextBillingDate"),
};

const subscription = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_PLAN:
      return {
        ...state,
        reduxSubscriptionPlan: action.payload,
      };
    case UPDATE_SUBSCRIPTION_NEXT_BILLING_DATE:
      return {
        ...state,
        nextBillingDate: action.payload,
      };
    case UPDATE_SUBSCRIPTION_RECURRING_MODE:
      return {
        ...state,
        planMode: action.payload,
      };
    default:
      return state;
  }
};

export default subscription;
