import React, { useState, useEffect } from "react";
import { Row, Form, Col, Input, message, Button, Popover, Select, Typography, Modal, Alert } from "antd";
import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";
import moment from "moment";
import axios from "axios";

import tickimg from "../../img/correct.png";
import errorimg from "../../img/cross.png";

import RfqTripForm from "./RfqTripForm";
import ThirdParty from "./ThirdParty";
import QuotesList from "./QuotesList";

import { getApiBaseUrl } from "utils/base-url/index.js";

import "../trip/index.css";

const { Option } = Select;
const { Paragraph } = Typography;

const RFQEntry = ({ showRFQModal, setShowRFQModal }) => {
  const [form] = Form.useForm();

  const [waypointList, setWaypointList] = useState([]);
  const [advancedTripOptions, setAdvancedTripOptions] = useState(false);
  const [optimizationTimeWindow, setOptimizationTimeWindow] = useState(false);
  const [manifests, setManifests] = useState(false);
  const [transportationProviderPay, setTransportationProvidedPay] = useState(false);

  const [isManual, setIsManual] = useState(false);

  const [loading, setLoading] = useState(false);
  const [successOne, setSuccessOne] = useState({
    state: false,
    message: {
      id: null,
      name: null,
    },
  });
  const [successTwo, setSuccessTwo] = useState({
    state: false,
    message: "",
  });
  const [errorOne, setErrorOne] = useState(null);
  const [errorTwo, setErrorTwo] = useState(null);

  const [zones, setZones] = useState([]);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [publicDsps, setPublicDsps] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [selectedPublicDsps, setSelectedPublicDsps] = useState([]);

  const [finalQuoteDetails, setFinalQuoteDetails] = useState({
    rfqId: null,
    zoneId: null,
    tripId: null,
    quoteId: null,
    fee: null,
    currency: null,
    companyName: null,
  });
  const [acceptLoading, setAcceptLoading] = useState(false);

  const [step, setStep] = useState(1);

  useEffect(() => {
    fetchZones();
    listPaymentOptions();
    fetchPublicDsps();
  }, []);

  const fetchZones = async () => {
    const wm = await InterplaiDirectService.getWorldModels();
    setZones(wm);
  };

  const listPaymentOptions = async () => {
    const options = await InterplaiDirectService.listPaymentMethods();
    setPaymentMethods(options?.paymentMethods);
  };

  const fetchPublicDsps = async () => {
    setListLoading(true);
    const keys = await InterplaiDirectService.getAllDspsAndKeys();
    setPublicDsps(keys);
    setSelectedPublicDsps(keys.map((dsp) => dsp.connectApiKey));
    setListLoading(false);
  };

  useEffect(() => {
    if (errorOne) {
      setTimeout(() => {
        setErrorOne(null);
      }, 4500);
    }
  }, [errorOne]);

  const handleRFQClose = () => {
    setSuccessOne({
      state: false,
      message: {
        id: null,
        name: null,
      },
    });
    setErrorOne(null);
    setShowRFQModal(false);
  };

  const onRFQSubmit = async (values) => {
    setLoading(true);
    try {
      if (waypointList.length < 2) {
        message.error("At least two waypoints are required!");
        setLoading(false);
      } else {
        let payload = {
          userTripId: values.userTripId,
          routingScenarioMode: values.routingScenarioMode,
        };

        if (values.maxStopsAllowed) {
          payload = { ...payload, maxStopsAllowed: Number(values.maxStopsAllowed) };
        }

        if (optimizationTimeWindow) {
          const startDate = moment(values.startDate).format("YYYY-MM-DD");
          const startTime = moment(values.startTime).format("HH:mm:ss");
          const endDate = moment(values.endDate).format("YYYY-MM-DD");
          const endTime = moment(values.endTime).format("HH:mm:ss");
          payload = {
            ...payload,
            optimizationTimeWindow: {
              startTimestamp: startDate + "T" + startTime + "Z",
              endTimestamp: endDate + "T" + endTime + "Z",
            },
          };
        }

        let formattedWaypoints = values.waypoints.map((wp) => {
          let waypoint = {
            action: wp.action,
            waypointSequence: Number(wp.waypointSequence),
            serviceTime: Number(wp.serviceTime),
            demand: Number(wp.demand),
            handlingInstructions: wp.handlingInstructions,
          };
          if (wp.newLocationChecked) {
            waypoint = {
              ...waypoint,
              location: {
                phoneNumber: wp.newLocation.phoneNumber,
                supportsPhoneCalls: wp.newLocation.supportsPhoneCalls,
                supportsTextMessages: wp.newLocation.supportsTextMessages,
                supportsChatMessages: wp.newLocation.supportsChatMessages,
                name: wp.newLocation.name,
                userLocationId: wp.newLocation.userLocationId,
              },
            };
            if (wp.newLocation.coordinatesChecked) {
              waypoint = {
                ...waypoint,
                location: {
                  ...waypoint.location,
                  coordinates: {
                    lat: parseFloat(wp.newLocation.lat),
                    lng: parseFloat(wp.newLocation.lng),
                  },
                },
              };
            } else {
              waypoint = {
                ...waypoint,
                location: {
                  ...waypoint.location,
                  address: wp.newLocation.address,
                },
              };
            }
          } else {
            waypoint = {
              ...waypoint,
              location: {
                id: wp.locationId,
              },
            };
          }

          if (wp.timeWindowChecked) {
            const startDate = moment(wp.startDate).format("YYYY-MM-DD");
            const startTime = moment(wp.startTime).format("HH:mm:ss");
            const endDate = moment(wp.endDate).format("YYYY-MM-DD");
            const endTime = moment(wp.endTime).format("HH:mm:ss");
            waypoint = {
              ...waypoint,
              timeWindow: {
                startTimestamp: startDate + "T" + startTime + "Z",
                endTimestamp: endDate + "T" + endTime + "Z",
              },
            };
          }

          if (wp.handlingSteps.length > 0) {
            const reformattedHandlingSteps = wp.handlingSteps.map((item) => {
              return {
                ...item,
                ...item.additionalData,
                additionalData: undefined,
              };
            });
            waypoint = {
              ...waypoint,
              handlingSteps: reformattedHandlingSteps,
            };
          }

          if (wp.manifestIds.length > 0) {
            waypoint = {
              ...waypoint,
              manifestIds: wp.manifestIds.map((item) => item.manifestId),
            };
          }

          return waypoint;
        });

        payload = { ...payload, waypoints: formattedWaypoints };

        if (advancedTripOptions) {
          if (manifests) {
            payload = {
              ...payload,
              manifests: values.manifests,
            };
          }

          if (transportationProviderPay) {
            payload = {
              ...payload,
              transportationProviderPay: {
                fee: Number(values.fee),
                currency: values.currency,
                customerTip: Number(values.customerTip),
              },
            };
          }
        }

        let finalPayload = {
          userRequestForQuoteId: values.userRequestForQuoteId,
          trip: payload,
        };

        const config = {
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            "connect-api-key": isManual ? values.connectApiKey.trim() : selectedPublicDsps.toString(),
            "encoded-auth-fb-token": localStorage.getItem("encoded_auth_fb_token"),
          },
        };

        const res = await axios.post(`${getApiBaseUrl("2")}/admin/zones/${values.zoneId}/requestforquotes`, finalPayload, config);

        setSuccessOne({
          ...successOne,
          state: true,
          message: {
            id: res.data.id,
            zoneId: res.data.zoneId,
          },
        });
        setFinalQuoteDetails({
          ...finalQuoteDetails,
          rfqId: res.data.id,
          zoneId: res.data.zoneId,
          tripId: res.data.trip.id,
        });
        setLoading(false);
        setStep(2);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setErrorOne(err.response.data.message);
      } else {
        setErrorOne("Something went wrong while creating request for quote. Please check the fields again");
      }
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onRFQSubmit(values);
      })
      .catch((err) => {
        setErrorOne("One or more required fields are missing");
      });
  };

  const onRFQReset = () => {
    form.resetFields();
  };

  const quoteAcceptanceAction = async ({ zoneId, rfqId, quoteId, fee, currency, companyName }) => {
    setAcceptLoading(true);
    try {
      await InterplaiDirectService.quoteAction(zoneId, rfqId, quoteId, "accept");
      setSuccessTwo({
        ...successTwo,
        state: true,
        message: `You have successfully accepted the quote of ${fee + currency.toUpperCase()} provided by ${companyName}.`,
      });
      message.success(`You have successfully accepted the quote of ${fee + currency.toUpperCase()} provided by ${companyName}.`);
      setStep(3);
      setAcceptLoading(false);
    } catch (err) {
      setErrorTwo(err.message);
      message.error(err.message);
      setAcceptLoading(false);
    }
  };

  const handleQuoteAction = async () => {
    if (
      finalQuoteDetails.rfqId &&
      finalQuoteDetails.quoteId &&
      finalQuoteDetails.zoneId &&
      finalQuoteDetails.fee &&
      finalQuoteDetails.currency &&
      finalQuoteDetails.companyName
    ) {
      await quoteAcceptanceAction(finalQuoteDetails);
    }
  };

  return (
    <Modal
      visible={showRFQModal}
      onCancel={handleRFQClose}
      title={step === 1 ? "Create a request and receive quotes from delivery partners" : "Accept quote from delivery partners"}
      maskClosable={false}
      footer={
        !successOne.state
          ? [
              <Button key="clear" onClick={onRFQReset} style={{ float: "left" }} id="create-rfq-reset">
                <ReloadOutlined />
                Clear all
              </Button>,
              <Button
                key="submit1"
                htmlType="submit"
                type="primary"
                loading={loading}
                onClick={onSubmit}
                id="rfq-entry-button"
                className="mr-1"
              >
                Continue
              </Button>,
              <Button key="cancel" onClick={handleRFQClose} id="create-rfq-close-1">
                Cancel
              </Button>,
            ]
          : successOne.state && step !== 3
          ? [
              <Button
                key="accept"
                type="primary"
                disabled={!finalQuoteDetails.quoteId}
                loading={acceptLoading}
                onClick={handleQuoteAction}
                id="accept-quote"
              >
                Accept quote
              </Button>,
              <Button key="cancel" onClick={handleRFQClose} id="create-rfq-close-2">
                Cancel
              </Button>,
            ]
          : step === 3
          ? [
              <Button key="cancel" onClick={handleRFQClose} id="create-rfq-close-3">
                Close
              </Button>,
            ]
          : null
      }
      width={step === 3 ? 540 : 1180}
    >
      {step === 1 && (
        <>
          {errorOne && (
            <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
                  <Alert message={errorOne} showIcon type="error" closable />
                </Col>
              </Row>
            </div>
          )}
          <div style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "60vh" }}>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Alert
                  message="Caution"
                  description={
                    <p className="text-left">
                      DoorDash prohibits the delivery of illegal items, stolen goods, recreational drugs, etc; alcohol and tobacco
                      deliveries require a signed addendum.&nbsp;<a href="#">Learn more.</a>
                    </p>
                  }
                  type="warning"
                  showIcon
                  closable
                  className="m-2"
                />
                <Form
                  form={form}
                  layout="vertical"
                  name="create-rfq"
                  preserve={false}
                  className="mt-4 mx-2 my-3 d-flex"
                  id="rfq-entry-form"
                >
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} className="mr-1">
                    <Form.Item
                      name="zoneId"
                      label="Zone"
                      hasFeedback
                      shouldUpdate={true}
                      initialValue={""}
                      rules={[{ required: true, message: "Please select a zone." }]}
                    >
                      <div className="d-flex align-items-center mb-4">
                        <Select
                          showSearch
                          optionFilterProp="children"
                          className="mr-3"
                          placeholder="Select a zone"
                          filterOption={(input, option) =>
                            option.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          onChange={(value) => {
                            form.setFieldsValue({
                              zoneId: value,
                            });
                          }}
                        >
                          {zones.length > 0 &&
                            zones.map((worldmodel) => (
                              <Option key={worldmodel.id} value={worldmodel.id}>
                                <strong>{worldmodel.name}</strong> ({worldmodel.id})
                              </Option>
                            ))}
                        </Select>
                        <Popover content="The zone in which the vehicle is to be created. (string)" trigger="hover" placement="right">
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                    </Form.Item>
                    <Form.Item
                      name="userRequestForQuoteId"
                      label="User Request For Quote ID"
                      hasFeedback
                      shouldUpdate={true}
                      initialValue={""}
                      rules={[{ required: false }]}
                    >
                      <div className="d-flex align-items-center">
                        <Input autoComplete="off" className="mr-3" placeholder="Enter user request for quote ID" />
                        <Popover content="User-specified identifier of the request for quote. (string)" trigger="hover" placement="right">
                          <InfoCircleOutlined />
                        </Popover>
                      </div>
                    </Form.Item>
                    <a className="text-dark">TRIP OBJECT</a>
                    <div className="p-3 mt-2 border rounded">
                      <RfqTripForm
                        form={form}
                        waypointList={waypointList}
                        setWaypointList={setWaypointList}
                        advancedTripOptions={advancedTripOptions}
                        setAdvancedTripOptions={setAdvancedTripOptions}
                        optimizationTimeWindow={optimizationTimeWindow}
                        setOptimizationTimeWindow={setOptimizationTimeWindow}
                        manifests={manifests}
                        setManifests={setManifests}
                        transportationProviderPay={transportationProviderPay}
                        setTransportationProvidedPay={setTransportationProvidedPay}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} className="ml-1">
                    <ThirdParty
                      publicDsps={publicDsps}
                      setPublicDsps={setPublicDsps}
                      selectedPublicDsps={selectedPublicDsps}
                      setSelectedPublicDsps={setSelectedPublicDsps}
                      isManual={isManual}
                      setIsManual={setIsManual}
                      listLoading={listLoading}
                    />
                  </Col>
                </Form>
              </Col>
            </Row>
          </div>
        </>
      )}
      {step === 2 && successOne.state && (
        <div style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "60vh" }}>
          <QuotesList step={step} finalQuoteDetails={finalQuoteDetails} setFinalQuoteDetails={setFinalQuoteDetails} />
        </div>
      )}
      {step === 3 && errorTwo ? (
        <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
              <div className="d-block mb-4">
                <img src={errorimg} className="mr-3" height="28px" width="28px" alt="error-2" />
                <strong>{errorTwo}</strong>
              </div>
            </Col>
          </Row>
        </div>
      ) : null}
      {step === 3 && successTwo.state && (
        <Row gutter={16} justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="d-block mb-4">
              <img src={tickimg} className="mr-3" height="28px" width="28px" alt="success-2" />
              <strong>{successTwo.message}</strong>
            </div>
            <div className="d-block">
              <strong>Quote ID: </strong>
              <Paragraph id="de-quote-id-accept-success" copyable>
                {finalQuoteDetails.quoteId}
              </Paragraph>
            </div>
            <div className="d-block">
              <strong>Request for quote ID: </strong>
              <Paragraph id="de-rfq-id-accept-success" copyable>
                {finalQuoteDetails.rfqId}
              </Paragraph>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default RFQEntry;
