import React, { useState, useEffect } from "react";
import {
  Row,
  Form,
  Col,
  Input,
  Button,
  Popover,
  Select,
  Typography,
  Modal,
  Alert,
} from "antd";
import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";
import tickimg from "../../img/correct.png";
import errorimg from "../../img/cross.png";

import { connect } from "react-redux";
import { updateServices } from "redux/actions/Items";

const { Option } = Select;
const { Paragraph } = Typography;

const ServiceEntry = (props) => {
  const {
    updateServices,
    showServiceModal,
    setShowServiceModal,
    reduxZones,
    areZonesUpdated,
  } = props;
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({
    state: false,
    message: {
      id: null,
      zoneId: null,
    },
  });
  const [error, setError] = useState(null);

  const [zones, setZones] = useState([]);

  useEffect(() => {
    const fetchZones = async () => {
      const wm = await InterplaiDirectService.getWorldModels();
      setZones(wm);
    };
    fetchZones();
  }, []);

  useEffect(() => {
    if (areZonesUpdated) {
      setZones(reduxZones);
    }
  }, [areZonesUpdated, reduxZones]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4500);
    }
  }, [error]);

  const handleServiceClose = () => {
    setSuccess({
      state: false,
      message: {
        id: null,
        zoneId: null,
      },
    });
    setError(null);
    setShowServiceModal(false);
  };

  const onServiceSubmit = async (values) => {
    setLoading(true);
    try {
      let payload = {
        name: values.name,
        userServiceId: values.userServiceId,
      };
      const res = await InterplaiDirectService.createServices(
        values.zoneId,
        payload
      );
      const allservices = await InterplaiDirectService.getAllServices(
        values.zoneId
      );
      updateServices(allservices);
      setSuccess({
        ...success,
        state: true,
        message: {
          id: res.id,
          zoneId: res.zoneId,
        },
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onServiceSubmit(values);
      })
      .catch((err) => {
        setError("One or more required fields are missing");
      });
  };

  const onServiceReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={showServiceModal}
      onCancel={handleServiceClose}
      title="Create a Service"
      maskClosable={false}
      footer={
        success.state
          ? [
              <Button
                key="close"
                type="primary"
                onClick={handleServiceClose}
                id="create-service-close"
              >
                Close
              </Button>,
            ]
          : [
              <Button
                key="clear"
                onClick={onServiceReset}
                style={{ float: "left" }}
                id="create-service-reset"
              >
                <ReloadOutlined />
                Clear all
              </Button>,
              <Button
                key="submit"
                htmlType="submit"
                type="primary"
                loading={loading}
                onClick={onSubmit}
                id="create-service-button"
              >
                Create
              </Button>,

              <Button
                type="ghost"
                key="cancel"
                onClick={handleServiceClose}
                id="create-service-close"
              >
                Cancel
              </Button>,
            ]
      }
      width={540}
    >
      {error && (
        <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
              <Alert message={error} showIcon type="error" closable />
            </Col>
          </Row>
        </div>
      )}
      <div
        style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "68vh" }}
      >
        {success.state ? (
          <Row gutter={16} justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="d-block mb-4">
                <img
                  src={tickimg}
                  className="mr-3"
                  height="28px"
                  width="28px"
                  alt="success"
                />
                <strong>The service is successfully created!</strong>
              </div>
              <div className="d-block">
                <strong>Service ID: </strong>
                <Paragraph id="de-service-id-success" copyable>
                  {success.message.id}
                </Paragraph>
              </div>
              <div className="d-block">
                <strong>Corresponding Zone ID: </strong>
                <Paragraph copyable>{success.message.zoneId}</Paragraph>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form
                  form={form}
                  layout="vertical"
                  name="create-service"
                  preserve={false}
                  className="m-3"
                  id="create-service-form"
                >
                  <Form.Item
                    name="zoneId"
                    label="Zone"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[
                      { required: true, message: "Please select a zone." },
                    ]}
                  >
                    <div className="d-flex align-items-center">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="mr-3"
                        placeholder="Select a zone"
                        filterOption={(input, option) =>
                          option.props.children[0].props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.props.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          form.setFieldsValue({
                            zoneId: value,
                          });
                        }}
                      >
                        {zones.length > 0 &&
                          zones.map((worldmodel) => (
                            <Option key={worldmodel.id} value={worldmodel.id}>
                              <strong>{worldmodel.name}</strong> (
                              {worldmodel.id})
                            </Option>
                          ))}
                      </Select>
                      <Popover
                        content="The zone in which the vehicle is to be created. (string)"
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>

                  <Form.Item
                    name="name"
                    label="Name"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the name of the service.",
                      },
                    ]}
                  >
                    <div className="d-flex align-items-center">
                      <Input autoComplete="off" className="mr-3" />
                      <Popover
                        content="Name of the service. (string)"
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="userServiceId"
                    label="User Service ID"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[{ required: false }]}
                  >
                    <div className="d-flex align-items-center">
                      <Input autoComplete="off" className="mr-3" />
                      <Popover
                        content="User-specified identifier of the service. (string)"
                        trigger="hover"
                        placement="right"
                      >
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxZones: state.items.zones,
    areZonesUpdated: state.items.areZonesUpdated,
  };
};

const mapDispatchToProps = {
  updateServices,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEntry);
