export function selectLatestZone(responseWorldModels) {
  let zone = null;
  if (responseWorldModels.length > 0) {
    // Select the zone which is most recently updated and set it as default zone on page mount
    let latestZone = responseWorldModels.reduce(
      (latest, current) =>
        new Date(current.lastUpdatedTimestamp) >
        new Date(latest.lastUpdatedTimestamp)
          ? current
          : latest,
      responseWorldModels[0]
    ).id;
    zone = latestZone;
  }
  return zone;
}

export function getInitials(name) {
  let initials = "";
  const words = name.split(" ");

  for (let i = 0; i < words.length; i++) {
    initials += words[i].charAt(0).toUpperCase();
  }

  return initials;
}

// Format date as per user local settings
export function formatDate(d, tz) {
  let date = new Date(d);
  let options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: tz,
  };
  return date.toLocaleString("en-US", options);
}

export function formatDateWithoutYear(d, tz) {
  let date = new Date(d);
  let options = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: tz,
  };
  return date.toLocaleString("en-US", options);
}

export function formatDay(d, tz) {
  let date = new Date(d);
  let options = {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: tz,
  };
  return date.toLocaleString("en-US", options);
}

export function filterRoutes(routes) {
  let filteredRoutes = [];
  if (routes.length > 0) {
    filteredRoutes = routes.filter(
      (item) =>
        item.mode !== "rejected" &&
        item.status !== "invalid" &&
        item.status !== "completed"
    );
  }
  return filteredRoutes;
}

export function filterRoutesForMap(routes) {
  let filteredRoutes = [];
  if (routes.length > 0) {
    filteredRoutes = routes.filter(
      (item) =>
        item.mode !== "rejected" &&
        item.route !== "unaccepted" &&
        item.status !== "invalid" &&
        item.status !== "completed"
    );
  }
  return filteredRoutes;
}

export function filterTrips(trips) {
  let filteredTrips = [];
  if (trips.length > 0) {
    filteredTrips = trips.filter((item) => item.status !== "aborted");
  }
  return filteredTrips;
}

export function filterTripsWithArrayOfFilters(trips, filters) {
  let filteredTrips = [];
  if (trips.length > 0) {
    filteredTrips = trips.filter((item) => {
      return item.status !== "aborted" && filters.includes(item.status);
    });
  }
  return filteredTrips;
}

export function filterActiveTrips(trips) {
  const filtered_active_trips = trips.filter((trip) => {
    return (
      trip.status !== "droppedOff" &&
      trip.status !== "cancelled" &&
      trip.status !== "aborted" &&
      trip.status !== "expired" &&
      trip.status !== "failed"
    );
  });
  return filtered_active_trips;
}

export const getDateTimeFromUtc = (utcDateTimeString) => {
  const utcDate = new Date(utcDateTimeString);
  const localDate = new Date(
    utcDate.getFullYear(),
    utcDate.getMonth(),
    utcDate.getDate(),
    utcDate.getHours(),
    utcDate.getMinutes(),
    utcDate.getSeconds()
  );
  return localDate;
};

export const getRoutingScenarioMode = (mode) => {
  let label = "";
  switch (mode) {
    case "none":
      label = "No restrictions on delivery scenario";
    case "spsd":
      label = "Single pickup and single dropoff";
    case "spmd":
      label = "Single pickup and multiple dropoff";
    case "mpsd":
      label = "Multiple pickup and single dropoff";
    case "mpmd":
      label = "Multiple pickup and multiple dropoff";
  }
  return label;
};

// Segegrate selected routes based on optimizationSolutionId
export function segregateRoutes(data) {
  let result = data.reduce((acc, object) => {
    acc[object.optimizationSolutionId] =
      acc[object.optimizationSolutionId] || [];
    acc[object.optimizationSolutionId].push(object);
    return acc;
  }, {});

  return result;
}

// Check for duplicate vehicles
export function checkDuplicateVehicle(arr) {
  const vehicleIds = {};

  for (let i = 0; i < arr.length; i++) {
    const vehicleId = arr[i].vehicleId;

    if (vehicleIds[vehicleId]) {
      return true;
    } else {
      vehicleIds[vehicleId] = true;
    }
  }

  return false;
}

export function progress(status, action = null, tripStatus = null) {
  let obj = {};
  switch (status) {
    case "created":
      obj.p = "UPCOMING →";
      obj.color = "geekblue";
      break;
    case "departed":
      obj.p = "COMPLETED ✓";
      obj.color = "volcano";
      break;
    case "arrived":
      obj.p = "IN PROGRESS !";
      obj.color = "green";

      if (action && tripStatus) {
        if (action == "pickup" && tripStatus == "pickedUp") {
          obj.p = "COMPLETED ✓";
          obj.color = "volcano";
        } else if (action == "dropoff" && tripStatus == "droppedOff") {
          obj.p = "COMPLETED ✓";
          obj.color = "volcano";
        } else {
          obj.p = "IN PROGRESS !";
          obj.color = "green";
        }
      }
      break;
    default:
      obj.p = "";
      obj.color = "";
      break;
  }
  return obj;
}

export function minutesSinceDatetime(datetime) {
  var past = new Date(datetime);
  var now = new Date();
  var elapsed = (now - past) / 1000;
  var minutes = Math.ceil(elapsed / 60);
  var hours = 0;
  var days = 0;
  var months = 0;

  if (minutes >= 60) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
  }

  if (hours >= 24) {
    days = Math.floor(hours / 24);
    hours = hours % 24;
  }

  if (days >= 30) {
    months = Math.floor(days / 30);
    days = days % 30;
  }

  if (months > 0) {
    return months + " months";
  } else if (days > 0) {
    return days + " days";
  } else if (hours > 0) {
    return hours + " h " + minutes + " mins";
  } else {
    return minutes + " mins";
  }
}

export function dropoffOrElapsedTime(row) {
  var past = new Date(row.createdTimestamp);
  var latest =
    row.status !== "droppedOff" && row.status !== "cancelled"
      ? new Date()
      : new Date(row.lastUpdatedTimestamp);
  var elapsed = (latest - past) / 1000;
  var minutes = Math.round(elapsed / 60);
  var hours = Math.round(minutes / 60);
  var days = Math.floor(hours / 24);
  var weeks = Math.floor(days / 7);

  if (hours < 24 && minutes <= 59) {
    return minutes + " min(s)";
  } else if (hours < 24 && minutes > 59) {
    return hours + " hrs";
  } else if (hours >= 24 && hours < 48) {
    return "1 day";
  } else if (hours >= 48 && hours < 72) {
    return "2 days";
  } else if (hours >= 72 && hours < 168) {
    return "less than a week";
  } else if (hours >= 168 && hours < 336) {
    return "1 week";
  } else if (hours >= 336) {
    return weeks + " weeks";
  } else {
    return days + " days";
  }
}

// To display the rows in the nested tables
export function fetchWaypoints(row) {
  let waypoints = [];
  if (row && row.segments) {
    for (let i = 0; i < row.segments.length; i++) {
      for (let j = 0; j < row.segments[i].waypoints.length; j++) {
        waypoints.push(row.segments[i].waypoints[j]);
      }
    }
  }

  return waypoints;
}

export function getLocation(obj) {
  let location = obj.location;
  if (location.address) {
    return `${location.address.street1}, ${location.address.city}, ${location.address.stateProvince}`;
  } else {
    return `${
      obj.action.slice(0, 1).toUpperCase() + obj.action.slice(1)
    } Location`;
  }
}

export function fetchUpcomingWaypoint(row) {
  let arr = fetchWaypoints(row);
  let obj = {};
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].status === "arrived" || arr[i].status === "departed") {
      if (i + 1 < arr.length) {
        obj.status = arr[i + 1].status;
        obj.action = arr[i + 1].action;
        obj.location = getLocation(arr[i + 1]);
      } else {
        obj.status = "NA";
        obj.action = "...";
        obj.location = "...";
      }
    }
  }
  if (arr.length > 0 && arr[0].status === "created") {
    obj.status = arr[0].status;
    obj.action = arr[0].action;
    obj.location = getLocation(arr[0]);
  }
  return obj;
}

export function fetchCurrentAndPreviousWaypoints(row) {
  let current = {};
  let previous = {};
  let arr = fetchWaypoints(row);
  if (arr.length > 0) {
    if (arr[0].status === "created" || arr[0].status === "arrived") {
      current.id = arr[0].waypointId;
      current.status = arr[0].status;
      current.eta = arr[0].eta;
      current.action = arr[0].action;
      previous.status = "NA";
      previous.eta = "NA";
      previous.action = "...";
      previous.location = {
        coordinate: "...",
      };
    }
  }

  for (let i = 1; i < arr.length - 1; i++) {
    if (arr[i].status === "created" || arr[i].status === "arrived") {
      current.status = "arrived";
      current.eta = arr[i].eta;
      current.id = arr[i].waypointId;
      current.action = arr[i].action;
      previous.id = arr[i - 1].waypointId;
      previous.status = arr[i - 1].status;
      previous.eta = arr[i - 1].eta;
      previous.location = arr[i - 1].location;
      previous.action = arr[i - 1].action;
    }
    if (arr[i].status === "departed" && arr[i + 1].status !== "arrived") {
      current.status = "NA";
      current.eta = "NA";
      current.action = "...";
      previous.id = arr[i].waypointId;
      previous.status = arr[i].status;
      previous.eta = arr[i].eta;
      previous.location = arr[i].location;
      previous.action = arr[i].action;
    }
  }
  if (arr[arr.length - 1].status === "departed") {
    current.status = "NA";
    current.eta = "NA";
    current.action = "...";
    previous.id = arr[arr.length - 2].waypointId;
    previous.status = arr[arr.length - 2].status;
    previous.eta = arr[arr.length - 1].eta;
    previous.location = arr[arr.length - 1].location;
    previous.action = arr[arr.length - 1].action;
  }

  return { current: current, previous: previous };
}

export function tripStatusDescription(status) {
  let details = {
    desc: "",
    col: "",
  };
  switch (status) {
    case "queuing":
      details.desc =
        "Unoptimized trips are waiting in the queue to be optimized.";
      details.col = "#F9D664";
      break;
    case "optimizing":
      details.desc = "Trip is currently being optimized.";
      details.col = "#CE92E5";
      break;
    case "optimized":
      details.desc = "Trip has been recently optimized.";
      details.col = "#6CE4D5";
      break;
    case "assigned":
      details.desc =
        "Trip has been assigned to a driver and is accepted by the customer.";
      details.col = "#F7BF30";
      break;
    case "pickedUp":
      details.desc = "Assigned trip has been picked up by the driver.";
      details.col = "#2DBA11";
      break;
    case "droppedOff":
      details.desc = "Assigned trip has been dropped off.";
      details.col = "#D27D2D";
      break;
    case "expired":
      details.desc = "The trip is expired.";
      details.col = "#F88379";
      break;
    case "cancelled":
      details.desc = "The trip is cancelled.";
      details.col = "#9B9087";
      break;
    default:
      details.desc = "";
      details.col = "#808080";
      break;
  }
  return details;
}

export function routeStatusDescription(status) {
  let details = {
    desc: "",
    col: "",
  };
  switch (status) {
    case "waiting":
      details.desc =
        "The route that is created is waiting to be accepted or rejected by the customer.";
      details.col = "#F9D664";
      break;
    case "executing":
      details.desc =
        "The route that is accepted, and the order delivery is being processed.";
      details.col = "#CE92E5";
      break;
    default:
      details.desc = "No description found.";
      details.col = "#808080";
      break;
  }
  return details;
}

export function getRFQTextAndColor(status) {
  let text, color;
  switch (status) {
    case "quotingInProgress":
      text = "In progress";
      color = "#99971f";
      break;
    case "quoteAccepted":
      text = "Accepted";
      color = "#198534";
      break;
    case "quotingCompletedWithoutAcceptance":
      text = "Completed without acceptance";
      color = "#d17452";
      break;
    default:
      text = "Unknown status";
      color = "#808080";
  }
  return { text, color };
}

export function getOrderedWaypoints(route) {
  route.segments.sort((a, b) => a.segmentSequence - b.segmentSequence);
  let orderedWaypoints = [].concat(
    ...route.segments.map((segment) => segment.waypoints)
  );
  return orderedWaypoints;
}
