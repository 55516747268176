import React, { useState, useEffect } from "react";
import { Table, Card, Row, Radio, Button, Tooltip } from "antd";
import InterplaiDirectService from "services/InterplaiDirectService";
import { ReloadOutlined } from "@ant-design/icons";

const QuotesList = (props) => {
  const { finalQuoteDetails, setFinalQuoteDetails } = props;
  const [quotes, setQuotes] = useState();
  const [quotesLoading, setQuotesLoading] = useState(false);
  const [isQuoteSelected, setIsQuoteSelected] = useState(false);

  useEffect(() => {
    if (finalQuoteDetails && finalQuoteDetails.rfqId && finalQuoteDetails.zoneId) {
      const delay = 5000;
      const timerId = setTimeout(() => {
        fetchQuotes(finalQuoteDetails.zoneId, finalQuoteDetails.rfqId);
      }, delay);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [finalQuoteDetails]);

  const fetchQuotes = async (zoneId, rfqId) => {
    setQuotesLoading(true);
    try {
      const quotes = await InterplaiDirectService.getAllQuotesInAZoneByRfqID(zoneId, rfqId);
      setQuotes(quotes);
      setQuotesLoading(false);
    } catch (err) {
      setQuotesLoading(false);
    }
  };

  useEffect(() => {
    if (finalQuoteDetails.quoteId && finalQuoteDetails.fee && finalQuoteDetails.currency) {
      setIsQuoteSelected(true);
    }
  }, [finalQuoteDetails.quoteId]);

  const quotesColumns = [
    {
      title: "Quote ID",
      dataIndex: "id",
      key: "id",
      render: (_, record) => <strong>{record.id}</strong>,
    },
    {
      title: "Partner",
      dataIndex: "companyName",
      key: "companyName",
      render: (_, record) => <strong>{record.provider ? record.provider.companyName : ""}</strong>,
      align: "center",
    },
    {
      title: "Quote",
      dataIndex: "fee",
      key: "fee",
      render: (_, record) => <strong>{record.fee + " " + record.currency.toUpperCase()}</strong>,
      align: "center",
    },
    {
      title: "Accept",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Radio
          checked={record.id === finalQuoteDetails.quoteId}
          onChange={() =>
            setFinalQuoteDetails({
              ...finalQuoteDetails,
              quoteId: record.id,
              fee: record.fee,
              currency: record.currency,
              companyName: record.provider.companyName,
            })
          }
        />
      ),
      align: "right",
    },
  ];
  return (
    <>
      {finalQuoteDetails.rfqId ? (
        <>
          <Row gutter={16}>
            <Card className="d-flex flex-row justify-space-between mx-4">
              <p>
                Request for quote ID: <b>{finalQuoteDetails.rfqId}</b>
              </p>
              <p>
                Zone ID: <b>{finalQuoteDetails.zoneId}</b>
              </p>
              <p>
                Trip ID: <b>{finalQuoteDetails.tripId}</b>
              </p>
            </Card>
          </Row>
          <Row gutter={16} className="w-100">
            <small className="mx-2 mb-2">
              Note: If you do not want to continue with the quote acceptance right away, you can exit by clicking on 'Cancel'.
            </small>
            <Card
              title="Accept a quote"
              className="w-100 mx-4"
              actions={[
                <Tooltip title="Please click refresh if the table doesn't load automatically">
                  <Button size="small" key="refresh" onClick={() => fetchQuotes(finalQuoteDetails.zoneId, finalQuoteDetails.rfqId)}>
                    <ReloadOutlined /> Refresh
                  </Button>
                </Tooltip>,
              ]}
            >
              {quotesLoading ? (
                <div className="example">
                  <p>Loading quotes from the providers. It may take a while...</p>
                </div>
              ) : (
                <Table className="my-3 p-1 border rounded" dataSource={quotes} columns={quotesColumns} rowKey="id" pagination={false} />
              )}
            </Card>
          </Row>
          {isQuoteSelected ? (
            <Row gutter={16} className="w-100">
              <Card className="w-100 mx-4">
                By choosing this quotation, you accept to pay a fee of <b>${finalQuoteDetails.fee}</b> to{" "}
                <b>{finalQuoteDetails.companyName}</b> complete this trip.
              </Card>
            </Row>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default QuotesList;
