import React, { useState, useEffect } from "react";
import { Row, Form, Col, Button, Popover, Select, Typography, Modal, Alert } from "antd";
import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";
import { generateInterplaiId } from "utils/mongoId";

import tickimg from "../../img/correct.png";

import { connect } from "react-redux";
import { updateTrips } from "redux/actions/Items";

import TripForm from "./TripForm";

import "./index.css";

const { Option } = Select;
const { Paragraph } = Typography;

const BasicTripEntry = (props) => {
  const { reduxZones, areZonesUpdated, showTripBasicModal, setShowTripBasicModal } = props;
  const [form] = Form.useForm();

  const [selectedZone, setSelectedZone] = useState(null);
  const [locations, setLocations] = useState([]);
  const [manifests, setManifests] = useState(false);
  const [manifestId, setManifestId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState({
    state: false,
    message: {
      id: null,
      name: null,
    },
  });
  const [error, setError] = useState(null);

  const [zones, setZones] = useState([]);

  useEffect(() => {
    const fetchZones = async () => {
      const wm = await InterplaiDirectService.getWorldModels();
      setZones(wm);
    };
    fetchZones();
  }, []);

  useEffect(() => {
    if (areZonesUpdated) {
      setZones(reduxZones);
    }
  }, [areZonesUpdated, reduxZones]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4500);
    }
  }, [error]);

  useEffect(() => {
    const fetchLocations = async (zone) => {
      const locs = await InterplaiDirectService.getAllLocations(zone);
      const filteredLocs = locs.filter((location) => location.address);
      setLocations(filteredLocs);
    };
    if (selectedZone) {
      fetchLocations(selectedZone);
    }
  }, [selectedZone]);

  useEffect(() => {
    const generateId = async () => {
      let id = await generateInterplaiId();
      console.log("Generated manifest ID: " + id);
      setManifestId(id);
    };

    if (manifests) {
      generateId();
    }
  }, [manifests]);

  const handleTripClose = () => {
    setSuccess({
      state: false,
      message: {
        id: null,
        name: null,
      },
    });
    setError(null);
    setShowTripBasicModal(false);
  };

  const onTripSubmit = async (values) => {
    setLoading(true);
    try {
      let payload = {
        routingScenarioMode: "none",
      };

      if (values.maxStopsAllowed) {
        payload = { ...payload, maxStopsAllowed: Number(values.maxStopsAllowed) };
      }

      if (values.manuallyAssignedVehicleId) {
        payload = { ...payload, manuallyAssignedVehicleId: values.manuallyAssignedVehicleId };
      }

      let pickupWaypoint = {
        action: "pickup",
        waypointSequence: 0,
        serviceTime: 0,
        demand: 0,
        location: {
          address: {
            street1: values.pickupStreet1,
            city: values.pickupCity,
            stateProvince: values.pickupStateProvince,
            postalCode: values.pickupPostalCode,
            country: values.pickupCountry,
            ...(values.pickupStreet2 && { street2: values.pickupStreet2 }),
            ...(values.pickupUnit && { unit: values.pickupUnit }),
          },
          ...(values.pickupName && { name: values.pickupName }),
          ...(values.pickupPhoneNumber && { phoneNumber: values.pickupPhoneNumber }),
        },
        ...(values.pickupHandlingSteps &&
          values.pickupHandlingSteps.length > 0 && {
            handlingSteps: values.pickupHandlingSteps.map((step) => ({ name: step })),
          }),
        ...(values.pickupHandlingInstructions && { handlingInstructions: values.pickupHandlingInstructions }),
        ...(manifests && { manifestIds: [manifestId] }),
      };

      let dropoffWaypoint = {
        action: "dropoff",
        waypointSequence: 1,
        serviceTime: 0,
        demand: 0,
        location: {
          address: {
            street1: values.dropoffStreet1,
            city: values.dropoffCity,
            stateProvince: values.dropoffStateProvince,
            postalCode: values.dropoffPostalCode,
            country: values.dropoffCountry,
            ...(values.dropoffStreet2 && { street2: values.dropoffStreet2 }),
            ...(values.dropoffUnit && { unit: values.dropoffUnit }),
          },
          ...(values.dropoffName && { name: values.dropoffName }),
          ...(values.dropoffPhoneNumber && { phoneNumber: values.dropoffPhoneNumber }),
        },
        ...(values.dropoffHandlingSteps &&
          values.dropoffHandlingSteps.length > 0 && {
            handlingSteps: values.dropoffHandlingSteps.map((step) => ({ name: step })),
          }),
        ...(values.dropoffHandlingInstructions && { handlingInstructions: values.dropoffHandlingInstructions }),
        ...(manifests && { manifestIds: [manifestId] }),
        ...(values.dropoffFallbackHandlingSteps && {
          fallbackHandlingSteps: [{ name: values.dropoffFallbackHandlingSteps }],
        }),
      };

      payload = {
        ...payload,
        waypoints: [pickupWaypoint, dropoffWaypoint],
      };

      if (manifests) {
        payload = {
          ...payload,
          manifests: [
            {
              id: manifestId,
              ...(values.manifest.userManifestId && { userManifestId: values.manifest.userManifestId }),
              items: values.manifest.items,
            },
          ],
        };
      }

      const res = await InterplaiDirectService.createTrips(values.zoneId, payload);
      const alltrips = await InterplaiDirectService.getAllTripsInAZone(values.zoneId);
      updateTrips(alltrips);
      setSuccess({
        ...success,
        state: true,
        message: {
          id: res.id,
          zoneId: res.zoneId,
        },
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // console.log(values);
        onTripSubmit(values);
      })
      .catch((err) => {
        setError("One or more required fields are missing");
      });
  };

  const onTripReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={showTripBasicModal}
      onCancel={handleTripClose}
      title={
        <div>
          <h2>Create a Trip (Basic)</h2>
          {!success.state ||
            (!error && (
              <small className="font-weight-light">
                The basic trip creation form allows you to create trips with a maximum of <i>two waypoints</i> and <i>one manifest</i>. To
                fill in other fields, please use the advanced trip creation form.
              </small>
            ))}
        </div>
      }
      maskClosable={false}
      footer={
        success.state
          ? [
              <Button key="close" type="primary" onClick={handleTripClose} id="create-trip-close">
                Close
              </Button>,
            ]
          : [
              <Button key="clear" onClick={onTripReset} style={{ float: "left" }} id="create-trip-reset">
                <ReloadOutlined />
                Clear all
              </Button>,
              <Button key="submit" htmlType="submit" type="primary" loading={loading} onClick={onSubmit} id="create-trip-button">
                Create
              </Button>,

              <Button type="ghost" key="cancel" onClick={handleTripClose} id="create-trip-close">
                Cancel
              </Button>,
            ]
      }
      width={1160}
    >
      {error && (
        <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
              <Alert message={error} showIcon type="error" closable />
            </Col>
          </Row>
        </div>
      )}
      <div style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "60vh" }}>
        {success.state ? (
          <Row gutter={16} justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="d-block mb-4">
                <img src={tickimg} className="mr-3" height="28px" width="28px" alt="success" />
                <strong>The trip is successfully created!</strong>
              </div>
              <div className="d-block">
                <strong>Trip ID: </strong>
                <Paragraph copyable>{success.message.id}</Paragraph>
              </div>
              <div className="d-block">
                <strong>Corresponding Zone ID: </strong>
                <Paragraph copyable>{success.message.zoneId}</Paragraph>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Form form={form} layout="vertical" name="create-trip" preserve={false} className="m-3" id="create-trip-form">
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    name="zoneId"
                    label="Zone"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[{ required: true, message: "Please select a zone." }]}
                  >
                    <div className="d-flex align-items-center">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        className="mr-3"
                        placeholder="Select a zone"
                        filterOption={(input, option) =>
                          option.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(value) => {
                          form.setFieldsValue({
                            zoneId: value,
                          });
                          setSelectedZone(value);
                        }}
                      >
                        {zones.length > 0 &&
                          zones.map((worldmodel) => (
                            <Option key={worldmodel.id} value={worldmodel.id}>
                              <strong>{worldmodel.name}</strong> ({worldmodel.id})
                            </Option>
                          ))}
                      </Select>
                      <Popover content="The zone in which the vehicle is to be created. (string)" trigger="hover" placement="right">
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <TripForm form={form} selectedZone={selectedZone} locations={locations} manifests={manifests} setManifests={setManifests} />
            </Form>
          </>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    reduxZones: state.items.zones,
    areZonesUpdated: state.items.areZonesUpdated,
  };
};

const mapDispatchToProps = {
  updateTrips,
};

export default connect(mapStateToProps, mapDispatchToProps)(BasicTripEntry);
