import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthViews from "views/auth-views";
import Loading from "components/shared-components/Loading";
import { useThemeSwitcher } from "react-css-theme-switcher";
import prodsignin from "./img/latest/prodSignIn.png";
import devsignin from "./img/latest/devSignIn.png";
import "./index.css";

const firstBackgroundStyle = {
  background: "#FFF",
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto",
  minHeight: "100%",
  backgroundPosition: "center",
  width: "55%",
  height: "100%",
  top: 0,
  left: 0,
};
const secondDevBackgroundStyle = {
  backgroundImage: "url(/img/latest/blueBackground.png)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto",
  minHeight: "100%",
  backgroundPosition: "center",
  position: "relative",
  width: "45%",
  height: "100%",
  top: 0,
  left: 0,
};

const secondProdBackgroundStyle = {
  backgroundImage: "url(/img/latest/greenBackground.png)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "auto",
  minHeight: "100%",
  backgroundPosition: "center",
  position: "relative",
  width: "45%",
  height: "100%",
  top: 0,
  left: 0,
};

const secondDevChild = {
  minHeight: "100%",
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

const secondProdChild = {
  minHeight: "100%",
  position: "absolute",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
};

export const AuthLayout = () => {
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <div className="auth-container d-flex">
      <div className="left-container h-100" style={firstBackgroundStyle}>
        <div className="container-fluid d-flex flex-column justify-content-center h-100 w-100">
          <Switch>
            <Route path="" component={AuthViews} />
          </Switch>
        </div>
      </div>
      <div
        className="right-container h-100"
        style={
          process.env.REACT_APP_CROSS_NODE_ENV === "production"
            ? secondProdBackgroundStyle
            : secondDevBackgroundStyle
        }
      >
        <div
          className="d-flex container-fluid justify-content-center align-items-center"
          style={
            process.env.REACT_APP_CROSS_NODE_ENV === "production"
              ? secondProdChild
              : secondDevChild
          }
        >
          <img
            className="auth-img"
            src={
              process.env.REACT_APP_CROSS_NODE_ENV === "production"
                ? prodsignin
                : devsignin
            }
            alt=""
            width="480"
            height="480"
          />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
