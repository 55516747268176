import React, { useState, useEffect } from "react";
import { Row, Form, Col, Input, InputNumber, Button, Popover, Select, Typography, Modal, Alert } from "antd";
import { UpOutlined, DownOutlined, InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import InterplaiDirectService from "services/InterplaiDirectService";
import tickimg from "../../img/correct.png";
import errorimg from "../../img/cross.png";

import { connect } from "react-redux";
import { updateZones } from "redux/actions/Items";

const { Option } = Select;
const { Paragraph } = Typography;

const ZoneEntry = (props) => {
  const { updateZones, showZoneModal, setShowZoneModal } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [advancedFields, setAdvancedFields] = useState(false);
  const [optimizationParameters, setOptimizationParameters] = useState(false);
  const [routeParameters, setRouteParameters] = useState(false);
  const [vehicleParameters, setVehicleParameters] = useState(false);
  const [tripParameters, setTripParameters] = useState(false);
  const [mobileAppParameters, setMobileAppParameters] = useState(false);
  const [coordinates, setCoordinates] = useState(false);
  const [success, setSuccess] = useState({
    state: false,
    message: {
      id: null,
      name: null,
    },
  });
  const [error, setError] = useState(null);

  const handleAdvancedView = () => {
    setAdvancedFields(!advancedFields);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 4500);
    }
  }, [error]);

  const handleZoneClose = () => {
    setSuccess({
      state: false,
      message: {
        id: null,
        name: null,
      },
    });
    setError(null);
    setShowZoneModal(false);
  };

  const onZoneSubmit = async (values) => {
    setLoading(true);
    try {
      let payload = {
        name: values.name,
        userZoneId: values.userZoneId,
      };
      if (routeParameters) {
        payload = {
          ...payload,
          routeParameters: {
            maxTravelDistance: Number(values.maxTravelDistance),
            maxTravelDuration: Number(values.maxTravelDuration),
            maxFirstWaypointTravelDistance: Number(values.maxFirstWaypointTravelDistance),
            maxFirstWaypointTravelDuration: Number(values.maxFirstWaypointTravelDuration),
            travelRestrictionMode: values.travelRestrictionMode,
            reroutingMode: values.reroutingMode,
            reroutingFinalDropoffThreshold: Number(values.reroutingFinalDropoffThreshold),
            allowReroutingDestinationChanges: values.allowReroutingDestinationChanges,
            vehicleToTripServiceMatching: values.vehicleToTripServiceMatching,
            maxDistanceBetweenPickupLocations: Number(values.maxDistanceBetweenPickupLocations),
            maxDistanceBetweenDropoffLocations: Number(values.maxDistanceBetweenDropoffLocations),
            matchServicesBetweenPickupLocations: values.matchServicesBetweenPickupLocations,
          },
        };
      }
      if (optimizationParameters) {
        payload = {
          ...payload,
          optimizationParameters: {
            optimizationMode: values.optimizationMode,
            costMatrixMode: values.costMatrixMode,
          },
        };
      }
      if (tripParameters) {
        payload = {
          ...payload,
          tripParameters: {
            tripPrioritizationMode: values.tripPrioritizationMode,
            tripPrioritizationThreshold: Number(values.tripPrioritizationThreshold),
          },
        };
      }
      if (vehicleParameters) {
        payload = {
          ...payload,
          vehicleParameters: {
            vehicleAssignmentMode: values.vehicleAssignmentMode,
            vehicleNotificationMode: values.vehicleNotificationMode,
          },
        };
      }
      if (mobileAppParameters) {
        payload = {
          ...payload,
          mobileAppParameters: {
            mobileAppActive: values.mobileAppActive,
          },
        };
      }
      if (values.lat && values.lng) {
        payload = {
          ...payload,
          coordinates: {
            lat: Number(values.lat),
            lng: Number(values.lng),
          },
        };
      }

      const res = await InterplaiDirectService.createZones(payload);
      const updatedZones = await InterplaiDirectService.getWorldModels();
      updateZones(updatedZones);
      setSuccess({
        ...success,
        state: true,
        message: {
          id: res.id,
          name: res.name,
        },
      });
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onZoneSubmit(values);
      })
      .catch((err) => {
        setError("One or more required fields are missing");
      });
  };

  const onZoneReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      visible={showZoneModal}
      onCancel={handleZoneClose}
      title="Create a Zone"
      maskClosable={false}
      footer={
        success.state
          ? [
              <Button key="close" type="primary" onClick={handleZoneClose} id="create-zone-close">
                Close
              </Button>,
            ]
          : [
              <Button key="clear" onClick={onZoneReset} style={{ float: "left" }} id="create-zone-reset">
                <ReloadOutlined />
                Clear all
              </Button>,
              <Button key="submit" htmlType="submit" type="primary" loading={loading} onClick={onSubmit} id="create-zone-button">
                Create
              </Button>,

              <Button type="ghost" key="cancel" onClick={handleZoneClose} id="create-zone-close">
                Cancel
              </Button>,
            ]
      }
      width={540}
      className="mb-4"
    >
      {error && (
        <div style={{ overflowX: "hidden", overflowY: "hidden" }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mt-2 mb-3">
              <Alert message={error} showIcon type="error" closable />
            </Col>
          </Row>
        </div>
      )}
      <div style={{ overflowX: "hidden", overflowY: "auto", maxHeight: "64vh" }}>
        {success.state ? (
          <Row gutter={16} justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="d-block mb-4">
                <img src={tickimg} className="mr-3" height="28px" width="28px" alt="success" />
                <strong>The zone is successfully created!</strong>
              </div>
              <div className="d-block">
                <strong>Name: </strong>
                <Paragraph copyable>{success.message.name}</Paragraph>
              </div>
              <div className="d-block">
                <strong>ID: </strong>
                <Paragraph copyable>{success.message.id}</Paragraph>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form form={form} layout="vertical" name="create-zone" preserve={false} className="m-3" id="create-zone-form">
                  <Form.Item
                    name="name"
                    label="Name"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[
                      {
                        required: true,
                        message: "Please enter the name of the zone.",
                      },
                    ]}
                  >
                    <div className="d-flex align-items-center">
                      <Input id="de-zone-name" autoComplete="off" className="mr-3" />
                      <Popover content="Name of the zone (string)" trigger="hover" placement="right">
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name="userZoneId"
                    label="User Zone ID"
                    hasFeedback
                    shouldUpdate={true}
                    initialValue={""}
                    rules={[{ required: false }]}
                  >
                    <div className="d-flex align-items-center">
                      <Input id="de-zone-user-zone-id" autoComplete="off" className="mr-3" />
                      <Popover content="User-specified identifier of the zone (string)" trigger="hover" placement="right">
                        <InfoCircleOutlined />
                      </Popover>
                    </div>
                  </Form.Item>
                  <a className="my-3" onClick={handleAdvancedView}>
                    <strong>
                      Advanced Zone Options&nbsp;
                      {advancedFields ? <UpOutlined /> : <DownOutlined />}
                    </strong>
                  </a>
                  {advancedFields && (
                    <div className="my-4 mx-3">
                      <div className="my-3">
                        <a className="my-4 text-dark" onClick={() => setOptimizationParameters(!optimizationParameters)}>
                          OPTIMIZATION PARAMETERS&nbsp;
                          {optimizationParameters ? <UpOutlined /> : <DownOutlined />}
                        </a>
                        {optimizationParameters && (
                          <div className="mt-4">
                            <Form.Item
                              name="optimizationMode"
                              label="Optimization Mode"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={"online"}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-optimizationMode"
                                  className="mr-3"
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      optimizationMode: value,
                                    });
                                  }}
                                  defaultValue={"online"}
                                >
                                  <Option id="de-zone-optimizationMode-offline" value="offline">
                                    Offline
                                  </Option>
                                  <Option id="de-zone-optimizationMode-online" value="online">
                                    Online
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>The technique used to optimize trips.</strong>
                                        &nbsp;(string)
                                      </div>
                                      <br />
                                      <small>Online : the system immediately optimizes trips upon adding them.</small>
                                      <small>Offline: the user triggers the optimization of trips.</small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="costMatrixMode"
                              label="Cost Matrix Mode"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={"distance"}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-costMatrixMode"
                                  className="mr-3"
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      costMatrixMode: value,
                                    });
                                  }}
                                  defaultValue={"distance"}
                                >
                                  <Option id="de-zone-costMatrixMode-distance" value="distance">
                                    Distance
                                  </Option>
                                  <Option id="de-zone-costMatrixMode-duration" value="duration">
                                    Duration
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>The cost matrix specifies which factor should be minimized during optimization.</strong>
                                        &nbsp;(string)
                                      </div>
                                      <br />
                                      <small>Distance : (meters) minimizes the distance it takes for a vehicle to complete a route.</small>
                                      <small>
                                        Duration : (minutes) minimizes the estimated time it takes for a vehicle to complete a route.
                                      </small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                          </div>
                        )}
                      </div>

                      <div className="my-3">
                        <a className="my-4 text-dark" onClick={() => setRouteParameters(!routeParameters)}>
                          ROUTE PARAMETERS&nbsp;
                          {routeParameters ? <UpOutlined /> : <DownOutlined />}
                        </a>
                        {routeParameters && (
                          <div className="mt-4">
                            <Form.Item
                              name="maxTravelDistance"
                              label="Max Travel Distance"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={100000}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  id="de-zone-maxTravelDistance"
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                  defaultValue={100000}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          Maximum distance (meters) that can be traveled by the assigned vehicle for a single route.
                                        </strong>
                                        &nbsp;(int32)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="maxTravelDuration"
                              label="Max Travel Duration"
                              hasFeedback
                              initialValue={480}
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  id="de-zone-maxTravelDuration"
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                  defaultValue={480}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          Maximum duration of time (minutes) that an assigned vehicle can travel in a single route.
                                        </strong>
                                        &nbsp;(int32)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="maxFirstWaypointTravelDistance"
                              label="Max First Waypoint Travel Distance"
                              hasFeedback
                              initialValue={100000}
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  id="de-zone-maxFirstWaypointTravelDistance"
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                  defaultValue={100000}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          Maximum distance (meters) that an assigned vehicle can travel to the first waypoint from its
                                          initial position during optimization.
                                        </strong>
                                        &nbsp;(int32)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="maxFirstWaypointTravelDuration"
                              label="Max First Waypoint Travel Duration"
                              hasFeedback
                              initialValue={480}
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  id="de-zone-maxFirstWaypointTravelDuration"
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                  defaultValue={480}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          Maximum duration of time (minutes) that an assigned vehicle can travel to the first waypoint from
                                          its initial position during optimization.
                                        </strong>
                                        &nbsp;(int32)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="maxDistanceBetweenPickupLocations"
                              label="Max Distance Between Pickup Locations"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={100000}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  id="de-zone-maxDistanceBetweenPickupLocations"
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                  defaultValue={100000}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>Maximum distance (meters) between pickup locations to consider for Bundling / MPMD.</strong>
                                        &nbsp;(int32)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="maxDistanceBetweenDropoffLocations"
                              label="Max Distance Between Dropoff Locations"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={100000}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  id="de-zone-maxDistanceBetweenDropoffLocations"
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                  defaultValue={100000}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          Maximum distance (meters) between dropoff locations to consider for Bundling / MPMD.
                                        </strong>
                                        &nbsp;(int32)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="matchServicesBetweenPickupLocations"
                              label="Match Services Between Pickup Locations"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={false}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-matchServicesBetweenPickupLocations"
                                  className="mr-3"
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      matchServicesBetweenPickupLocations: value,
                                    });
                                  }}
                                  defaultValue={false}
                                >
                                  <Option id="de-zone-matchServicesBetweenPickupLocations-false" value={false}>
                                    False
                                  </Option>
                                  <Option id="de-zone-matchServicesBetweenPickupLocations-true" value={true}>
                                    True
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <strong>Should the services match between the Trips to consider for Bundling / MPMD.</strong>
                                      &nbsp;(boolean)
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="travelRestrictionMode"
                              label="Travel Restriction Mode"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={"none"}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-travelRestrictionMode"
                                  className="mr-3"
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      travelRestrictionMode: value,
                                    });
                                  }}
                                  defaultValue={"none"}
                                >
                                  <Option id="de-zone-travelRestrictionMode-none" value="none">
                                    None
                                  </Option>
                                  <Option id="de-zone-travelRestrictionMode-distance" value="distance">
                                    Distance
                                  </Option>
                                  <Option id="de-zone-travelRestrictionMode-duration" value="duration">
                                    Duration
                                  </Option>
                                  <Option id="de-zone-travelRestrictionMode-both" value="both">
                                    Both
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          Set the mode to restrict vehicle assignment based on maxFirstWaypointTravelDistance and
                                          maxFirstWaypointTravelDuration.
                                        </strong>
                                        &nbsp;(string)
                                      </div>
                                      <br />
                                      <small>None : no restriction in assignment.</small>
                                      <small>Distance : restriction is based on maxFirstWaypointTravelDistance.</small>
                                      <small>Duration : restriction is based on maxFirstWaypointTravelDuration.</small>
                                      <small>Both : restriction is based on both.</small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="reroutingMode"
                              label="Re-routing Mode"
                              initialValue={"none"}
                              hasFeedback
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-reroutingMode"
                                  className="mr-3"
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      reroutingMode: value,
                                    });
                                  }}
                                  defaultValue={"none"}
                                >
                                  <Option id="de-zone-reroutingMode-none" value="none">
                                    None
                                  </Option>
                                  <Option id="de-zone-reroutingMode-pickup" value="duringPickup">
                                    During Pickup
                                  </Option>
                                  <Option id="de-zone-reroutingMode-dropoff" value="duringFinalDropoff">
                                    During Final Dropoff
                                  </Option>
                                  <Option id="de-zone-reroutingMode-pick&drop" value="duringPickupAndFinalDropoff">
                                    During Pickup And Final Dropoff
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          Defines how rerouting is performed for vehicles that are currently executing routes. The following
                                          rerouting modes are supported:
                                        </strong>
                                        &nbsp;(string)
                                      </div>
                                      <br />
                                      <small>
                                        During Pickup : rerouting is active during the pickup phase; starts once a route is accepted and
                                        ends when the vehicle departs the last pickup location.
                                      </small>
                                      <small>
                                        During Final Dropoff : rerouting is active during the drop-off phase; starts once the vehicle is
                                        within the drop-off distance threshold (meters) of the final drop-off location in the route, and
                                        ends when the route is complete.
                                      </small>
                                      <small>
                                        During Pickup And Final Dropoff : rerouting is active during both the pickup phase and drop-off
                                        phase; starts once a route is accepted and ends once the route is complete.
                                      </small>
                                      <small>None : rerouting is not active.</small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="reroutingFinalDropoffThreshold"
                              label="Re-routing Final Dropoff Threshold"
                              hasFeedback
                              initialValue={1000}
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  id="de-zone-reroutingFinalDropoffThreshold"
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                  defaultValue={1000}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          When the distance from vehicle’s current location to its final drop-off waypoint location is below
                                          this threshold distance (meters), the system will start looking for rerouting opportunities by
                                          adding new trips to the vehicle’s route.
                                        </strong>
                                        &nbsp;(int32)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="allowReroutingDestinationChanges"
                              label="Allow Re-routing Destination Changes"
                              hasFeedback
                              initialValue={false}
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-allowReroutingDestinationChanges"
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      allowReroutingDestinationChanges: value,
                                    });
                                  }}
                                  className="mr-3"
                                  defaultValue={false}
                                >
                                  <Option id="de-zone-allowReroutingDestinationChanges-false" value={false}>
                                    False
                                  </Option>
                                  <Option id="de-zone-allowReroutingDestinationChanges-true" value={true}>
                                    True
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>Defines the rerouting changes.</strong>
                                        &nbsp;(boolean)
                                      </div>
                                      <small>
                                        True : the system will look for opportunities to perform rerouting which may result in the vehicle
                                        having to change its current destination.
                                      </small>
                                      <small>
                                        False : when rerouting is performed, the vehicle will always continue to its current destination and
                                        new trips may be added after.
                                      </small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="vehicleToTripServiceMatching"
                              label="Vehicle-to-Trip Service Match"
                              hasFeedback
                              initialValue={"matchAtLeastOne"}
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-vehicleToTripServiceMatching"
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      vehicleToTripServiceMatching: value,
                                    });
                                  }}
                                  className="mr-3"
                                  defaultValue={"matchAtLeastOne"}
                                >
                                  <Option id="de-zone-vehicleToTripServiceMatching-none" value={"none"}>
                                    None
                                  </Option>
                                  <Option id="de-zone-vehicleToTripServiceMatching-matchAtLeastOne" value={"matchAtLeastOne"}>
                                    Match At Least One
                                  </Option>
                                  <Option id="de-zone-vehicleToTripServiceMatching-matchAll" value={"matchAll"}>
                                    Match All
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>Defines the vehicle-to-trip service matching.</strong>
                                        &nbsp;(string)
                                      </div>
                                      <small>
                                        None : services constraint is not applicable even if the Trips and Vehicles provided with service.
                                      </small>
                                      <small>
                                        Match At Least One : vehicle services should match at least one of the services given in the trips.
                                        added after.
                                      </small>
                                      <small>Match All : vehicle services should contain all the services given in the trip.</small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                          </div>
                        )}
                      </div>

                      <div className="my-3">
                        <a className="my-4 text-dark" onClick={() => setVehicleParameters(!vehicleParameters)}>
                          VEHICLE PARAMETERS&nbsp;
                          {vehicleParameters ? <UpOutlined /> : <DownOutlined />}
                        </a>
                        {vehicleParameters && (
                          <div className="mt-4">
                            <Form.Item
                              name="vehicleAssignmentMode"
                              label="Vehicle Assignment Mode"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={"minimizeVehicles"}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-vehicleAssignmentMode"
                                  className="mr-3"
                                  defaultValue={"minimizeVehicles"}
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      vehicleAssignmentMode: value,
                                    });
                                  }}
                                >
                                  <Option id="de-zone-vehicleAssignmentMode-noveh" value="noVehicles">
                                    No Vehicles
                                  </Option>
                                  <Option id="de-zone-vehicleAssignmentMode-minveh" value="minimizeVehicles">
                                    Minimize Vehicles
                                  </Option>
                                  <Option id="de-zone-vehicleAssignmentMode-maxveh" value="maximizeVehicles">
                                    Maximize Vehicles
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>Selects how vehicles are assigned during optimization.</strong>
                                        &nbsp;(string)
                                      </div>
                                      <br />
                                      <small>Minimize Vehicles : minimimizes the number of vehicles used for the assignment.</small>
                                      <small>Maximize Vehicles : maximizes the number of vehicles used for the assignment.</small>
                                      <small>No Vehicles : vehicles are not used for the assignment.</small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>

                            <Form.Item
                              name="vehicleNotificationMode"
                              label="Vehicle Notification Mode"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={"single"}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-vehicleNotificationMode"
                                  className="mr-3"
                                  defaultValue={"single"}
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      vehicleNotificationMode: value,
                                    });
                                  }}
                                >
                                  <Option id="de-zone-vehicleNotificationMode-single" value="single">
                                    Single
                                  </Option>
                                  <Option id="de-zone-vehicleNotificationMode-bulletin" value="bulletin">
                                    Bulletin
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>Selects how vehicles are notified during trip creation.</strong>
                                        &nbsp;(string)
                                      </div>
                                      <br />
                                      <small>Single : trip is assigned to the single best vehicle.</small>
                                      <small>Bulletin : trip creation is notified to all the vehicles.</small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                          </div>
                        )}
                      </div>

                      <div className="my-3">
                        <a className="my-4 text-dark" onClick={() => setTripParameters(!tripParameters)}>
                          TRIP PARAMETERS&nbsp;
                          {tripParameters ? <UpOutlined /> : <DownOutlined />}
                        </a>
                        {tripParameters && (
                          <div className="mt-4">
                            <Form.Item
                              name="tripPrioritizationMode"
                              label="Trip Prioritization Mode"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={"none"}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-tripPrioritizationMode"
                                  className="mr-3"
                                  defaultValue={"none"}
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      tripPrioritizationMode: value,
                                    });
                                  }}
                                >
                                  <Option id="de-zone-tripPrioritizationMode-none" value="none">
                                    None
                                  </Option>
                                  <Option id="de-zone-tripPrioritizationMode-earlier" value="earlierTrips">
                                    Earlier Trips
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          Select how trips are prioritized, best used when there are insufficient vehicles available.
                                        </strong>
                                        &nbsp;(string)
                                      </div>
                                      <br />
                                      <small>None : no prioritization is implemented.</small>
                                      <small>
                                        Earlier Trips : trips are ordered by their creation timestamp, and earlier trips are prioritized for
                                        optimization over later trips.
                                      </small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="tripPrioritizationThreshold"
                              label="Trip Prioritization Threshold"
                              hasFeedback
                              initialValue={60}
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  id="de-zone-tripPrioritizationThreshold"
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                  defaultValue={60}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          Elapsed time (minutes) from the trip creation to start prioritizing for optimization.
                                        </strong>
                                        &nbsp;(int32)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                          </div>
                        )}
                      </div>

                      <div className="my-3">
                        <a className="my-4 text-dark" onClick={() => setMobileAppParameters(!mobileAppParameters)}>
                          MOBILE APP PARAMETERS&nbsp;
                          {mobileAppParameters ? <UpOutlined /> : <DownOutlined />}
                        </a>
                        {mobileAppParameters && (
                          <div className="mt-4">
                            <Form.Item
                              name="mobileAppActive"
                              label="Mobile App Active"
                              hasFeedback
                              shouldUpdate={true}
                              initialValue={false}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <Select
                                  id="de-zone-mobileAppActive"
                                  className="mr-3"
                                  defaultValue={false}
                                  onChange={(value) => {
                                    form.setFieldsValue({
                                      mobileAppActive: value,
                                    });
                                  }}
                                >
                                  <Option id="de-zone-mobileAppActive-false" value={false}>
                                    False
                                  </Option>
                                  <Option id="de-zone-mobileAppActive-true" value={true}>
                                    True
                                  </Option>
                                </Select>
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>
                                          A value indicating if this particular zone is included in the mobile app trip process.
                                        </strong>
                                        &nbsp;(boolean)
                                      </div>
                                      <br />
                                      <small>True : mobile app includes this zone.</small>
                                      <small>False : mobile app does not include this zone.</small>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                          </div>
                        )}
                      </div>

                      {/* <div className="my-3">
                        <a className="my-4 text-dark" onClick={() => setCoordinates(!coordinates)}>
                          COORDINATES&nbsp;{coordinates ? <UpOutlined /> : <DownOutlined />}
                        </a>
                        {coordinates && (
                          <div className="mt-4">
                            <Form.Item
                              name="lat"
                              label="Latitude"
                              hasFeedback
                              initialValue={""}
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>Coordinate latitude.</strong>
                                        &nbsp;(double)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                            <Form.Item
                              name="lng"
                              label="Longitude"
                              hasFeedback
                              initialValue={""}
                              shouldUpdate={true}
                              rules={[{ required: false }]}
                            >
                              <div className="d-flex align-items-center">
                                <InputNumber
                                  className="mr-3"
                                  style={{
                                    width: "100%",
                                  }}
                                />
                                <Popover
                                  content={
                                    <div className="d-flex flex-column">
                                      <div>
                                        <strong>Coordinate longitude.</strong>
                                        &nbsp;(double)
                                      </div>
                                    </div>
                                  }
                                  trigger="hover"
                                  placement="right"
                                >
                                  <InfoCircleOutlined />
                                </Popover>
                              </div>
                            </Form.Item>
                          </div>
                        )}
                      </div> */}
                    </div>
                  )}
                </Form>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  updateZones,
};

export default connect(null, mapDispatchToProps)(ZoneEntry);
