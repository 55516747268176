import React, { useState } from "react";
import { Form, Button, Input, InputNumber, Switch } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const Manifests = () => {
  const [itemList, setItemList] = useState([
    {
      dimensions: {
        checked: false,
      },
    },
  ]);

  const appendItemArray = () => {
    setItemList((prevState) => [
      ...prevState,
      {
        dimensions: {
          checked: false,
        },
      },
    ]);
  };

  const trimItemArray = (index) => {
    setItemList((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  const handleItem = (index) => {
    setItemList((prevState) => {
      const newState = [...prevState];
      newState[index].dimensions.checked = !newState[index].dimensions.checked;
      return newState;
    });
  };

  return (
    <div className="p-3 border rounded">
      <Form.Item style={{ padding: "16px" }}>
        <Form.Item name={["manifest", "userManifestId"]} label="Manifest Details" rules={[{ required: false }]}>
          <Input placeholder="e.g, Order category, name, etc" className="mr-3" />
        </Form.Item>

        <Form.List
          name={["manifest", "items"]}
          initialValue={[
            {
              userManifestItemId: "",
              name: "",
              description: "",
              quantity: 1,
              weight: 0,
              price: 0,
              dimensions: {
                height: 0,
                length: 0,
                width: 0,
              },
            },
          ]}
        >
          {(items, { add, remove }) => (
            <>
              {items.map((item, index) => (
                <Form.Item key={item.key} label={`Item ${index + 1}`} style={{ border: "1px solid #f0f0f0", padding: "16px" }}>
                  <Form.Item name={[item.name, "name"]} label="Name" rules={[{ required: true, message: "Item name is required." }]}>
                    <Input placeholder="Enter name of the item" className="mr-3" />
                  </Form.Item>
                  <Form.Item name={[item.name, "description"]} label="Description" rules={[{ required: false }]}>
                    <Input.TextArea placeholder="Enter description" className="mr-3" rows={3} />
                  </Form.Item>
                  <Form.Item name={[item.name, "quantity"]} label="Quantity" rules={[{ required: true, message: "Quantity is required." }]}>
                    <InputNumber className="mr-3 w-100" />
                  </Form.Item>
                  <Form.Item name={[item.name, "price"]} label="Price" rules={[{ required: false }]}>
                    <InputNumber className="mr-3 w-100" placeholder="Enter price of the item" />
                  </Form.Item>
                  <Form.Item name={[item.name, "weight"]} label="Weight" rules={[{ required: false }]}>
                    <InputNumber className="mr-3 w-100" placeholder="Enter weight of the item" />
                  </Form.Item>
                  <Form.Item name={[item.name, "dimensionChecked"]} valuePropName="checked" initialValue={false}>
                    <Switch
                      onChange={() => handleItem(index)}
                      defaultChecked={false}
                      checkedChildren="Hide Dimensions"
                      unCheckedChildren="Dimensions"
                    />
                  </Form.Item>
                  {itemList[index].dimensions.checked && (
                    <div className="p-3 border rounded mb-3 mt-2">
                      <Form.Item name={[item.name, "dimensions", "height"]} label="Height" rules={[{ required: false }]}>
                        <InputNumber className="mr-3 w-100" />
                      </Form.Item>
                      <Form.Item name={[item.name, "dimensions", "length"]} label="Length" rules={[{ required: false }]}>
                        <InputNumber className="mr-3 w-100" />
                      </Form.Item>
                      <Form.Item name={[item.name, "dimensions", "width"]} label="Width" rules={[{ required: false }]}>
                        <InputNumber className="mr-3 w-100" />
                      </Form.Item>
                    </div>
                  )}

                  {items.length > 1 && (
                    <Button
                      className="mt-2"
                      type="link"
                      onClick={() => {
                        remove(item.name);
                        trimItemArray(index);
                      }}
                      style={{ marginLeft: 8, marginTop: 1 }}
                    >
                      <MinusCircleOutlined /> Remove Item
                    </Button>
                  )}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                    appendItemArray();
                  }}
                  icon={<PlusOutlined />}
                >
                  Add Item
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </div>
  );
};

export default Manifests;
