import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Tag } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import devicon from "./img/developmentIcon.png";
import prodicon from "./img/productionIcon.png";
import InterplaiDirectService from "services/InterplaiDirectService";
import ZoneEntry from "views/app-views/data-entry/zone";
import LocationEntry from "views/app-views/data-entry/location";
import TripEntry from "views/app-views/data-entry/trip";
import BasicTripEntry from "views/app-views/data-entry/trip-basic";
import VehicleEntry from "views/app-views/data-entry/vehicle";
import ServiceEntry from "views/app-views/data-entry/service";
import CostMatrixEntry from "views/app-views/data-entry/cost-matrix";
import RFQEntry from "views/app-views/data-entry/rfq";
import QuoteEntry from "views/app-views/data-entry/quote";
import "./MenuContent.css"; // Create a new CSS file
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;
const setLocale = (isLocaleOn, localeKey) => (isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString());
const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};
const SideNavContent = (props) => {
  const { sideNavTheme, routeInfo, hideGroupTitle, localization, onMobileNavToggle } = props;
  const [showZoneModal, setShowZoneModal] = useState(false);
  const [showVehicleModal, setShowVehicleModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showTripBasicModal, setShowTripBasicModal] = useState(false);
  const [showTripModal, setShowTripModal] = useState(false);
  const [showServiceModal, setShowServiceModal] = useState(false);
  const [showCostMatrixModal, setShowCostMatrixModal] = useState(false);
  const [showRFQModal, setShowRFQModal] = useState(false);
  const [showQuoteModal, setShowQuoteModal] = useState(false);

  const [isInlineMode, setIsInlineMode] = useState(true);

  const getKeyAndModal = (key) => {
    if (key === "data-entry-zone") {
      setShowZoneModal(true);
    } else if (key === "data-entry-vehicle") {
      setShowVehicleModal(true);
    } else if (key === "data-entry-location") {
      setShowLocationModal(true);
    } else if (key === "data-entry-trip") {
      setShowTripModal(true);
    } else if (key === "data-entry-trip-basic") {
      setShowTripBasicModal(true);
    } else if (key === "data-entry-service") {
      setShowServiceModal(true);
    } else if (key === "data-entry-cost-matrix") {
      setShowCostMatrixModal(true);
    } else if (key === "data-entry-rfq") {
      setShowRFQModal(true);
    } else if (key === "data-entry-quote") {
      setShowQuoteModal(true);
    }
  };
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  setDefaultOpen(routeInfo.key);

  return (
    <>
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
        mode={isInlineMode ? "inline" : "vertical"} // Dynamic switching between inline and vertical mode
        style={{ height: "100%", borderRight: 0, marginTop: "20px" }} // Adjusted marginTop for reduced spacing
        defaultSelectedKeys={[routeInfo?.key]}
        className={hideGroupTitle ? "hide-group-title" : ""}
      >
        <Menu.Item className="custom-menu-item" style={{ margin: 0 }}>
          <a className="d-flex mb-2" href={InterplaiDirectService.clientConsoleLink()} target="_blank">
            <img src={process.env.REACT_APP_CROSS_NODE_ENV === "production" ? devicon : prodicon} height="24" width="24" className="mr-2" />
            <Tag color={process.env.REACT_APP_CROSS_NODE_ENV === "production" ? "blue" : "green"} className="text-decoration-underline">
              {process.env.REACT_APP_CROSS_NODE_ENV === "production" ? "DEV CLIENT CONSOLE ➤" : "GO TO CLIENT CONSOLE ➤"}
            </Tag>
          </a>
        </Menu.Item>

        {navigationConfig.map((menu) =>
          menu.submenu.length > 0 && InterplaiDirectService.hasAbility(menu.key, "group") ? (
            <Menu.ItemGroup key={menu.key} title={setLocale(localization, menu.title)} style={{ margin: 0 }}>
              {menu.submenu.map((subMenuFirst, idx) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    id={idx + subMenuFirst.title}
                    icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                    style={{ margin: 0 }}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item
                        key={subMenuSecond.key}
                        id={subMenuSecond.key}
                        onClick={() => (subMenuSecond.expand ? getKeyAndModal(subMenuSecond.key) : null)}
                        style={{ margin: 0 }}
                      >
                        {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                        <span>{setLocale(localization, subMenuSecond.title)}</span>
                        {subMenuSecond.path ? <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} /> : null}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : InterplaiDirectService.hasAbility(subMenuFirst.key, "action") ? (
                  <Menu.Item key={subMenuFirst.key} id={subMenuFirst.key} style={{ margin: 0 }}>
                    {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                  </Menu.Item>
                ) : null
              )}
            </Menu.ItemGroup>
          ) : InterplaiDirectService.hasAbility(menu.key, "group") ? (
            <Menu.Item key={menu.key} id={menu.key} style={{ margin: 0 }}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
            </Menu.Item>
          ) : null
        )}
      </Menu>

      {showZoneModal && <ZoneEntry id="zone-entry" showZoneModal={showZoneModal} setShowZoneModal={setShowZoneModal} />}
      {showVehicleModal && (
        <VehicleEntry id="vehicle-entry" showVehicleModal={showVehicleModal} setShowVehicleModal={setShowVehicleModal} />
      )}
      {showLocationModal && (
        <LocationEntry id="location-entry" showLocationModal={showLocationModal} setShowLocationModal={setShowLocationModal} />
      )}
      {showTripModal && <TripEntry id="trip-entry" showTripModal={showTripModal} setShowTripModal={setShowTripModal} />}
      {showTripBasicModal && (
        <BasicTripEntry id="trip-basic-entry" showTripBasicModal={showTripBasicModal} setShowTripBasicModal={setShowTripBasicModal} />
      )}
      {showServiceModal && (
        <ServiceEntry id="service-entry" showServiceModal={showServiceModal} setShowServiceModal={setShowServiceModal} />
      )}
      {showCostMatrixModal && (
        <CostMatrixEntry id="cost-matrix-entry" showCostMatrixModal={showCostMatrixModal} setShowCostMatrixModal={setShowCostMatrixModal} />
      )}
      {showRFQModal && <RFQEntry id="rfq-entry" showRFQModal={showRFQModal} setShowRFQModal={setShowRFQModal} />}
      {showQuoteModal && <QuoteEntry id="quote-entry" showQuoteModal={showQuoteModal} setShowQuoteModal={setShowQuoteModal} />}
    </>
  );
};
const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }} width={300}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};
const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? <SideNavContent {...props} /> : <TopNavContent {...props} />;
};
const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};
export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
