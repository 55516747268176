import {
  AUTH_TOKEN,
  AUTHENTICATED,
  REQUIREACCEPT,
  REQUIREACCOUNTSELECT,
  SHOW_AUTH_MESSAGE,
  SHOW_SUCCESS_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  HIDE_LOADING,
  SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_RESET_SUCCESS,
  RESET_FORM,
} from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  messageType: "success",
  showMessage: false,
  redirect: "",
  resetForm: false,
  allowRedirect: false,
  token: localStorage.getItem(AUTH_TOKEN),
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        redirect: "/",
        token: action.token,
        allowRedirect: true,
      };
    case REQUIREACCEPT:
      return {
        ...state,
        loading: false,
        redirect: "/auth/accept",
        token: action.token,
        allowRedirect: true,
      };
    case REQUIREACCOUNTSELECT:
      return {
        ...state,
        loading: false,
        redirect: "/auth/select-account",
        token: action.token,
        allowRedirect: true,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        messageType: "error",
        showMessage: true,
        loading: false,
      };
    case SHOW_SUCCESS_MESSAGE:
      return {
        ...state,
        message: action.message,
        messageType: "success",
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: "",
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: "/",
        loading: false,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case HIDE_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case FORGOT_PASSWORD_REQUESTED: {
      return {
        ...state,
        token: null,
        loading: false,
      };
    }
    case FORGOT_PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        token: null,
        loading: false,
        allowRedirect: true,
        redirect: "/auth/login",
      };
    }
    case RESET_FORM: {
      return {
        ...state,
        resetForm: true,
      };
    }
    default:
      return state;
  }
};

export default auth;
