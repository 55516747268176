import socketio from "socket.io-client";
import React from "react";
import InterplaiDirectService from "services/InterplaiDirectService";

const baseUrl = InterplaiDirectService.getApiUrl().slice(0, -3);

export const socket = socketio.connect(baseUrl, {
  transports: ["websocket"],
});

// Creating the socket context for usage - React Context API
export const SocketContext = React.createContext();
