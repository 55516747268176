import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/operations`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/billing`} component={lazy(() => import(`./billing`))} />
        <Route path={`${APP_PREFIX_PATH}/api`} component={lazy(() => import(`./api`))} />
        <Route path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./admin`))} />
        {/* <Route path={`${APP_PREFIX_PATH}/visualization`} component={lazy(() => import(`./visualization`))} /> */}
        <Route path={`${APP_PREFIX_PATH}/support`} component={lazy(() => import(`./support`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
        {/* <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} /> */}
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/operations`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
